/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Dropdown from "../../../Commons/Dropdown";
import Loader from "../../../Commons/Loader/Loader";
import {
  AddAddress,
  addProductLocation,
  getAddressList,
  viewAddress,
} from "../../../Services/APIServices";
import showNotification from "../../../Services/NotificationService";
import { DownDarkIcon, RightArrownIcon } from "../../../SvgIcons/allIcons";
import { useOnClickOutside } from "../../../globals/HelperCommon";
import { STEP_TWO } from "../../../globals/constants";
import { countryCode } from "../../../utils/CountryCode";

const Location = ({ productDetails }) => {
  const [showContent, setShowContent] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setShowContent(false));
  const onClickHandler = () => {
    setShowContent((show) => !show);
  };
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [addressData, setAddressData] = useState("");
  const [addressId, setAddressId] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [country, setCountry] = useState("");
  const rid = new URLSearchParams(location.search).get("rid");
  const navigate = useNavigate();

  const backAction = () => {
    navigate("/lender/new-item?atab=second&rid=" + rid);
  };

  useEffect(() => {
    locationList();
  }, []);

  useEffect(() => {
    if (rid && productDetails) {
      setAddressId(productDetails?.address);
      getSelectedAddress(productDetails?.id);
    }
  }, [productDetails]);

  const getSelectedAddress = (id) => {
    viewAddress(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setAddressData(resp?.data?.data);
      }
    });
  };

  const locationList = () => {
    getAddressList().then((resp) => {
      if (resp?.data?.status === 200) {
        setAddressList(resp?.data?.data);
      }
    });
  };

  const handleShow = (data) => {
    setAddressId(data?.id);
    setAddressData(data);
  };

  const handleAddressChange = (e, data) => {
    setAddressId(e.target.value);
    setAddressData(data);
    setShowContent(false);
    setNewAddress("");
    setCountry("");
  };

  const handleAddNewAddress = () => {
    setNewAddress("Add new address");
    setAddressData("");
    setAddressId("");
    setShowContent(false);
  };

  const recordSave = () => {
    if (productDetails?.steps >= STEP_TWO) {
      if (!addressId) {
        showNotification("danger", "Please select address");
      } else {
        let body = {
          product_id: rid,
          address_id: addressId,
        };
        let formData = new FormData();
        Object.keys(body).forEach((i) => {
          formData.append(i, body[i]);
        });
        setLoading(true);
        addProductLocation(formData).then((resp) => {
          setLoading(false);
          if (resp?.data?.status === 200) {
            navigate("/lender/new-item?atab=fourth&rid=" + rid);
          }
        });
      }
    } else {
      setLoading(false);
      showNotification("danger", "Please complete previous steps to proceed");
    }
  };
  const handlePlaces = (place) => {

    setFieldValue("zipcode", '');

    place?.address_components?.map((item) => {
      if (item?.types?.includes("country")) {
        setFieldValue("country", item?.long_name);
      }
      if (item?.types?.includes("administrative_area_level_1")) {
        setFieldValue("state", item?.long_name);
      }
      if (item?.types?.includes("administrative_area_level_3")) {
        setFieldValue("city", item?.long_name);
      }
      if (item?.types?.includes("postal_code")) {
        setFieldValue("zipcode", item?.long_name);
      }
    });
    setFieldValue("address1", place?.formatted_address);
    setFieldValue("latitude", place?.geometry?.location?.lat());
    setFieldValue("longitude", place?.geometry?.location?.lng());
  };

  const validationSchema = Yup.object({
    address1: Yup.string().required("This field is required").trim(),
    state: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    country: Yup.string().required("This field is required"),
    zipcode: Yup.string()
      .matches(/^[0-9]{5}/, "Enter a correct ZipCode it should be number")
      .min(6, "Minimum 6 digit required.")
      .max(10, "Maximum 10 digit required.")
      .required("This field is required"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      address1: "",
      address2: "",
      state: "",
      city: "",
      country: "",
      zipcode: "",
      latitude: "",
      longitude: "",
    },
    validationSchema,
    onSubmit(values, { resetForm }) {
      let body = {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.zipcode,
        latitude: values.latitude ? values.latitude : 0,
        longitude: values.longitude ? values.longitude : 0,
      };
      let formData = new FormData();
      Object.keys(body).forEach((data) => {
        formData.append(data, body[data]);
      });
      setLoading(true);
      AddAddress(formData).then((resp) => {
        setLoading(false);
        if (resp?.status === 200) {
          locationList();
          handleShow(resp?.data?.data);
          resetForm();
          setNewAddress("");
        } else {
          showNotification("danger", resp?.data?.message);
        }
      });
    },
  });

  return (
    <>
      <p className="head16">
        Item Details
        <span className="mx-2">
          <RightArrownIcon />
        </span>
        Location
      </p>
      <Row>
        <Col lg={7} md={6}>
          <div className="mb-4">
            <p className="mb-1 font12 font700 colordark">Select Address</p>
            <div className="addresdropdown-main addres-checkout" ref={ref}>
              <div
                className="form-control d-flex justify-content-between align-items-center cursor"
                onClick={onClickHandler}
              >
                <input
                  className="cursor"
                  readOnly={true}
                  value={
                    newAddress
                      ? newAddress
                      : addressData &&
                        `${addressData?.address1} ${addressData?.address2}`
                  }
                />
                <DownDarkIcon />
              </div>
              {showContent && (
                <div className="addressdetailbox">
                  <Row>
                    <Col sm={12} className="mx-auto">
                      {addressList &&
                        addressList.length > 0 &&
                        addressList.map((data, index) => {
                          return (
                            <Card
                              className="addresscard shadow-none rounded-0 mb-3 h-auto addressdropdown"
                              key={index}
                            >
                              <div className="d-flex align-items-center justify-content-between ">
                                <div className="d-flex align-items-start">
                                  <div className="custom-checkbox mt-1">
                                    <Form.Check
                                      type="radio"
                                      id={`address-${data?.id}`}
                                      name="address-id"
                                      value={data?.id}
                                      checked={addressId == data?.id}
                                      onChange={(e) =>
                                        handleAddressChange(e, data)
                                      }
                                    />
                                  </div>
                                  <label htmlFor={`address-${data?.id}`}>
                                    <div className="arrive-details ps-3 cursor">
                                      <p className="mb-0">
                                        {`${data?.address1} ${data?.address2}`}
                                        <br />
                                        {`${data?.city} ${data?.state}`}
                                        <br />
                                        {data?.country}
                                        <br />
                                        {data?.zipcode}
                                      </p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            </Card>
                          );
                        })}
                      <div
                        className="text-decoration-none"
                        onClick={() => handleAddNewAddress()}
                      >
                        <Card className="addresscard addressdropdown addadress shadow-none rounded-0 h-auto d-flex align-items-center justify-content-center cursor">
                          <p className="mb-0 font14 font700">Add new address</p>
                        </Card>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
          {addressId ? (
            <>
              <div className="mb-4">
                <p className="font12 font700 colordark mb-1">Address 1</p>
                <Form.Control
                  type="text"
                  value={addressData?.address1 ? addressData.address1 : ""}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <p className="font12 font700 colordark mb-1">Address 2</p>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={addressData?.address2 ? addressData.address2 : ""}
                  readOnly
                />
              </div>
              <div>
                <div className="d-flex align-items-center gap-4">
                  <div className="mb-4">
                    <p className="font12 font700 colordark mb-1">Country</p>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={addressData?.country ? addressData.country : ""}
                      readOnly
                    />
                  </div>
                  <div className="mb-4">
                    <p className="font12 font700 colordark mb-1">State</p>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={addressData?.state ? addressData.state : ""}
                      readOnly
                    />
                  </div>
                  <div className="mb-4">
                    <p className="font12 font700 colordark mb-1">City</p>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={addressData?.city ? addressData.city : ""}
                      readOnly
                    />
                  </div>
                  <div className="mb-4">
                    <p className="font12 font700 colordark mb-1">Zip Code</p>
                    <Form.Control
                      type="text"
                      placeholder=""
                      value={addressData?.zipcode ? addressData.zipcode : ""}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </>
          ) : newAddress ? (
            <>
              <div className="mb-4">
                <p className="font12 font700 colordark mb-1">
                  Address 1 <span className="error">*</span>
                </p>
                <Autocomplete
                  apiKey={process.env.REACT_APP_MAP_KEY}
                  placeholder="Address 1"
                  className="form-control"
                  name="address1"
                  value={values?.address1}
                  options={{
                    types: ["address"],
                    componentRestrictions: { country: "in" },
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onPlaceSelected={(place) => {
                    handlePlaces(place);
                  }}
                />

                <span className="text-start required">
                  {touched.address1 && errors.address1}
                </span>
              </div>
              <div className="mb-4">
                <p className="font12 font700 colordark mb-1">
                  Address 2{/* <span className="error">*</span> */}
                </p>
                <Form.Control
                  type="text"
                  placeholder="Address 2"
                  name="address2"
                  value={values?.address2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {/* <span className="text-start required">
                  {touched.address2 && errors.address2}
                </span> */}
              </div>
              <div>
                <div className="d-none d-md-flex align-items-center gap-4">
                  <div className="mb-4">
                    <p className="font12 font700 colordark mb-1">
                      City <span className="error">*</span>
                    </p>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="City"
                      onChange={handleChange}
                      value={values?.city}
                      onBlur={handleBlur}
                    />
                    <span className="text-start required">
                      {touched.city && errors.city}
                    </span>
                  </div>

                  <div className="mb-4">
                    <p className="font12 font700 colordark mb-1">
                      State <span className="error">*</span>
                    </p>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      name="state"
                      value={values?.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="text-start required">
                      {touched.state && errors.state}
                    </span>
                  </div>
                  <div className="mb-4">
                    <p className="font12 font700 colordark mb-1">
                      Country <span className="error">*</span>
                    </p>
                    <Form.Control
                      type="text"
                      placeholder="Country"
                      name="country"
                      value={values?.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="text-start required">
                      {touched.country && errors.country}
                    </span>
                  </div>
                  <div className="mb-4">
                    <p className="font12 font700 colordark mb-1">
                      Zip Code <span className="error">*</span>
                    </p>
                    <Form.Control
                      type="text"
                      placeholder="Zip Code"
                      name="zipcode"
                      value={values?.zipcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <span className="text-start required">
                      {touched.zipcode && errors.zipcode}
                    </span>
                  </div>
                </div>
                {/* <Row className="d-none d-md-flex">
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        Country Code <span className="error">*</span>
                      </Form.Label>
                      <Dropdown
                        name="country_code"
                        value={country}
                        options={countryCodeOptions()}
                        onChange={handleCountryChange}
                        onBlur={() => setFieldTouched("country_code", true)}
                      />
                      <span className="text-start required">
                        {touched.country_code && errors.country_code}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        Phone Number <span className="error">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={handleChange}
                        name="mobile_no"
                        placeholder="Enter phone number "
                        value={values?.mobile_no}
                        onBlur={handleBlur}
                        maxLength={10}
                      />
                      <span className="text-start required">
                        {touched.mobile_no && errors.mobile_no}{" "}
                      </span>
                    </Form.Group>
                  </Col>
                </Row> */}
                <div className="d-block d-md-none ">
                  <div className="d-flex align-items-center gap-4">
                    <div className="mb-4">
                      <p className="font12 font700 colordark mb-1">
                        City <span className="error">*</span>
                      </p>
                      <Form.Control
                        type="text"
                        name="city"
                        placeholder="City"
                        onChange={handleChange}
                        value={values?.city}
                        onBlur={handleBlur}
                      />
                      <span className="text-start required">
                        {touched.city && errors.city}{" "}
                      </span>
                    </div>

                    <div className="mb-4">
                      <p className="font12 font700 colordark mb-1">
                        State <span className="error">*</span>
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="State"
                        name="state"
                        value={values?.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-start required">
                        {touched.state && errors.state}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-4">
                    <div className="mb-4">
                      <p className="font12 font700 colordark mb-1">
                        Country <span className="error">*</span>
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="Country"
                        name="country"
                        value={values?.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-start required">
                        {touched.country && errors.country}
                      </span>
                    </div>
                    <div className="mb-4">
                      <p className="font12 font700 colordark mb-1">
                        Zip Code <span className="error">*</span>
                      </p>
                      <Form.Control
                        type="text"
                        placeholder="Zip Code"
                        name="zipcode"
                        value={values?.zipcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-start required">
                        {touched.zipcode && errors.zipcode}
                      </span>
                    </div>
                  </div>
                  {/* <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          Country Code <span className="error">*</span>
                        </Form.Label>
                        <Dropdown
                          name="country_code"
                          value={country}
                          options={countryCodeOptions()}
                          onChange={handleCountryChange}
                          onBlur={() => setFieldTouched("country_code", true)}
                        />

                        <span className="text-start required">
                          {touched.country_code && errors.country_code}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          Phone Number <span className="error">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={handleChange}
                          name="mobile_no"
                          placeholder="Enter phone number"
                          value={values?.mobile_no}
                          onBlur={handleBlur}
                          maxLength={10}
                        />
                        <span className="text-start required">
                          {touched.mobile_no && errors.mobile_no}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row> */}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </Col>

        <div className="mt-3">
          <span
            className="btn btn-theme mb-0 mx-1"
            onClick={() => {
              newAddress ? handleSubmit() : recordSave();
            }}
          >
            Save
          </span>
          <span
            className="btn btn-theme mb-0 mx-1"
            onClick={() => backAction()}
          >
            Back
          </span>
        </div>
      </Row>

      {loading && <Loader />}
    </>
  );
};

export default Location;
