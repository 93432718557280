/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";
import * as Yup from "yup";
import {
  addProductInformation,
  deleteProduct,
  getCategory,
  getSubCategory,
} from "../../../Services/APIServices";
import { RightArrownIcon } from "../../../SvgIcons/allIcons";
import {
  ACTIVE_STATE,
  INACTIVE_STATE,
  RENTER,
} from "../../../globals/constants";
import { NumberWithdecimal } from "../../../utils/CommonFunction";
import Dropdown from "../../../Commons/Dropdown";
import { useSelector } from "react-redux";
import Loader from "../../../Commons/Loader/Loader";

const ListingBasic = ({ productDetails }) => {
  const location = useLocation();
  const rid = new URLSearchParams(location?.search)?.get("rid") ?? "null";
  const [category, setCategory] = useState("");
  const [state, setState] = useState({ label: "Active", value: ACTIVE_STATE });
  const [subCategory, setSubCategory] = useState("");
  const userRole = useSelector((state) => state?.Login?.userDetails?.role_id);
  const navigate = useNavigate();
  const [loading, setLoding] = useState(false);

  useEffect(() => {
    if (rid) {
      setState({
        label: productDetails?.product_status === 1 ? "Active" : "Inactive",
        value:
          productDetails?.product_status === 1 ? ACTIVE_STATE : INACTIVE_STATE,
      });
    }
  }, [productDetails]);

  const {
    handleChange,
    values,
    handleBlur,
    errors,
    touched,
    setValues,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      title: "",
      description: "",
      height: "",
      width: "",
      length: "",
      weight: "",
      category: "",
      sub_category: "",
    },
    validationSchema,
    onSubmit(values) {
      setLoding(true);
      let body = {
        title: values?.title?.trim(),
        description: values?.description?.trim(),
        height: values?.height,
        width: values?.width,
        length: values?.length,
        weight: values?.weight,
        category: values?.category,
        subcategory: values?.sub_category,
      };

      let formData = new FormData();
      Object.keys(body).forEach((i) => {
        formData.append(i, body[i]);
      });
      if (rid != "null") {
        formData.append("product_id", rid);
        if (productDetails?.steps == 8) {
          formData.append("product_status", state?.value);
        }
      }
      addProductInformation(formData).then((resp) => {
        setLoding(false);
        if (resp?.data?.status === 200) {
          navigate("/lender/new-item?atab=second&rid=" + resp?.data?.data?.id);
        }
        if (resp?.data?.is_profile_completed === false) {
          if (userRole == RENTER) {
            navigate("/renter/profile");
          } else {
            navigate("/lender/profile");
          }
        }
      });
    },
  });

  useEffect(() => {
    if (rid && productDetails) {
      setValues({
        ...values,
        title: productDetails?.title,
        description: productDetails?.description,
        height: productDetails?.height,
        width: productDetails?.width,
        length: productDetails?.length,
        weight: productDetails?.weight,
        category: productDetails?.product_category?.id,
        sub_category: productDetails?.product_subcategory?.id,
      });
      setSubCategory({
        value: productDetails?.product_subcategory?.id,
        label: productDetails?.product_subcategory?.title,
      });
      setCategory({
        value: productDetails?.product_category?.id,
        label: productDetails?.product_category?.title,
      });
    }
  }, [productDetails]);

  const loadCategory = async (search, loadedOptions, { page }) => {
    let resp = await getCategory(page);
    let array = (await resp?.data?.data) ?? [];
    return {
      options: array.map((item) => ({
        value: item?.id,
        label: item?.title,
      })),
      hasMore:
        loadedOptions.length === resp?.data?.meta?.total_results ? false : true,
      additional: {
        page: search ? page + 1 : page + 1,
      },
    };
  };

  const handleCategorySelect = (data) => {
    setFieldValue("category", data?.value);
    setCategory(data);
    setFieldTouched("category", false);
    setSubCategory("");
  };

  const loadSubCategory = async (search, loadedOptions, { page }) => {
    let resp =
      values?.category && (await getSubCategory(values?.category, page));
    let array = (await resp?.data?.data) ?? [];
    return {
      options: array?.map((item) => ({
        value: item?.id,
        label: item?.title,
      })),
      hasMore:
        loadedOptions.length === resp?.data?.meta?.total_results ? false : true,
      additional: {
        page: search ? page + 1 : page + 1,
      },
    };
  };

  const handleSubCategorySelect = (data) => {
    setFieldValue("sub_category", data?.value);
    setFieldTouched("sub_category", false);
    setSubCategory(data);
  };

  const handleDeleteItem = () => {
    let formData = new FormData();
    formData.append("product_id", rid);
    deleteProduct(formData).then((resp) => {
      if (resp?.data?.status === 200) {
        navigate("/lender/listing");
      }
    });
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    control: (provided) => ({
      ...provided,
      height: "50%",
      border: "1px solid #E8E8E8",
      backgroundColor: "#fff",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #E8E8E8",
      },
      outline: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  return (
    <>
      <p className="head16">
        Item Details
        <span className="mx-2">
          <RightArrownIcon />
        </span>
        Listing Basics
      </p>
      <Row>
        <Col lg={7} md={7}>
          <div className="mb-4">
            <p className="font12 font700 colordark mb-1">
              Product Title <span className="error">*</span>
            </p>

            <Form.Control
              type="text"
              placeholder=" product title "
              name="title"
              value={values?.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span className="text-start required">
              {touched?.title && errors?.title}
            </span>
          </div>
          <div className="mb-4">
            <p className="font12 font700 colordark mb-1">
              Product Description <span className="error">*</span>
            </p>

            <Form.Control
              as="textarea"
              placeholder=" product description"
              rows={6}
              name="description"
              value={values?.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <span className="text-start required">
              {touched?.description && errors?.description}
            </span>
          </div>

          <p className="font12 font700 colordark mb-1">Product Measurements</p>
          <div className="row align-items-start">
            <Form.Group className="col-6 mb-4">
              <Form.Label className="font12 font700 colordark mb-1">
                Height (cm) <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="height"
                maxLength={4}
                value={values?.height}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={NumberWithdecimal}
              />

              <span className="text-start required">
                {touched?.height && errors?.height}
              </span>
            </Form.Group>
            <Form.Group className="col-6 mb-4">
              <Form.Label className="font12 font700 colordark mb-1">
                Width (cm) <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                maxLength={4}
                name="width"
                value={values?.width}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={NumberWithdecimal}
              />

              <span className="text-start required">
                {touched?.width && errors?.width}
              </span>
            </Form.Group>
            <Form.Group className="col-6 mb-4">
              <Form.Label className="font12 font700 colordark mb-1">
                Length (cm) <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="length"
                maxLength={4}
                value={values?.length}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={NumberWithdecimal}
              />

              <span className="text-start required">
                {touched?.length && errors?.length}
              </span>
            </Form.Group>
            <Form.Group className="col-6 mb-4">
              <Form.Label className="font12 font700 colordark mb-1">
                Weight (kg) <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="weight"
                maxLength={4}
                value={values?.weight}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={NumberWithdecimal}
              />

              <span className="text-start required">
                {touched?.weight && errors?.weight}
              </span>
            </Form.Group>
          </div>

          <div className="d-flex align-items-start flex-wrap">
            <Form.Group className="mb-4  w-100">
              <Form.Label className="font12 font700 colordark mb-1">
                Category <span className="error">*</span>
              </Form.Label>
              <AsyncPaginate
                name="category"
                className="font16"
                loadOptions={loadCategory}
                maxMenuHeight={150}
                onChange={handleCategorySelect}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "rgb(22 255 150 / 24%)",
                    primary: "#16ff961a",
                  },
                })}
                styles={customStyles}
                
                additional={{
                  page: 1,
                }}
                isClearable
                value={category}
                placeholder="Select Category"
                onBlur={() => setFieldTouched("category", true)}
              />
              <span className="text-start required">
                {touched?.category && errors?.category}
              </span>
            </Form.Group>
            <Form.Group className="mb-4 w-100">
              <Form.Label className="font12 font700 colordark mb-1">
                Subcategory <span className="error">*</span>
              </Form.Label>
              <AsyncPaginate
                key={values?.category}
                name="sub_category"
                className="font16"
                loadOptions={loadSubCategory}
                onChange={handleSubCategorySelect}
                maxMenuHeight={90}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "rgb(22 255 150 / 24%)",
                    primary: "#16ff961a",
                  },
                })}
                styles={customStyles}
                additional={{
                  page: 1,
                }}
                isClearable
                value={subCategory}
                placeholder="Select Sub-Category"
                onBlur={() => setFieldTouched("sub_category", true)}
              />
              <span className="text-start required">
                {touched?.sub_category && errors?.sub_category}
              </span>
            </Form.Group>
          </div>

          {rid != "null" && (
            <>
              <div className="d-flex align-items-center gap-4">
                {productDetails?.steps == 8 && (
                  <Form.Group className="mb-4  w-50">
                    <Form.Label className="font12 font700 colordark mb-1">
                      Status
                    </Form.Label>
                    <Dropdown
                      name="status"
                      value={state}
                      clearable={false}
                      options={[
                        { label: "Active", value: ACTIVE_STATE },
                        { label: "Inactive", value: INACTIVE_STATE },
                      ]}
                      placeholder={"Select"}
                      onChange={setState}
                    />
                  </Form.Group>
                )}

                <span
                  className="text-danger fw-bold mb-2 mx-1 pe-auto cursor"
                  onClick={handleDeleteItem}
                >
                  Delete Item
                </span>
              </div>
            </>
          )}

          <Button
            className="btn btn-theme mb-0 mx-1"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Col>
      </Row>
      {loading && <Loader />}
    </>
  );
};

const validationSchema = Yup.object({
  title: Yup.string().required("This field is required").trim(),
  description: Yup.string().required("This field is required").trim(),
  height: Yup.number()
    .required("Height required")
    .moreThan(0, "Height should be greater than 0")
    .typeError("Invalid Input"),
  width: Yup.number()
    .required("Width required")
    .moreThan(0, "Width should be greater than 0")
    .typeError("Invalid Input"),
  length: Yup.number()
    .required("Length required")
    .moreThan(0, "Length should be greater than 0")
    .typeError("Invalid Input"),
  weight: Yup.number()
    .required("Weight required")
    .moreThan(0, "Weight should be greater than 0")
    .typeError("Invalid Input"),
  category: Yup.string().required("This field is required"),
  sub_category: Yup.string().required("This field is required"),
});
export default ListingBasic;
