/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";

import Header from "./Headers/Header";
import Footer from "./Footer";
import * as APISERVICES from "../Services/APIServices";
import showNotification from "../Services/NotificationService";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { LENDER, RENTER } from "../globals/constants";
import LenderHeader from "./Headers/LenderHeader";
import RenterHeader from "./Headers/RenterHeader";
import useRole from "../hooks/useRole";

const ContactUs = () => {
  const location = useLocation();
  const role = useRole();
  const id = new URLSearchParams(location?.search).get("list_id");

  const [product, setProduct] = useState();

  useEffect(() => {
    handleProduct();
  }, []);

  const handleProduct = () => {
    APISERVICES.LanderProductDetails(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setProduct(resp?.data?.data);
      }
    });
  };

  const validationSchema = Yup.object({
    full_name: Yup.string().required("This field is required").trim(),
    email: Yup.string()
      .required("This field is required.")
      .matches(/^[a-zA-Z0-9_.+-]+[@]\w+[.]\w{2,3}/, "Please enter valid email"),
    message: Yup.string().required("This field is required."),
  });

  const { handleSubmit, handleChange, values, errors, handleBlur, touched } =
    useFormik({
      initialValues: {
        full_name: "",
        email: "",
        message: "",
      },
      validationSchema,
      onSubmit(values, { setSubmitting, resetForm }) {
        setSubmitting(true);
        let body = {
          full_name: values.full_name,
          email: values.email,
          message: values.message,
        };

        let formData = new FormData();
        Object.keys(body).forEach((data, key) => {
          formData.append(data, body[data]);
        });

        APISERVICES.addSupport(formData).then((resp) => {
          setSubmitting(false);
          if (resp?.status === 200) {          
            resetForm();
          } else {
            showNotification("danger", resp?.data?.message);
          }
        });
      },
    });

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        <div className="top-head">
          <Container>
            <div className="row">
              <Col>
                <h4 className="font48">Admin Support </h4>
              </Col>
            </div>
          </Container>
        </div>
        <div className="top-area newsletter pt-36 pb-156 mt-131 mb-151">
          <Container>
            <Row>
              <Col lg={7} md={7}>
                <h1 className="mb-70 font900 font24">{product?.title}</h1>
                <h2 className="font40  font700">{product?.description}</h2>
              </Col>
              <Col lg={5} md={5}>
                <Form className="align-items-center justify-content-between mt-5">
                  <Form.Group className="m-5  w-90">
                    <Form.Label className="font24 font700">
                      Enter Fullname
                    </Form.Label>
                    <span className="error">*</span>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="full_name"
                      value={values?.full_name}
                      type="text"
                      className="email"
                    />
                    <span className="text-start required">
                      {touched.full_name && errors.full_name}
                    </span>
                  </Form.Group>

                  <Form.Group className="m-5 w-90">
                    <Form.Label className="font24 font700">
                      Enter Email
                    </Form.Label>
                    <span className="error">*</span>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="email"
                      value={values?.email}
                      type="email"
                      className="email"
                    />
                    <span className="text-start required">
                      {touched.email && errors.email}
                    </span>
                  </Form.Group>

                  <Form.Group className="m-5 w-90">
                    <Form.Label className="font24 font700">
                      Enter message
                    </Form.Label>
                    <span className="error">*</span>
                    <Form.Control
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="message"
                      as="textarea"
                      rows={3}
                      value={values?.message}
                      type="text"
                      className="email"
                    />
                    <span className="text-start required">
                      {touched.message && errors.message}
                    </span>
                  </Form.Group>

                  <button
                    className="btn m-3 btn-theme ml-5"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
