/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Nodata from "../../Commons/CommonModals/Nodata";
import Dropdown from "../../Commons/Dropdown";
import Footer from "../../Commons/Footer";
import Loader from "../../Commons/Loader/Loader";
import { reservedProduct } from "../../Services/APIServices";
import { BtnRightIcon } from "../../SvgIcons/allIcons";
import { imageBase } from "../../globals/Config";
import {
  PERPAGE_TEN,
  RENTER_CURRENT_RENTING,
  RENTER_FUTURE_RENTALS,
  RENTER_PAST_RENTALS,
} from "../../globals/constants";

const Reservationmobile = () => {
  const [product, setProduct] = useState([]);
  const [meta, setMeta] = useState("");
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState("1");
  const [perPage, setPerPage] = useState(PERPAGE_TEN);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getreserveProductApi(page, perPage, search);
  }, []);

  const getreserveProductApi = (
    page,
    perPage = PERPAGE_TEN,
    search = "",
    search_title
  ) => {
    reservedProduct(page, perPage, search, search_title)
      .then((resp) => {
        setLoader(false);
        if (resp?.status === 200) {
          setProduct(resp?.data?.data);
          setMeta(resp?.data?.meta);
        }
      })
      .finally(() => setLoader(false));
  };

  const handleSearch = () => {
    getreserveProductApi(page, perPage, search?.value);
  };

  const handleViewReservtion = (data) => {
    navigate(
      `/lender/reservation-detail?id=${data?.id}&pid=${data?.product_id}`
    );
  };

  return (
    <>
      <div className="top-breadcrumb top-area bg-white">
        <Container>
          <Row>
            <Col xs={9}>
              <Form.Label className="font700">View</Form.Label>
              <Dropdown
                name="reservation"
                value={search}
                clearable={true}
                options={[
                  {
                    label: "Currently Renting",
                    value: RENTER_CURRENT_RENTING,
                  },
                  { label: "Past Rentals", value: RENTER_PAST_RENTALS },
                  { label: "Future Rentals", value: RENTER_FUTURE_RENTALS },
                ]}
                placeholder={"Select"}
                onChange={setSearch}
              />
            </Col>
            <Col xs={3}>
              <Button className="btn btn-theme mb-0" onClick={handleSearch}>
                Apply
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="accountmain mobile-list mb-5 mt-0">
        <Container>
          <Row>
            {product && product?.length > 0 ? (
              product?.map((data, index) => {
                return (
                  <Col lg={3} md={4} sm={12} key={index}>
                    <Card className="mb-4">
                      <Card.Img
                        variant="top"
                        src={
                          data?.product_image?.images
                            ? imageBase + data?.product_image?.images
                            : require("../../assets/images/static_image.jpg")
                        }
                      />
                      <Card.Body>
                        <div
                          className="d-flex align-items-center gap-3 img-box mb-3"
                          onClick={() =>
                            navigate(
                              `/lender/user-profile?user_id=${data?.created_by?.id}`
                            )
                          }
                        >
                          <img
                            src={
                              data?.created_by_image
                                ? imageBase + data?.created_by_image
                                : require("../../assets/images/DummyImg.png")
                            }
                            width="40px"
                            height="40px"
                            className="avtar rounded-circle"
                            alt="Img"
                          />
                          <h6 className="mb-0">
                            {data?.created_by?.full_name}
                          </h6>
                        </div>
                        <div onClick={() => handleViewReservtion(data)}>
                          <p className="font12 font700 mb-2">
                            {data?.booked_from} to {data?.booked_to}{" "}
                          </p>
                          <h5 className="head16">{data?.title}</h5>
                          <p className="mb-0">
                            <span className="colordark font700 font12 mb-0 btn-shop">
                              See More Details{" "}
                              <span className="ms-2">
                                <BtnRightIcon />{" "}
                              </span>
                            </span>
                          </p>
                        </div>

                        <p className="mb-0">
                          {moment(data?.booked_to).format("YYYY-MM-DD") <
                            moment().format("YYYY-MM-DD") && (
                            <Link
                              to={`/lender/write-review?id=${data?.id}&pid=${data?.product_id}`}
                              className="colordark font700 font12 mb-0 btn-shop"
                            >
                              Write Review{" "}
                              <span className="ms-2">
                                <BtnRightIcon />{" "}
                              </span>
                            </Link>
                          )}
                        </p>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })
            ) : (
              <>{!loader && <Nodata />}</>
            )}
          </Row>
        </Container>
      </div>
      <Footer />
      {loader && <Loader />}
    </>
  );
};

export default Reservationmobile;
