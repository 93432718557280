import React from "react";
import { Route, Routes } from "react-router-dom";
import MobileProfile from "./Commons/MyProfile/MobileProfile";
import Profile from "./Commons/MyProfile/Profile";
import NotFound from "./Commons/NotFound";
import RenterSettings from "./Commons/ProfileSettings/Settings";
import CatagoryMap from "./Components/CategoryItem";
import AddAddressModal from "./Components/CommonModals/AddAddressModal";
import AddCardMobile from "./Components/CommonModals/AddCardMobile";
import AddNewAddress from "./Components/CommonModals/AddNewAddress";
import AddNewCard from "./Components/CommonModals/AddNewCard";
import PaymentModal from "./Components/CommonModals/PaymentModal";
import SendToOther from "./Components/CommonModals/SendToOther";
import ReservationDetail from "./Components/Lender/ReservationDetail";
import Chat from "./Components/Message/Chat";
import About from "./Components/Renter/About";
import AddRating from "./Components/Renter/AddRatingmobile";
import AddRatingmobile2 from "./Components/Renter/AddRatingmobile2";
import CartPage from "./Components/Renter/CartPage";
import Checkout from "./Components/Renter/Checkout/Checkout";
import MyRentals from "./Components/Renter/MyRentals";
import RenterCareers from "./Components/Renter/RenterCareers";
import RenterDetails from "./Components/Renter/RenterDetails";
import RenterExchangeRate from "./Components/Renter/RenterExchangeRate";
import RenterHome from "./Components/Renter/RenterHome";
import RenterNews from "./Components/Renter/RenterNews";
import RenterSavedItems from "./Components/Renter/RenterSavedItems";
import RenterSustainbility from "./Components/Renter/RenterSustainbility";
import ViewAvailability from "./Components/Renter/ViewAvailability";
import WriteReview from "./Components/Renter/WriteReview";
import ReviewsPage from "./Components/ReviewsPage";
const RenterRoutes = () => {
  return (
    <Routes>
      <Route path="/profile" element={<Profile />} />
      <Route path="/home" element={<RenterHome />} />
      <Route path="/careers" element={<RenterCareers />} />
      <Route path="/newsletter" element={<RenterNews />} />
      <Route path="/how-it-works" element={<RenterSustainbility />} />
      <Route path="/exchange-rate" element={<RenterExchangeRate />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/saved-items" element={<RenterSavedItems />} />
      <Route path="/category-map-view" element={<CatagoryMap />} />
      <Route path="/my-rental" element={<MyRentals />} />
      <Route path="/renter-details" element={<RenterDetails />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/settings" element={<RenterSettings />} />
      <Route path="/mobile-profile" element={<MobileProfile />} />
      <Route path="/message" element={<Chat />} />
      <Route path="/write-review" element={<WriteReview />} />
      <Route path="/my-rental-detail" element={<ReservationDetail />} />
      <Route path="/address-modal" element={<AddAddressModal />} />
      <Route path="/sustainbility" element={<RenterSustainbility />} />
      <Route path="/payment-modal" element={<PaymentModal />} />
      <Route path="/add-new-address" element={<SendToOther />} />
      <Route path="/add-new-card" element={<AddNewCard />} />
      <Route path="/addnewaddress-mobile" element={<AddNewAddress />} />
      <Route path="/add-card-mobile" element={<AddCardMobile />} />
      <Route path="/addratingmobile" element={<AddRating />} />
      <Route path="/addratingmobile2" element={<AddRatingmobile2 />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/user-profile" element={<ReviewsPage />} />
      <Route path="/view-availablity" element={<ViewAvailability />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RenterRoutes;
