import React from "react";
import { Route, Routes } from "react-router-dom";
import MobileProfile from "./Commons/MyProfile/MobileProfile";
import Profile from "./Commons/MyProfile/Profile";
import NotFound from "./Commons/NotFound";
import Settings from "./Commons/ProfileSettings/Settings";
import AddNewAddress from "./Components/CommonModals/AddNewAddress";
import AddNewCard from "./Components/CommonModals/AddNewCard";
import Contact from "./Components/Contact";
import Calendar from "./Components/Lender/Calendar";
import CreateNewListing from "./Components/Lender/LenderAddProduct/CreateNewListing";
import MobileCalender from "./Components/Lender/LenderAddProduct/MobileCalender";
import LenderHome from "./Components/Lender/LenderHome";
import LenderListing from "./Components/Lender/LenderListing";
import Reservation from "./Components/Lender/Reservation";
import ReservationDetail from "./Components/Lender/ReservationDetail";
import Chat from "./Components/Message/Chat";
import MobileCategoryItem from "./Components/MobileCategoryItem";
import AddRatingmobile from "./Components/Renter/AddRatingmobile";
import AddRatingmobile2 from "./Components/Renter/AddRatingmobile2";
import AddressModal from "./Components/Renter/Checkout/AddressModal";
import RenterCareers from "./Components/Renter/RenterCareers";
import RenterExchangeRate from "./Components/Renter/RenterExchangeRate";
import RenterNews from "./Components/Renter/RenterNews";
import RenterSustainbility from "./Components/Renter/RenterSustainbility";
import WriteReview from "./Components/Renter/WriteReview";
import ReviewsPage from "./Components/ReviewsPage";

const LenderRoutes = () => {
  return (
    <Routes>
      <Route path="/home" element={<LenderHome />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/listing" element={<LenderListing />} />
      <Route path="/addressModal" element={<AddressModal />} />
      <Route path="/new-item" element={<CreateNewListing />} />
      <Route path="/how-it-works" element={<RenterSustainbility />} />
      <Route path="/reservation" element={<Reservation />} />
      <Route path="/careers" element={<RenterCareers />} />
      <Route path="/newsletter" element={<RenterNews />} />
      <Route path="/reservation-detail" element={<ReservationDetail />} />
      <Route path="/exchange-rate" element={<RenterExchangeRate />} />
      <Route path="/calender" element={<Calendar />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/write-review" element={<WriteReview />} />
      <Route path="/mobile-profile" element={<MobileProfile />} />
      <Route path="/mobile-category-item" element={<MobileCategoryItem />} />
      <Route path="/message" element={<Chat />} />
      <Route path="/add-new-card" element={<AddNewCard />} />
      <Route path="/addnewaddress-mobile" element={<AddNewAddress />} />
      <Route path="/mobile-calender" element={<MobileCalender />} />
      <Route path="/addratingmobile" element={<AddRatingmobile />} />
      <Route path="/addratingmobile2" element={<AddRatingmobile2 />} />
      <Route path="/user-profile" element={<ReviewsPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default LenderRoutes;
