import { Navigate, Outlet } from "react-router-dom";
import { LENDER, RENTER } from "./globals/constants";
import useRole from "./hooks/useRole";

export const RequireRenterAuth = () => {
  const token = localStorage.getItem("token");
  const userRole = useRole();
  if (token) {
    if (userRole === RENTER) {
      return <Outlet />;
    } else {
      return <Navigate to="/lender/listing" replace />;
    }
  } else {
    return <Navigate to="/" replace />;
  }
};

export const RequireLenderAuth = () => {
  const token = localStorage.getItem("token");
  const userRole = useRole();
  if (token) {
    if (userRole === LENDER) {
      return <Outlet />;
    } else {
      return <Navigate to="/renter/home" replace />;
    }
  } else {
    return <Navigate to="/" replace />;
  }
};

export default RequireRenterAuth;
