import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: "",
  payment: "",
};

export const checkoutAddressSlice = createSlice({
  name: "checkout_address_slice",
  initialState,
  reducers: {
    checkoutAddress: (state, action) => {
      state.address = action.payload;
    },
    checkoutPayment: (state, action) => {
      state.payment = action.payload;
    },
  },
});

export const { checkoutAddress, checkoutPayment } =
  checkoutAddressSlice.actions;
export default checkoutAddressSlice.reducer;
