/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { AddDiscount } from "../../../Services/APIServices";
import { RightArrownIcon } from "../../../SvgIcons/allIcons";
import { NumberWithdecimal } from "../../../utils/CommonFunction";
import showNotification from "../../../Services/NotificationService";
import { STEP_FOUR } from "../../../globals/constants";
import Loader from "../../../Commons/Loader/Loader";
import * as Yup from "yup";
const Discounts = ({ productDetails }) => {
  const location = useLocation();
  const rid = new URLSearchParams(location?.search).get("rid");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    weekly_discount: Yup.number()
      .typeError("Invalid Input, must be a number")
      .nullable(),
    biweekly_discount: Yup.number()
      .typeError("Invalid Input, must be a number")
      .nullable(),
    monthly_discount: Yup.number()
      .typeError("Invalid Input, must be a number")
      .nullable(),
    rental_length_period: Yup.number()
      .typeError("Invalid Input, must be a number")
      .nullable(),
    discount: Yup.number().typeError("Invalid Input").nullable(),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    setValues,
    isValid,
  } = useFormik({
    initialValues: {
      product_id: rid,
      weekly_discount: "",
      biweekly_discount: "",
      monthly_discount: "",
      rental_length_period: "",
      discount: "",
    },
    validationSchema,
    onSubmit(values) {
      setLoading(true);
      let formData = new FormData();
      formData.append("product_id", rid);
      if (values.weekly_discount) {
        formData.append("weekly_discount", values.weekly_discount);
      }
      if (values.biweekly_discount) {
        formData.append("biweekly_discount", values.biweekly_discount);
      }
      if (values.monthly_discount) {
        formData.append("monthly_discount", values.monthly_discount);
      }
      if (values.rental_length_period) {
        formData.append("rental_length_period", values.rental_length_period);
      }
      if (values.discount) {
        formData.append("discount", values.discount);
      }
      if (productDetails?.steps >= STEP_FOUR) {
        AddDiscount(formData)
          .then((resp) => {
            setLoading(false);
            if (resp?.status === 200) {
              navigate("/lender/new-item?atab=sixth&rid=" + rid);
            }
          })
          .catch((err) => console.log(err));
      } else {
        setLoading(false);
        showNotification("danger", "Please complete previous steps to proceed");
      }
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isValid) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    if (rid && productDetails) {
      setValues({
        ...values,
        weekly_discount: productDetails?.weekly_discount,
        biweekly_discount: productDetails?.biweekly_discount,
        monthly_discount: productDetails?.monthly_discount,
        rental_length_period: productDetails?.rental_length_period,
        discount: productDetails?.discount,
      });
    }
  }, [productDetails]);

  const backAction = () => {
    navigate("/lender/new-item?atab=fourth&rid=" + rid);
  };
  return (
    <>
      <p className="head16">
        Pricing & Availability
        <span className="mx-2">
          <RightArrownIcon />
        </span>
        Discounts
      </p>
      <Row>
        <Col lg={7} md={6}>
          <div className="mb-4">
            <p className="font12 font700 colordark mb-1">Weekly Discount</p>

            <Form.Control
              type="text"
              name="weekly_discount"
              value={values?.weekly_discount}
              placeholder="weekly discount (%)"
              className="weekly_discount"
              onChange={handleChange}
              onBlur={handleBlur}
              maxLength={2}
              onKeyPress={NumberWithdecimal}
              onKeyDown={handleKeyDown}
            />
            {touched?.weekly_discount && errors?.weekly_discount && (
              <span className="text-start required">
                {errors?.weekly_discount}
              </span>
            )}
          </div>
          <div className="mb-4">
            <p className="font12 font700 colordark mb-1">Biweekly Discount</p>

            <Form.Control
              type="text"
              name="biweekly_discount"
              value={values?.biweekly_discount}
              placeholder="biweekly discount (%)"
              className="biweekly_discount"
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              maxLength={2}
              onKeyPress={NumberWithdecimal}
            />
            {touched?.biweekly_discount && errors?.biweekly_discount && (
              <span className="text-start required">
                {errors?.biweekly_discount}
              </span>
            )}
          </div>
          <div className="mb-4">
            <p className="font12 font700 colordark mb-1">Monthly Discount</p>

            <Form.Control
              type="text "
              name="monthly_discount"
              value={values?.monthly_discount}
              placeholder="monthly discount (%)"
              className="monthly_discount"
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyPress={NumberWithdecimal}
              onKeyDown={handleKeyDown}
              maxLength={2}
            />
            {touched?.monthly_discount && errors?.monthly_discount && (
              <span className="text-start required">
                {errors?.monthly_discount}
              </span>
            )}
          </div>
          <p className="font12 font700 colordark mb-2">Custom Period</p>
          <div className="d-flex gap-3 align-items-center w-100">
            <div className="w-100">
              <p className="font12 font700 colordark mb-1">
                Rental Length Period
              </p>

              <Form.Control
                type="text"
                name="rental_length_period"
                value={values?.rental_length_period}
                placeholder=" rental lenght period (days)"
                className="rental_length_period "
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={2}
                onKeyDown={handleKeyDown}
                onKeyPress={NumberWithdecimal}
              />
              {touched?.rental_length_period &&
                errors?.rental_length_period && (
                  <span className="text-start required">
                    {errors?.rental_length_period}
                  </span>
                )}
            </div>
            <div>
              <p className="font12 font700 colordark mb-1">Discount</p>

              <Form.Control
                type="text"
                name="discount"
                value={values?.discount}
                placeholder=" discount (%)"
                className="discount"
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={2}
                onKeyDown={handleKeyDown}
                onKeyPress={NumberWithdecimal}
              />
              {touched?.discount && errors?.discount && (
                <span className="text-start required">{errors?.discount}</span>
              )}
            </div>
          </div>
        </Col>
        <div className="mt-3 mx-1">
          <Button
            type="button"
            className="btn btn-theme mb-0"
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            type="button"
            className="btn btn-theme ms-2 mb-0 mx-1"
            onClick={() => backAction()}
          >
            Back
          </Button>
        </div>
      </Row>
      {loading && <Loader />}
    </>
  );
};

export default Discounts;
