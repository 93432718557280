/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Commons/Loader/Loader";
import { AddFee } from "../../../Services/APIServices";
import showNotification from "../../../Services/NotificationService";
import { RightArrownIcon } from "../../../SvgIcons/allIcons";
import { STEP_FIVE, STEP_FOUR } from "../../../globals/constants";
import { NumberWithdecimal } from "../../../utils/CommonFunction";
import * as Yup from "yup";

const Fees = ({ productDetails }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const rid = new URLSearchParams(location?.search).get("rid");
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    cleaning_fee: Yup.number()
      .typeError("Invalid Input, must be a number")
      .nullable(),
    taxes: Yup.number().typeError("Invalid Input, must be a number").nullable(),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    setValues,
  } = useFormik({
    initialValues: {
      product_id: rid,
      cleaning_fee: "",
      taxes: "",
    },
    validationSchema,
    onSubmit(values) {
      setLoading(true);
      let formData = new FormData();
      formData.append("product_id", rid);
      if (values.cleaning_fee) {
        formData.append("cleaning_fee", values.cleaning_fee);
      }
      if (values.taxes) {
        formData.append("taxes", values.taxes);
      }
      if (productDetails?.steps >= STEP_FOUR) {
        AddFee(formData)
          .then((resp) => {
            setLoading(false);
            if (resp?.status === 200) {
              navigate("/lender/new-item?atab=seventh&rid=" + rid);
            }
          })
          .catch((err) => console.log(err));
      } else {
        setLoading(false);
        showNotification("danger", "Please complete previous steps to proceed");
      }
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isValid) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    if (rid && productDetails) {
      setValues({
        ...values,
        cleaning_fee: productDetails?.cleaning_fee,
        taxes: productDetails?.taxes,
      });
    }
  }, [productDetails]);

  const backAction = () => {
    navigate("/lender/new-item?atab=fifth&rid=" + rid);
  };

  return (
    <>
      <p className="head16">
        Pricing & Availability
        <span className="mx-2">
          <RightArrownIcon />
        </span>
        Fees
      </p>
      <Row>
        <Col lg={6} md={6}>
          <div className="mb-4">
            <p className="font12 font700 colordark mb-1">Cleaning Fee</p>
            <Form.Control
              type="text"
              name="cleaning_fee"
              value={values?.cleaning_fee}
              placeholder="cleaning fee  "
              className="cleaning_fee"
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              onKeyPress={NumberWithdecimal}
              maxLength={6}
            />
            {touched?.cleaning_fee && errors?.cleaning_fee && (
              <span className="text-start required">
                {errors?.cleaning_fee}
              </span>
            )}
          </div>
          <div className="mb-4">
            <p className="font12 font700 colordark mb-1">Taxes</p>
            <div className="mb-4">
              <Form.Control
                type="text"
                name="taxes"
                value={values?.taxes}
                placeholder="taxes (%)"
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={NumberWithdecimal}
                onKeyDown={handleKeyDown}
                maxLength={2}
              />
              {touched?.taxes && errors?.taxes && (
                <span className="text-start required">{errors?.taxes}</span>
              )}
            </div>
          </div>
        </Col>
        <div className="mt-3">
          <Button
            type="button"
            className="btn btn-theme mb-0 mx-1"
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            type="button"
            className="btn btn-theme ms-2 mb-0 mx-1"
            onClick={() => backAction()}
          >
            Back
          </Button>
        </div>
      </Row>
      {loading && <Loader />}
    </>
  );
};

export default Fees;
