import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import { MyLocation } from "../../../SvgIcons/allIcons";

const containerStyle = {
  width: "100%",
  height: "100%",
};

let markers = [];

let markersForConsole = [];

function Map(props) {
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY,
  });

  const [currentPosition, setCurrentPosition] = useState({
    lat: 28.6139,
    lng: 77.209,
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
    if (map) {
      createMarkers(map);
    }
  }, [map, props?.props]);

  const clearMarkers = () => {
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];
  };
  const createMarkers = (map) => {
    if (markers.length > 0) {
      clearMarkers();
    }

    props?.props.map((item) => {
      let labelsClass = "marker_label_job_map";
      let icon = {
        url: require("../../../assets/big-marker.png"),
      };

      // PreParing array of Markers - allow if latitude & longitude not null

      if (
        item.product_address?.latitude != "" &&
        item.product_address?.longitude != ""
      ) {
        if (item.daily_price < 10) {
          icon.scaledSize = new window.google.maps.Size(40, 40);
          labelsClass += " lt10";
        } else if (item.daily_price >= 10 && item.daily_price < 100) {
          icon.scaledSize = new window.google.maps.Size(45, 45);
          labelsClass += " lt100";
        } else if (item.daily_price >= 100 && item.daily_price < 500) {
          icon.scaledSize = new window.google.maps.Size(55, 55);
          labelsClass += " lt500";
        } else if (item.daily_price >= 500 && item.daily_price < 1000) {
          icon.scaledSize = new window.google.maps.Size(60, 60);
          labelsClass += " lt1000";
        } else if (item.daily_price >= 1000 && item.daily_price < 5000) {
          icon.scaledSize = new window.google.maps.Size(68, 68);
          labelsClass += " lt5000";
        } else if (item.daily_price >= 5000 && item.daily_price < 10000) {
          icon.scaledSize = new window.google.maps.Size(72, 72);
          labelsClass += " lt10000";
        } else if (item.daily_price >= 10000 && item.daily_price < 100000) {
          icon.scaledSize = new window.google.maps.Size(75, 75);
          labelsClass += " lt10000";
        } else if (item.daily_price >= 100000 && item.daily_price < 1000000) {
          icon.scaledSize = new window.google.maps.Size(78, 72);
          labelsClass += " lt10000";
        } else if (item.daily_price >= 1000000 && item.daily_price < 10000000) {
          icon.scaledSize = new window.google.maps.Size(80, 80);
          labelsClass += " lt10000";
        } else {
          icon.scaledSize = new window.google.maps.Size(200, 200);
          labelsClass += " gt10000";
        }

        markersForConsole.push({
          item_id: item.id,
          lat: Number(item?.product_address?.latitude),
          lng: Number(item?.product_address?.longitude),
        });

        let marker = new MarkerWithLabel({
          position: {
            lat: Number(item?.product_address?.latitude),
            lng: Number(item?.product_address?.longitude),
          },
          map: map,
          labelContent: "₹" + Number(item?.daily_price)?.toLocaleString("hi"),
          icon: icon,
          labelAnchor: { x: 6, y: 27 },
          labelClass: labelsClass,
        });

        marker.addListener("click", () => {
          if (props?.device == "website") {
            document
              .getElementById(item.id)
              .scrollIntoView({ behavior: "smooth", block: "center" });
            props?.setShowActive(item?.id);
          } else {
            props?.setShowCard(true);
            props?.setShowData(item);
          }
        });

        markers.push(marker);
      }
    });
  };

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(currentPosition);
    map.fitBounds(bounds);
    setMap(map);
    createMarkers(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleClick = () => {
    const bounds = new window.google.maps.LatLngBounds(currentPosition);
    map.fitBounds(bounds);
    setMap(map);
    createMarkers(map);
  };

  const OPTIONS = {
    minZoom: 4,
    maxZoom: 20,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    showsMyLocationButton: true,
    showsUserLocation: true,
  };

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={OPTIONS}
        center={currentPosition}
        zoom={8}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <div onClick={handleClick} className="location_icon">
          <MyLocation />
        </div>
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default Map;
