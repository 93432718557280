import React from "react";
import "./Loader.css";

function Loader() {
  return (
    <div className="maindiv">
      <div>
        <div className="loadericon">
          <div className="outerCircle"></div>
          <div className="icon">
            <img
              alt=""
              width="10"
              src={require("../../assets/images/Shaxify.png")}
              className="logoname"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
