import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCartData } from "../Services/APIServices";

export const getCart = createAsyncThunk("cartList/getCart", async (page) => {
  let response = await getCartData(page);

  return response?.data?.data ?? [];
});

const cartListSlice = createSlice({
  name: "todoList",
  initialState: {
    cart: [],
    status: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, (state, action) => {
        state.cart = action.payload;
        state.status = null;
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.cart = action.payload;
      })
      .addCase(getCart.rejected, (state) => {
        state.status = "Failed to fetch data...";
      });
  },
});

export default cartListSlice.reducer;
