/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Footer from "../../../Commons/Footer";
import Header from "../../../Commons/Headers/Header";
import LenderHeader from "../../../Commons/Headers/LenderHeader";
import RenterHeader from "../../../Commons/Headers/RenterHeader";
import Loader from "../../../Commons/Loader/Loader";
import AddCardMoal from "../../../Commons/MyProfile/AddCardMoal";
import {
  checkoutAddress,
  checkoutPayment,
} from "../../../Redux/CheckoutAddressSlice";
import {
  CheckOut,
  ShowCardList,
  getAddressList,
  getPriceEstimation,
  getStaticPages,
} from "../../../Services/APIServices";
import showNotification from "../../../Services/NotificationService";
import {
  DownDarkIcon,
  MasterCardIconColor,
  VisaCardIconColor,
} from "../../../SvgIcons/allIcons";
import { useOnClickOutside } from "../../../globals/HelperCommon";
import {
  COOKIES,
  DEFAULT_CURRENCY,
  LENDER,
  MASTERCARD,
  PRIVACY_POLICY,
  RENTER,
  TERMS_AND_CONDITION,
  VISA,
} from "../../../globals/constants";
import useRole from "../../../hooks/useRole";
import AddressModal from "./AddressModal";
import PagesModal from "./PagesModal";
import { imageBase } from "../../../globals/Config";

const Checkout = () => {
  const role = useRole();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = new URLSearchParams(location?.search).get("id");
  const from = new URLSearchParams(location?.search).get("from");
  const to = new URLSearchParams(location?.search).get("to");
  const isCod = new URLSearchParams(location?.search).get("cod");
  const pincode = new URLSearchParams(location?.search).get("pincode");
  // Address Dropdown

  const [showContent, setShowContent] = useState(false);
  const [addressList, setAddressList] = useState([]);

  const ref = useRef();
  const paymentRef = useRef();
  useOnClickOutside(ref, () => setShowContent(false));

  const onClickHandler = () => {
    setShowContent((show) => !show);
  };

  const [showPaymentDropdown, setShowPaymentDropdown] = useState(false);

  const onClickPaymentHandler = () => {
    setShowPaymentDropdown((show) => !show);
  };
  useOnClickOutside(paymentRef, () => setShowPaymentDropdown(false));

  const [shownewadd, setshownewadd] = useState(false);
  const [showNewCard, setShowNewCard] = useState(false);
  const handleClosenewadd = () => setshownewadd(false);
  const handleshownewadd = () => setshownewadd(true);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [cardList, setCardList] = useState([]);
  const [selectedCard, setSelectedCard] = useState("");
  const [deliveryFess, setDeliveryFees] = useState("");
  const [pagesModal, setPagesModal] = useState(false);
  const [pageData, setPageData] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [cod, setCod] = useState(0);

  const handleCloseNewCard = () => setShowNewCard(false);
  const handleShowNewCard = () => setShowNewCard(true);

  const addressData = useSelector((state) => state?.checkout?.address);
  const paymentData = useSelector((state) => state?.checkout?.payment);

  useEffect(() => {
    AllCardList();
    if (id) {
      getCheckoutDetails("", isCod);
    }
    locationList();
    if (addressData) {
      setSelectedAddress(addressData);
      setFieldValue("addressId", addressData?.id);
    }
    if (paymentData || paymentData === 0) {
      setSelectedCard(paymentData);
      setFieldValue("cardId", paymentData?.id ? paymentData?.id : 0);
    }
  }, []);

  useEffect(() => {
    if (productDetails) {
      setDeliveryFees(productDetails?.cheapest_shipping);
      setFieldValue("deliveryOption", 1);
      setFieldValue("renter_delivery_carrier", productDetails?.cheapest_shipping_name || 'Receive at home / Return from home');
      setFieldValue("courier_id", productDetails?.courier_id);
    }
  }, [productDetails]);

  const getCheckoutDetails = (addData="", isCod="") => {
    let body = {
      product_id: id,
      from: from,
      to: to,
      deliver_to: addData?.zipcode || pincode,
      cod: isCod !== null && isCod !== undefined && isCod == 1 ? 1 : cod,
      address_id: addData?.id || (addressData?.id ? addressData?.id : 0),
    };
    console.log('body', body);
    setLoading(true);
    getPriceEstimation(body).then((resp) => {
      if (resp?.status == 200) {
        setLoading(false);
        setProductDetails(resp?.data);
      }
    });
  };

  const AllCardList = () => {
    setLoading(true);
    ShowCardList().then((res) => {
      setLoading(false);
      if (res?.status == 200) {
        setCardList(res?.data?.data);
      }
    });
  };

  const handleAddressChange = (data) => {
    setSelectedAddress(data);
    setFieldValue("addressId", data?.id);
    setShowContent(false);
    getCheckoutDetails(data, 0);
  };

  const handleAddressAdd = () => {
    if (selectedAddress) {
      setShowContent(false);
      setFieldValue("addressId", selectedAddress?.id);
    } else {
      showNotification("danger", "Select address to proceed");
    }
  };

  const handleCardChange = (data) => {
    setSelectedCard(data);
    setFieldValue("cardId", data?.id ? data?.id : 0);
    setShowPaymentDropdown(false);
  };

  const handleCardAdd = () => {
    if (selectedCard?.id || selectedCard === 0) {
      setFieldValue("cardId", selectedCard?.id ? selectedCard?.id : 0);
      setShowPaymentDropdown(false);
    } else {
      showNotification("danger", "Select payment option to proceed");
    }
  };

  const validationSchema = Yup.object({
    addressId: Yup.string().required("Address required"),
    cardId: Yup.string().required("Payment option required"),
    deliveryOption: Yup.string().required("Delivery type required"),
  });

  const { handleSubmit, errors, isSubmitting, setFieldValue } = useFormik({
    initialValues: {
      addressId: selectedAddress?.id,
      cardId: selectedCard?.id,
      deliveryOption: "",
      courier_id: "",
      renter_delivery_carrier: ""
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      let body = {
        product_id: id,
        delivery_options: values?.deliveryOption,
        renter_delivery_carrier: values?.renter_delivery_carrier,
        shipping_address: values?.addressId,
        shaxify_fee: productDetails?.shaxify_fee,
        total_amount: totalRentalPriceCalculator(),
        booked_from: moment(productDetails?.from_date).format("YYYY-MM-DD"),
        booked_to: moment(productDetails?.to_date).format("YYYY-MM-DD"),
        card_id: values?.cardId,
        courier_id: values?.courier_id,
        shipping_fee: deliveryFess,
      };
      let formData = new FormData();

      Object.keys(body).forEach((i) => {
        formData.append(i, body[i]);
      });
      setLoading(true);
      CheckOut(formData).then((resp) => {
        setSubmitting(false);
        setLoading(false);
        if (resp?.status === 200) {
          navigate(`/renter/my-rental`);
          dispatch(checkoutAddress(""));
          dispatch(checkoutPayment(""));
        }
      });
    },
  });

  const getPageData = (type) => {
    setLoading(true);
    getStaticPages(type).then((resp) => {
      setLoading(false);
      if (resp?.data?.status === 200) {
        setPageData(resp?.data?.data);
        setPagesModal(true);
      }
    });
  };

  const locationList = () => {
    setLoading(true);
    getAddressList().then((resp) => {
      setLoading(false);
      if (resp?.data?.status === 200) {
        setAddressList(resp?.data?.data);
      }
    });
  };

  const handleShow = (data) => {
    setFieldValue("addressId", data?.id);
    setSelectedAddress(data);
  };

  const handleCardIcon = (type) => {
    if (type == VISA) {
      return <VisaCardIconColor />;
    } else if (type == MASTERCARD) {
      return <MasterCardIconColor />;
    } else {
      return <></>;
    }
  };

  const totalPriceCalculator = () => {
    if (productDetails?.discounted_price) {
      let price =
        Number(productDetails?.discounted_price) +
        Number(deliveryFess ? deliveryFess : 0) +
        Number(productDetails?.shaxify_fee ? productDetails?.shaxify_fee : 0);

      return price?.toLocaleString("hi");
    } else {
      let price =
        Number(
          productDetails?.total_rental_price
            ? productDetails?.total_rental_price
            : 0
        ) +
        Number(deliveryFess ? deliveryFess : 0) +
        Number(productDetails?.shaxify_fee ? productDetails?.shaxify_fee : 0);

      return price?.toLocaleString("hi");
    }
  };

  const totalRentalPriceCalculator = () => {
    if (productDetails?.discounted_price) {
      let price =
        Number(productDetails?.discounted_price) +
        Number(deliveryFess ? deliveryFess : 0) +
        Number(productDetails?.shaxify_fee ? productDetails?.shaxify_fee : 0);

      return price;
    } else {
      let price =
        Number(
          productDetails?.total_rental_price
            ? productDetails?.total_rental_price
            : 0
        ) +
        Number(deliveryFess ? deliveryFess : 0) +
        Number(productDetails?.shaxify_fee ? productDetails?.shaxify_fee : 0);

      return price;
    }
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        <div className="pt48 pb132">
          <div className="top-head border-0 p-0">
            <Container>
              <div className="row">
                <Col>
                  <h2 className="mb28">Check Out</h2>
                </Col>
              </div>
            </Container>
          </div>
          <Container>
            <Row>
              <Col lg={8} md={8} sm={12}>
                <div className="detail-view checkout">
                  <div className="mb-3">
                    <h5 className="font12 font700 colordark">
                      Shipping Address
                    </h5>
                    <div
                      className="addresdropdown-main addres-checkout"
                      ref={ref}
                    >
                      <Card
                        style={{
                          width: "30rem",
                          padding: "10px",
                          radius: "5px",
                        }}
                        className="d-none d-md-block"
                        onClick={onClickHandler}
                      >
                        <div className="down-icon">
                          <DownDarkIcon />
                        </div>
                        {selectedAddress?.address1 != undefined &&
                          selectedAddress?.address1?.length ? (
                          <p className="mb-0">
                            {`${selectedAddress?.address1 || ""} ${selectedAddress?.address2 || ""
                              }`}
                            <br />
                            {`${selectedAddress?.city || ""} ${selectedAddress?.state || ""
                              }`}
                            <br />
                            {selectedAddress?.country || ""}
                            <br />
                            {selectedAddress?.zipcode || ""}
                          </p>
                        ) : (
                          <p className="mb-0">Select Address</p>
                        )}
                      </Card>
                      {showContent && (
                        <div
                          className="addressdetailbox"
                          style={{
                            width: "30rem",
                            padding: "10px",
                            radius: "5px",
                          }}
                        >
                          <Row>
                            <Col sm={12} className="mx-auto">
                              {addressList &&
                                addressList.length > 0 &&
                                addressList.map((data, index) => {
                                  return (
                                    <Card
                                      className="addresscard shadow-none rounded-0 mb-3 h-auto"
                                      key={index}
                                    >
                                      <div className="d-flex align-items-start gap-3 w-100">
                                        <div className="custom-checkbox">
                                          <Form.Check
                                            type="radio"
                                            name="address"
                                            id={`address-${data?.id}`}
                                            value={data?.id}
                                            checked={
                                              data?.id === selectedAddress?.id
                                            }
                                            onChange={() => {
                                              handleAddressChange(data);
                                            }}
                                          />
                                        </div>
                                        <label htmlFor={`address-${data?.id}`}>
                                          <p className="mb-0">
                                            {`${data?.address1} ${data?.address2}`}
                                            <br />
                                            {`${data?.city} ${data?.state}`}
                                            <br />
                                            {data?.country}
                                            <br />
                                            {data?.zipcode}
                                          </p>
                                        </label>
                                      </div>
                                    </Card>
                                  );
                                })}

                              <div
                                className="text-decoration-none"
                                onClick={() => {
                                  handleshownewadd();
                                }}
                              >
                                <Card className="addresscard addadress shadow-none rounded-0 h-auto d-flex align-items-center justify-content-center">
                                  <p className="mb-0 font14 font700">
                                    Add new address
                                  </p>
                                </Card>
                              </div>
                            </Col>
                          </Row>
                          <Button
                            className="btn btn-theme font700 mb-0 w-100 use-address-desktop"
                            onClick={handleAddressAdd}
                          >
                            Use This Address
                          </Button>
                        </div>
                      )}
                    </div>

                    {/* For mobile screen */}

                    <Card
                      style={{ width: "30rem", padding: "10px", radius: "5px" }}
                      onClick={() =>
                        navigate(
                          `/renter/address-modal?id=${id}&from=${from}&to=${to}&pincode=${pincode}&cod=${isCod}`
                        )
                      }
                      className="d-block d-md-none"
                    >
                      <div className="down-icon">
                        <DownDarkIcon />
                      </div>
                      {selectedAddress?.address1 != undefined ? (
                        <p className="mb-0">
                          {`${selectedAddress?.address1} ${selectedAddress?.address2}`}
                          <br />
                          {`${selectedAddress?.city} ${selectedAddress?.state}`}
                          <br />
                          {selectedAddress?.country}
                          <br />
                          {selectedAddress?.zipcode}
                        </p>
                      ) : (
                        <p className="mb-0">Select Address</p>
                      )}
                    </Card>

                    {/* mobile screen ends */}

                    <span className="text-start required">
                      {errors?.addressId}
                    </span>
                  </div>
                  <div className="mb-3 d-none d-md-block">
                    <h5 className="font12 font700 colordark">
                      Payment Methods
                    </h5>

                    <div
                      className="addresdropdown-main addres-checkout"
                      ref={paymentRef}
                    >
                      <Card
                        style={{
                          width: "30rem",
                          padding: "10px",
                          radius: "5px",
                        }}
                        onClick={onClickPaymentHandler}
                      >
                        <div className="down-icon">
                          <DownDarkIcon />
                        </div>

                        {selectedCard?.id ? (
                          <p className="font400 font16 mb-0">
                            {handleCardIcon(selectedCard?.card_type)}
                            <span className="ms-2">
                              ending with {selectedCard?.ac_no}
                            </span>
                          </p>
                        ) : selectedCard === 0 ? (
                          <p className="mb-0">
                            Pay in cash when item is delivered.
                          </p>
                        ) : (
                          <p className="mb-0"> Select payment option</p>
                        )}
                      </Card>
                      {showPaymentDropdown && (
                        <div
                          className="addressdetailbox"
                          style={{
                            width: "30rem",
                            padding: "10px",
                            radius: "5px",
                          }}
                        >
                          <Row>
                            <Col sm={12} className="mx-auto">
                              {cardList &&
                                cardList.map((data, index) => {
                                  return (
                                    <Card
                                      className="addresscard shadow-none rounded-0 mb-3 h-auto"
                                      key={index}
                                    >
                                      <div className="d-flex align-items-start gap-3 w-100">
                                        <div className="custom-checkbox">
                                          <Form.Check
                                            type="radio"
                                            name="adress"
                                            id={`payment-${data?.id}`}
                                            checked={
                                              data?.id === selectedCard?.id
                                            }
                                            onChange={() => {
                                              handleCardChange(data);
                                              getCheckoutDetails(selectedAddress, 0);
                                            }}
                                          />
                                        </div>
                                        <label htmlFor={`payment-${data?.id}`}>
                                          <div>
                                            <p className="mb-2 font16 font700 colordark">
                                              {data?.card_holder_name}
                                            </p>
                                            <p className="mb-2 font400 colordark">
                                              XXXX-XXXX-XXXX-
                                              {data?.ac_no}
                                            </p>
                                            <p className="mb-0 font400 colordark">
                                              <p className="mb-0 font400 colordark">
                                                Exp.{" "}
                                                {moment(
                                                  data?.exp_month,
                                                  "MM"
                                                ).format("MM")}
                                                /
                                                {moment(
                                                  data?.exp_year,
                                                  "YYYY"
                                                ).format("YY")}
                                              </p>
                                            </p>
                                          </div>
                                        </label>
                                      </div>
                                    </Card>
                                  );
                                })}
                              <Card className="addresscard shadow-none rounded-0 mb-3 h-auto">
                                <div className="d-flex align-items-start gap-3 w-100">
                                  <div className="custom-checkbox">
                                    <Form.Check
                                      type="radio"
                                      name="adress"
                                      id={"payment-0"}
                                      checked={0 === selectedCard}
                                      onChange={() => {
                                        handleCardChange(0);
                                        getCheckoutDetails(selectedAddress, 1);
                                      }}
                                    />
                                  </div>
                                  <label htmlFor={"payment-0"}>
                                    <p className="font400 font16 mb-0">
                                      Pay in cash when item is delivered.
                                    </p>
                                  </label>
                                </div>
                              </Card>
                              <div
                                className="text-decoration-none"
                                onClick={() => {
                                  handleShowNewCard();
                                }}
                              >
                                <Card className="addresscard addadress shadow-none rounded-0 h-auto d-flex align-items-center justify-content-center">
                                  <p className="mb-0 font14 font700">
                                    Add new card
                                  </p>
                                </Card>
                              </div>
                            </Col>
                          </Row>

                          {selectedCard === 0 ? (
                            <Button
                              className="btn btn-theme font700 mb-0 w-100"
                              onClick={handleCardAdd}
                            >
                              Cash
                            </Button>
                          ) : (
                            <Button
                              className="btn btn-theme font700 mb-0 w-100"
                              onClick={handleCardAdd}
                            >
                              Select this card
                            </Button>
                          )}
                        </div>
                      )}
                    </div>

                    <span className="text-start required">
                      {errors?.cardId}
                    </span>
                  </div>

                  {/* For mobile screen */}
                  <div className="mb-3 d-block d-md-none">
                    <Card
                      style={{ width: "20rem", padding: "10px", radius: "5px" }}
                      onClick={() =>
                        navigate(
                          `/renter/payment-modal?id=${id}&from=${from}&to=${to}&pincode=${pincode}`,
                          {
                            state: productDetails,
                          }
                        )
                      }
                      className="d-block d-md-none"
                    >
                      <div className="down-icon">
                        <DownDarkIcon />
                      </div>

                      {selectedCard?.id ? (
                        <p className="font400 font16 mb-0">
                          {handleCardIcon(selectedCard?.card_type)}
                          <span className="ms-2">
                            ending with {selectedCard?.ac_no}
                          </span>
                        </p>
                      ) : selectedCard === 0 ? (
                        <p className="mb-0">
                          Pay in cash when item is delivered.
                        </p>
                      ) : (
                        <p>Select payment option</p>
                      )}
                    </Card>

                    <span className="text-start required">
                      {errors?.cardId}
                    </span>
                  </div>
                  {/* mobile screen ends*/}

                  <h6 className="font16 font700 colordark">
                    Review Rentals and Delivery Options
                  </h6>
                  <Card>
                    <Card.Body>
                      <Row className="align-items-start flex-wrap">
                        <Col lg={4} md={4} sm={12}>
                          <div className="product-img">
                            <img
                              src={
                                productDetails?.data?.product_image &&
                                  productDetails?.data?.product_image?.length !==
                                  0
                                  ? imageBase +
                                  productDetails?.data?.product_image[0]
                                    ?.images
                                  : require("../../../assets/images/static_image.jpg")
                              }
                              alt="image"
                            />
                          </div>
                        </Col>
                        <Col lg={8} md={8} sm={12}>

                          {/* cheapest Option */}
                          {
                            /*
                            {
                              cheapest fee = cheapest_shipping
                              cheapest name = cheapest_shipping_name
                              cheapest courier_id = fast_courier_id
                            }
                            */
                          }
                          <div className="mb-3 mb-lg-0 cheapest">
                            <h6 className="font16 font700 colordark mt-3 mt-lg-0 mb-sm-0 mb12 text-capitalize">
                              {productDetails?.data &&
                                productDetails?.data?.title}
                            </h6>
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <div className="d-flex align-items-center flex-wrap">
                                <div className="custom-checkbox">
                                  <Form.Check
                                    type="radio"
                                    name="fees"
                                    id={10}
                                    checked={
                                      productDetails?.cheapest_shipping ===
                                      deliveryFess
                                    }
                                    onChange={() => {
                                      setDeliveryFees(
                                        productDetails?.cheapest_shipping
                                      );
                                      setFieldValue("deliveryOption", 1);
                                      setFieldValue("renter_delivery_carrier", productDetails?.cheapest_shipping_name || 'Receive at home / Return from home');
                                      setFieldValue(
                                        "courier_id",
                                        productDetails?.fast_courier_id
                                      );
                                    }}
                                  />
                                </div>
                                <label htmlFor={10}>
                                  <div className="arrive-details ps-3">
                                    <h5 className="font16 font400 colordark mb-0">
                                      {productDetails?.cheapest_shipping_name || 'Receive at home / Return from home'}
                                    </h5>
                                    <p className="font16 font700 colordark mb-0 mb-lg-3 ">
                                      <span>
                                        Arrive{" "}
                                        {moment(
                                          productDetails?.from_date
                                        ).format("YYYY-MM-DD")}{" "}
                                      </span>
                                      <span>
                                        Return{" "}
                                        {moment(productDetails?.to_date).format(
                                          "YYYY-MM-DD"
                                        )}{" "}
                                      </span>
                                    </p>
                                  </div>
                                </label>
                              </div>
                              <div className="price">
                                <p className="font16 font700 colordark">
                                  {DEFAULT_CURRENCY}{" "}
                                  {Number(
                                    productDetails?.cheapest_shipping
                                  )?.toLocaleString("hi")}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* Ship Rocket Recommended */}
                          {
                            /*
                            {
                              Ship Rocket Recommended fee = estimated_shipping
                              Ship Rocket Recommended name = shiprocket_recommended_career_name
                              Ship Rocket Recommended courier_id = courier_id
                            }
                            */
                          }
                          <div className="mb-3 mb-lg-0 shiprocket-recommended">
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <div className="d-flex align-items-center flex-wrap">
                                <div className="custom-checkbox">
                                  <Form.Check
                                    type="radio"
                                    name="fees"
                                    id={6}
                                    checked={
                                      productDetails?.estimated_shipping ===
                                      deliveryFess
                                    }
                                    onChange={() => {
                                      setDeliveryFees(
                                        productDetails?.estimated_shipping
                                      );
                                      setFieldValue("deliveryOption", 2);
                                      setFieldValue("renter_delivery_carrier", productDetails?.shiprocket_recommended_career_name || 'Receive in store / Return from store');
                                      setFieldValue(
                                        "courier_id",
                                        productDetails?.courier_id
                                      );
                                    }}
                                  />
                                </div>
                                <label htmlFor={6}>
                                  <div className="arrive-details ps-3">
                                    <h5 className="font16 font400 colordark mb-0">
                                      {productDetails?.shiprocket_recommended_career_name || 'Receive in store / Return from store'}
                                    </h5>
                                    <p className="font16 font700 colordark mb-0 mb-lg-3 ">
                                      <span>
                                        Arrive{" "}
                                        {moment(
                                          productDetails?.from_date
                                        ).format("YYYY-MM-DD")}{" "}
                                      </span>
                                      <span>
                                        Return{" "}
                                        {moment(productDetails?.to_date).format(
                                          "YYYY-MM-DD"
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </label>
                              </div>
                              <div className="price">
                                <p className="font16 font700 colordark">
                                  {DEFAULT_CURRENCY}{" "}
                                  {Number(
                                    productDetails?.estimated_shipping
                                  )?.toLocaleString("hi")}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* In Person */}
                          <div className="mb-3 mb-lg-0 in-person">
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <div className="d-flex align-items-center flex-wrap">
                                <div className="custom-checkbox">
                                  <Form.Check
                                    type="radio"
                                    name="fees"
                                    id="0"
                                    checked={0 === deliveryFess}
                                    onChange={() => {
                                      setDeliveryFees(0);
                                      setFieldValue("deliveryOption", 3);
                                      setFieldValue("renter_delivery_carrier", 'Receive in person / Return in person');
                                      setFieldValue("courier_id", "");
                                    }}
                                  />
                                </div>
                                <label htmlFor="0">
                                  <div className="arrive-details ps-3">
                                    <h5 className="font16 font400 colordark  mb-0">
                                      Receive in person / Return in person
                                    </h5>
                                    <p className="font16 font700 colordark mb-0 mb-lg-3 ">
                                      <span>
                                        Arrive{" "}
                                        {moment(
                                          productDetails?.from_date
                                        ).format("YYYY-MM-DD")}{" "}
                                      </span>
                                      <span>
                                        Return{" "}
                                        {moment(productDetails?.to_date).format(
                                          "YYYY-MM-DD"
                                        )}
                                      </span>
                                    </p>
                                  </div>
                                </label>
                              </div>
                              <div className="price">
                                <p className="font16 font700 colordark">
                                  {DEFAULT_CURRENCY} 0
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <h4 className="font16 font700 colordark">
                                Rental
                              </h4>
                              <p className="font16 font700 colordark total">
                                {DEFAULT_CURRENCY}
                                {productDetails?.total_rental_price
                                  ? `${Number(
                                    productDetails?.total_rental_price
                                  )?.toLocaleString("hi")}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <span className="text-start required">
                    {errors?.deliveryOption}
                  </span>

                </div>
              </Col>
              <Col lg={4} md={4} sm={12}>
                <div className="summary-box">
                  <h6 className="font16 font700 colordark mb-1 mb-lg-4">
                    Summary
                  </h6>
                  <div className="d-flex align-items-center justify-content-between flex wrap">
                    <h5 className="font16 font400 colordark">Rentals</h5>
                    <p className="font16 font400 colordark">
                      {DEFAULT_CURRENCY}
                      {productDetails?.total_rental_price
                        ? `${Number(
                          productDetails?.total_rental_price
                        )?.toLocaleString("hi")}`
                        : ""}

                      {/* {productDetails?.product_detail?.amount?.productDetails?.product_detail?.amount} */}
                    </p>
                  </div>
                  {productDetails?.discount_days && (
                    <div className="d-flex align-items-center justify-content-between flex wrap">
                      <h5 className="font16 font400 colordark">
                        {productDetails?.discount_days} Discount ({productDetails?.discount_applied})
                      </h5>
                      <p className="font16 font400 colordark">
                        {DEFAULT_CURRENCY} { }
                        {((productDetails?.total_rental_price ?? 0) - (productDetails?.discounted_price ?? 0)).toLocaleString("hi")}
                      </p>
                    </div>
                  )}

                  <div className="d-flex align-items-center justify-content-between flex wrap">
                    <h5 className="font16 font400 colordark">Shipping</h5>
                    <p className="font16 font400 colordark">
                      {DEFAULT_CURRENCY}
                      {deliveryFess
                        ? Number(deliveryFess)?.toLocaleString("hi")
                        : 0}
                    </p>
                  </div>

                  <div className="d-flex align-items-center justify-content-between flex wrap">
                    <h5 className="font16 font400 colordark">Shaxify Fee</h5>
                    <p className="font16 font400 colordark">
                      {DEFAULT_CURRENCY}
                      {Number(productDetails?.shaxify_fee)?.toLocaleString(
                        "hi"
                      )}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex wrap">
                    <h5 className="font16 font400 colordark">Total</h5>
                    <p className="font16 font700 colordark">
                      {DEFAULT_CURRENCY}
                      {totalPriceCalculator()}
                    </p>
                  </div>
                  <h6 className="font16 font700 colordark mb-2 mb-lg-3">
                    Shipping Address
                  </h6>
                  {selectedAddress ? (
                    <p className="mb-2">
                      {`${selectedAddress?.address1} ${selectedAddress?.address2}`}
                      <br />
                      {`${selectedAddress?.city} ${selectedAddress?.state}`}
                      <br />
                      {selectedAddress?.country} {selectedAddress?.zipcode}
                      <br />
                    </p>
                  ) : (
                    ""
                  )}
                  <p className="font12 font400 colordark adress">
                    When renting with us you are accepting our{" "}
                    <span
                      className="text-underline colordark cursor"
                      onClick={() => {
                        getPageData(TERMS_AND_CONDITION);
                      }}
                    >
                      <u>conditions of use,</u>{" "}
                    </span>
                    <span
                      className="text-underline colordark cursor"
                      onClick={() => {
                        getPageData(PRIVACY_POLICY);
                      }}
                    >
                      <u>privacy</u>{" "}
                    </span>
                    and{" "}
                    <span
                      className="text-underline colordark cursor"
                      onClick={() => {
                        getPageData(COOKIES);
                      }}
                    >
                      <u>cookies.</u>
                    </span>
                  </p>
                </div>
                <button
                  className="btn btn-theme w-100 mt-2"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Rent Now
                </button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />

      {/* Add Address Modal */}

      <AddressModal
        handleClosenewadd={handleClosenewadd}
        locationList={locationList}
        shownewadd={shownewadd}
        handleShow={handleShow}
      />

      <AddCardMoal
        setLoading={setLoading}
        cardList={AllCardList}
        handleClose={handleCloseNewCard}
        show={showNewCard}
        setShow={setShowNewCard}
      />

      <PagesModal
        pagesModal={pagesModal}
        setPagesModal={setPagesModal}
        pageData={pageData}
        setPageData={setPageData}
      />

      {loading ? <Loader /> : null}
    </>
  );
};

export default Checkout;
