import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  BackIcon,
  ClosedEyeGreyIcon,
  EyeGreyIcon,
} from "../../SvgIcons/allIcons";
import { useFormik } from "formik";
import * as Yup from "yup";
import showNotification from "../../Services/NotificationService";
import { ChangeUserPassword } from "../../Services/APIServices";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { convertUTCToLocal } from "../../Services/helper";
import { useNavigate } from "react-router-dom";
import { isLogout } from "../../Redux/LoginSlice";
import Loader from "../Loader/Loader";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const passwordChangedTime = useSelector(
    (state) => state?.Login?.userDetails?.password_last_updated
  );

  const [showCurrentPassword, setShowCurrentPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState("");
  const validationSchema = Yup.object({
    current_password: Yup.string().required("Current password required").trim(),
    new_password: Yup.string()
      .required("New password required")
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/,
        "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special character."
      )
      .trim(),
    confirm_password: Yup.string()
      .required("Confirm password required")
      .trim()
      .oneOf(
        [Yup.ref("new_password"), null],
        "New passwords and confirm password must match"
      ),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    handleBlur,
    touched,
    isValid,
  } = useFormik({
    initialValues: {
      select_type: 1,
      current_password: "",
      new_password: "",
      confirm_password: "",
      email_type: "",
      text_type: "",
      promotion_type: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);
      let formData = new FormData();
      formData.append("select_type", 1);
      formData.append("current_password", values.current_password);
      formData.append("new_password", values?.new_password);
      setLoading(true);
      ChangeUserPassword(formData).then((resp) => {
        setLoading(false);
        setSubmitting(false);
        if (resp?.status === 200) {
          resetForm(); 
        } else {
          showNotification("danger", resp?.data?.message);
        }
      });
    },
  });

  return (
    <>
      <p className="head16 d-none d-lg-block d-md-block">Password</p>
      <p className="head16 d-flex d-lg-none d-md-none align-items-center gap-3">
        <a href="" className="d-block d-lg-none d-md-none">
          <BackIcon />
        </a>
        Settings
      </p>
      <Row>
        <Col lg={4} md={6}>
          <Form className="change-password">
            <Form.Group className="mb-3 position-relative">
              <Form.Label className="font700 font12 secondaycolor">
                Current Password <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type={showCurrentPassword ? "text" : "password"}
                name="current_password"
                value={values?.current_password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                className="eye-icon"
                onClick={() => setShowCurrentPassword((prev) => !prev)}
              >
                {showCurrentPassword ? <EyeGreyIcon /> : <ClosedEyeGreyIcon />}
              </div>
              {touched?.current_password && errors?.current_password && (
                <span className="text-start required">
                  {errors?.current_password}
                </span>
              )}
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label className="font700 font12 secondaycolor">
                New Password <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type={showNewPassword ? "text" : "password"}
                name="new_password"
                value={values?.new_password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                className="eye-icon"
                onClick={() => setShowNewPassword((prev) => !prev)}
              >
                {showNewPassword ? <EyeGreyIcon /> : <ClosedEyeGreyIcon />}
              </div>
              {touched?.new_password && errors?.new_password && (
                <span className="text-start required">
                  {errors?.new_password}
                </span>
              )}
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label className="font700 font12 secondaycolor">
                Confirm Password <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                name="confirm_password"
                value={values?.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <div
                className="eye-icon"
                onClick={() => setShowConfirmPassword((prev) => !prev)}
              >
                {showConfirmPassword ? <EyeGreyIcon /> : <ClosedEyeGreyIcon />}
              </div>
              {touched?.confirm_password && errors?.confirm_password && (
                <span className="text-start required">
                  {errors?.confirm_password}
                </span>
              )}
            </Form.Group>
            <Button
              className="btn btn-theme font700 w-100 mb-3 d-none d-lg-block d-md-block"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
            >
              Update
            </Button>
            {passwordChangedTime !== null && (
              <p className="last-update font12 font700 text-dark">
                Last updated{" "}
                {moment(convertUTCToLocal(passwordChangedTime)).fromNow()}
              </p>
            )}
          </Form>
        </Col>
      </Row>
      {loading ? <Loader /> : null}
    </>
  );
};

export default ChangePassword;
