import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { isLogin } from "../../Redux/LoginSlice";
import { UpdateUserDetails, UserDetails } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { BackIcon, CalendarIcon, EditIcon } from "../../SvgIcons/allIcons";
import { RENTER } from "../../globals/constants";
import { Selected } from "../../App";
import Loader from "../Loader/Loader";
import { imageBase } from "../../globals/Config";

export default function PersonalInformation() {
  const { setIsSelected } = useContext(Selected);
  const navigate = useNavigate();
  const [profileDetails, setProfileDetails] = useState({});
  const [loading, setLoding] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [previewImg, setPreViewImage] = useState();
  const [isPicked, setisPicked] = useState(false);
  const imageUploader = useRef(null);
  const userRole = useSelector((state) => state?.Login?.userDetails?.role_id);
  const dispatch = useDispatch();

  useEffect(() => {
    profileDetail();
  }, []);

  const validationSchema = Yup.object({
    username: Yup.string()
      .required("This field is required")
      .min(3, "Need at least 3 characters.")
      .max(20, "at most 20 characters allowed.")
      .trim(),
    first_name: Yup.string()
      .required("This field is required")
      .min(3, "Need at least 3 characters.")
      .max(20, "at most 20 characters allowed.")
      .trim(),
    middle_name: Yup.string()
      .trim()
      .min(3, "Need at least 3 characters.")
      .max(20, "at most 20 characters allowed.")
      .nullable()
      .trim(),
    last_name: Yup.string()
      .required("This field is required")
      .min(3, "Need at least 3 characters.")
      .max(20, "at most 20 characters allowed.")
      .trim(),
    dob: Yup.date()
      .required("This field is required")
      .max(
        new Date(Date.now() - 567648000000),
        "You must be at least 18 years"
      ),
  });

  const uploadImages = (event) => {
    const image = event.target.files[0];
    if (!image.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      showNotification(
        "danger",
        "Select a valid image like jpg, jpeg, png ,gif "
      );
      return false;
    }
    setSelectedFile(image);
    setPreViewImage(URL.createObjectURL(event.target.files[0]));
    setisPicked(true);
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      username: profileDetails?.username,
      first_name: profileDetails?.first_name,
      middle_name: profileDetails?.middle_name,
      last_name: profileDetails?.last_name,
      dob: profileDetails?.dob,
      profile_pic: profileDetails?.profile_pic,
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      let body = {
        username: values.username,
        first_name: values.first_name,
        last_name:
          values.last_name && values.last_name != "" ? values.last_name : "",
        middle_name: values.middle_name,
        dob: moment(values.dob).format("YYYY-MM-DD"),
        profile_pic: selectedFile ? selectedFile : values.profile_pic,
      };
      if (!selectedFile || selectedFile === undefined) {
        body.profile_pic = null;
      }
      let formData = new FormData();
      Object.keys(body).forEach((data) => {
        formData.append(data, body[data]);
      });

      UpdateUserDetails(body)
        .then((resp) => {
          setSubmitting(false);
          if (resp?.status === 200) {
            setProfileDetails(resp?.data?.data?.profile_pic);
            setIsSelected(false);
            dispatch(isLogin(resp?.data?.data));            
            profileDetail();
            if (userRole === RENTER) {
              navigate(`/renter/profile?atab=second`);
            } else {
              navigate(`/lender/profile?atab=second`);
            }
          } else {
            showNotification("danger", resp?.data?.message);
          }
        })
        .catch(console.log(errors));
    },
  });

  const profileDetail = () => {
    setLoding(true);
    UserDetails().then((resp) => {
      setLoding(false);
      setProfileDetails(resp?.data?.data);
      setValues({
        ...values,
        username: resp?.data?.data?.username,
        first_name: resp?.data?.data.first_name,
        last_name: resp?.data?.data.last_name,
        middle_name: resp?.data?.data.middle_name,
        dob: resp?.data?.data?.dob,
        profile_pic: resp?.data?.data.profile_pic,
      });
    });
  };

  return (
    <>
      <p className="head16 d-flex align-items-center gap-3 px-4 px-lg-0">
        <Link
          className="d-block d-lg-none d-md-none"
          onClick={(e) => {
            e.preventDefault();
            setIsSelected(false);
          }}
        >
          <BackIcon />
        </Link>
        Personal Information
      </p>
      <Row className="px-4 px-lg-0 personalinfo">
        <Col lg={4} md={6}>
          <Form>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "105px",
                width: "105px",
                overflow: "hidden",
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={uploadImages}
                ref={imageUploader}
                style={{
                  display: "none",
                }}
              />

              <div
                style={{
                  height: "75px",
                  width: "75px",
                  border: "1px solid #eee",
                  borderRadius: "50%",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    height: "24px",
                    width: "24px",
                    borderRadius: "50%",
                    position: "absolute",
                    background: "#16FF96",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    right: "0",
                  }}
                  onClick={() => imageUploader.current.click()}
                >
                  <EditIcon />
                </div>

                {isPicked ? (
                  <img
                    alt="preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    className="avatar"
                    src={previewImg}
                  />
                ) : (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    alt="..."
                    className="avatar"
                    src={
                      values?.profile_pic
                        ? imageBase + values?.profile_pic
                        : require("../../assets/images/DummyImg.png")
                    }
                  />
                )}
              </div>
            </div>

            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Username <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                name="username"
                onChange={handleChange}
                value={values?.username}
                onBlur={handleBlur}
              />
              <span className="text-start required">
                {touched.username && errors.username}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                First Name <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                name="first_name"
                value={values?.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="text-start required">
                {touched.first_name && errors.first_name}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Middle Name
              </Form.Label>
              <Form.Control
                type="text"
                name="middle_name"
                placeholder="Middle Name"
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={15}
                value={values?.middle_name}
              />
              <span className="text-start required">
                {touched.middle_name && errors.middle_name}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Last Name <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="last_name"
                maxLength={15}
                onChange={handleChange}
                value={values?.last_name}
              />
              <span className="text-start required">
                {touched.last_name && errors.last_name}
              </span>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Date of Birth <span className="error">*</span>
              </Form.Label>
              <div className="datepicker position-relative">
                <Form.Control
                  type="date"
                  placeholder="Date of Birth"
                  name="dob"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.dob}
                />
                <span className="position-absolute">
                  <CalendarIcon />
                </span>
                <span className="text-start required">
                  {touched.dob && errors.dob}
                </span>
              </div>
            </Form.Group>
            <Button
              className="btn btn-theme font700"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Form>
        </Col>
      </Row>
      {loading ? <Loader /> : ""}
    </>
  );
}
