import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { CrossIcon } from "../../SvgIcons/allIcons";
import { DeleteAccount } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isLogout } from "../../Redux/LoginSlice";

const AccountDelete = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteAccount = () => {
    DeleteAccount().then((resp) => {
      if (resp?.status === 200) {
        localStorage.clear();
        navigate("/");
        dispatch(isLogout());
      }
    });
  };
  return (
    <>
      <p className="head16 d-none d-lg-block d-md-block">Account</p>
      <Row>
        <Col lg={4} md={6}>
          <Button onClick={handleShow} className="btn btn-danger font700 d-none d-lg-block d-md-block">
            Delete Account
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6} className="mt-4">
        <Button onClick={handleShow} className="btn btn-theme font700 d-block d-lg-none d-md-none w-100">
            Save
          </Button>
          <Button onClick={handleShow} className="btn btn-danger font700 d-block d-lg-none d-md-none w-100">
            Delete Account
          </Button>
        </Col>
      </Row>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered className="smallmodal"
      >
        <div className="middle-area text-start">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Delete Account</h4>
            <a onClick={handleClose}>
              <CrossIcon />
            </a>
          </div>
          <Modal.Body className="p-0">
            <div className="mb96">
              <p className="head12">
                Are you sure you want to delete your account? You’ll loose
                access to your all your rentals.
              </p>
              <p className="head12">
                Account deletion is only possible if you do not have any ongoing
                rentals
              </p>
            </div>
            <div className="row">
              <div className="col-6">
              <Button
                  className="btn btn-theme-outline font700 w-100 height42"
                onClick={handleClose}
              >
                Cancel
              </Button>
              </div>
              <div className="col-6">
              <Button
                  className="btn btn-danger font700 w-100 height42"
                onClick={handleDeleteAccount}
              >
                Delete
                </Button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default AccountDelete;
