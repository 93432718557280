import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./LoginSlice";
import searchReducer from "./searchSlice";
import checkoutReducer from "./CheckoutAddressSlice";
import chatName from "./ChatUserNameSlice";
import cartListSlice from "./cartListSlice";

export const store = configureStore({
  reducer: {
    Login: loginReducer,
    search: searchReducer,
    checkout: checkoutReducer,
    chatName: chatName,
    cartData: cartListSlice,
  },
});
