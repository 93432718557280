import React from "react";
import { Modal } from "react-bootstrap";
import { CrossIcon } from "../../../SvgIcons/allIcons";

const PagesModal = ({
  pagesModal,
  setPagesModal,
  pageData,
  setPageData,
  loader,
}) => {
  return (
    <Modal
      show={pagesModal}
      onHide={() => {
        setPagesModal(false);
        setPageData("");
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-items"
    >
      <div className="middle-area text-start">
        <Modal.Body className="px-0 pb-0 pt-0">
          <div className="d-flex justify-content-end align-items-center mb-2">
            <span onClick={() => setPagesModal(false)}>
              <CrossIcon />
            </span>
          </div>
          {pageData ? (
            <>
              <h4 className="text-start font18 mb-2">{pageData?.title}</h4>
              <span
                dangerouslySetInnerHTML={{
                  __html: pageData?.content,
                }}
              />
            </>
          ) : (
            !loader && <h4 className="text-start font18 mb-2">No Data Found</h4>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default PagesModal;
