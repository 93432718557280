import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Selected } from "../../App";
import { UpdateContactInfo, UserDetails } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { BackIcon } from "../../SvgIcons/allIcons";
import { RENTER } from "../../globals/constants";
import { countryCode } from "../../utils/CountryCode";
import Dropdown from "../Dropdown";
import Loader from "../Loader/Loader";

export default function ContactInformation() {
  const { setIsSelected } = useContext(Selected);
  const navigate = useNavigate();
  const [loading, setLoding] = useState(false);
  const [country, setCountry] = useState("");
  const [profileDetails, setProfileDetails] = useState({});
  const userRole = useSelector((state) => state?.Login?.userDetails?.role_id);

  const phoneRegExp = /^[6-9]\d{9}$/;

  const urlTest = new RegExp(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?/gm
  );
  useEffect(() => {
    profileDetail();
  }, []);

  const validationSchema = Yup.object({
    country_code: Yup.string().required("This field is required."),
    mobile_no: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("This field is required."),
    linkedin: Yup.string().nullable().matches(urlTest, "URL is not valid"),
    facebook: Yup.string().nullable().matches(urlTest, "URL is not valid"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      email: profileDetails?.email,
      mobile_no: profileDetails?.mobile_no,
      linkedin: profileDetails?.linkedin,
      facebook: profileDetails?.facebook,
      country_code: profileDetails?.country_code,
      iso_code: profileDetails?.country_iso,
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      let body = {
        email: values.email,
        mobile_no: values.mobile_no,
        linkedin: values.linkedin,
        facebook: values.facebook,
        country_code: values?.country_code,
        country_iso: values?.iso_code,
      };
      setLoding(true);
      UpdateContactInfo(body)
        .then((resp) => {
          setLoding(false);
          setSubmitting(false);
          if (resp?.status === 200) {
            setProfileDetails(resp?.data?.data?.profile_pic);
            profileDetail();
            setIsSelected(false);
            if (userRole === RENTER) {
              navigate(`/renter/profile?atab=third`);
            } else {
              navigate(`/lender/profile?atab=third`);
            }
          } else {
            showNotification("danger", resp?.data?.message);
          }
        })
        .catch(console.log(errors));
    },
  });

  const profileDetail = () => {
    setLoding(true);
    UserDetails().then((resp) => {
      setLoding(false);
      setProfileDetails(resp?.data?.data);
      setValues({
        ...values,
        email: resp?.data?.data?.email,
        mobile_no: resp?.data?.data?.mobile_no,
        linkedin: resp?.data?.data?.linkedin,
        facebook: resp?.data?.data?.facebook,
        country_code: resp?.data?.data?.country_code,
        iso_code: resp?.data?.data?.country_iso,
      });
      if (resp?.data?.data?.country_code) {
        setCountry({
          label: `${resp?.data?.data?.country_iso} (${resp?.data?.data?.country_code})`,
          value: resp?.data?.data?.country_code,
          iso: resp?.data?.data?.country_iso,
        });
      }
    });
  };

  const countryCodeOptions = () => {
    let country_options = [];
    if (countryCode) {
      countryCode?.map((item) => {
        return country_options.push({
          label: `${item?.iso} (${item?.code})`,
          value: item?.code,
          iso: item?.iso,
        });
      });
      return country_options;
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e);
    setFieldValue("country_code", e?.value);
    setFieldValue("iso_code", e.iso);
  };

  const backAction = () => {
    if (userRole == RENTER) {
      navigate("/renter/profile?atab=first");
    } else {
      navigate("/lender/profile?atab=first");
    }
  };

  return (
    <>
      <p className="head16 d-flex align-items-center gap-3 px-4 px-lg-0">
        <Link
          className="d-block d-lg-none d-md-none"
          onClick={(e) => {
            e.preventDefault();
            setIsSelected(false);
          }}
        >
          <BackIcon />
        </Link>
        Contact Information
      </p>
      <Row className="px-4 px-lg-0 personalinfo">
        <Col xxl={4} xl={6} lg={6} md={7} xs={12}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Email <span className="error">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values?.email}
                onBlur={handleBlur}
              />
              <span className="text-start required">
                {touched.email && errors.email}
              </span>
            </Form.Group>

            <Row>
              <Col xxl={5} xl={6} lg={6} md={6} sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Country Code <span className="error">*</span>
                  </Form.Label>
                  <Dropdown
                    name="country_code"
                    value={country}
                    options={countryCodeOptions()}
                    onChange={handleCountryChange}
                    onBlur={() => setFieldTouched("country_code", true)}
                  />

                  <span className="text-start required">
                    {touched.country_code && errors.country_code}
                  </span>
                </Form.Group>
              </Col>
              <Col xxl={7} xl={6} lg={6} md={6} sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Phone Number <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="mobile_no"
                    maxLength={10}
                    value={values?.mobile_no}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <span className="text-start required">
                    {touched.mobile_no && errors.mobile_no}
                  </span>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                LinkedIn
              </Form.Label>
              <Form.Control
                type="text"
                name="linkedin"
                value={values?.linkedin}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="text-start required">
                {touched.linkedin && errors.linkedin}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="font700 font12 secondaycolor">
                Facebook
              </Form.Label>
              <Form.Control
                type="text"
                name="facebook"
                value={values?.facebook}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="text-start required">
                {touched.facebook && errors.facebook}
              </span>
            </Form.Group>

            <div className="mt-3 d-flex">
              <Button
                className="btn btn-theme font700 mx-1"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                className="btn btn-theme font700 mx-1 d-none d-md-block"
                onClick={() => backAction()}
              >
                Back
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      {loading ? <Loader /> : ""}
    </>
  );
}
