/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import Footer from "../../Commons/Footer";
import Loader from "../../Commons/Loader/Loader";
import {
  addRating,
  getProductDetailsAfterCheckout,
  ratingDetails,
} from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { BackIcon } from "../../SvgIcons/allIcons";
import { LENDER, RENTER } from "../../globals/constants";
import * as Yup from "yup";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Header from "../../Commons/Headers/Header";
import useRole from "../../hooks/useRole";
import { imageBase } from "../../globals/Config";

const AddRatingmobile2 = () => {
  const role = useRole();
  const location = useLocation();
  const locationData = location?.state;
  const rating = location?.state?.rating;
  const userDetail = useSelector((state) => state?.Login?.userDetails);
  const id = new URLSearchParams(location?.search).get("id");
  const pid = new URLSearchParams(location?.search).get("pid");
  const [product, setProduct] = useState("");
  const navigate = useNavigate();
  const [loading, setLoding] = useState(false);
  const [sliderImg, setSliderImg] = useState([]);

  useEffect(() => {
    ProductDetails();
  }, []);

  const ProductDetails = () => {
    getProductDetailsAfterCheckout(id).then((resp) => {
      if (resp?.data?.status === 200) {
        const tempArr = [];
        if (resp?.data?.data) {
          resp?.data?.data?.product_image?.map((data) => {
            if (data?.is_cover_photo) {
              tempArr.unshift({
                original: imageBase + data?.images,
                thumbnail: imageBase + data?.images,
              });
            } else {
              tempArr.push({
                original: imageBase + data?.images,
                thumbnail: imageBase + data?.images,
              });
            }
          });
        }
        setSliderImg(tempArr);
        setProduct(resp?.data?.data);
        if (userDetail?.role_id == RENTER) {
          RatingDetails(id);
        } else {
          RatingDetails(resp?.data?.data?.booked_by?.renter_id);
        }
      }
    });
  };

  const validationSchema = Yup.object({
    rating: Yup.string().required("Rate your overall experience"),
    review: Yup.string().required("This field is required"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    handleBlur,
    setValues,
    touched,
  } = useFormik({
    initialValues: {
      product_id: "",
      user_id: "",
      rating: "",
      item_quality: "",
      communication: "",
      transport: "",
      value: "",
      item_maintained: "",
      punctuality: "",
      review: "",
      message: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      setLoding(true);
      let renterbody = {
        user_id: product?.lender_id,
        product_id: pid,
        rating: values?.rating,
        item_quality: locationData?.item_quality,
        communication: locationData?.communication,
        transport: locationData?.transport,
        review: values?.review,
        value: locationData?.value,
        message: values?.message,
      };

      let lenderbody = {
        user_id: product?.booked_by?.renter_id,
        rating: values?.rating,
        item_maintained: locationData?.item_maintained,
        communication: locationData?.communication,
        punctuality: locationData?.punctuality,
        review: values?.review,
        message: values?.message,
      };

      let formData = new FormData();

      if (userDetail?.role_id == RENTER) {
        Object.keys(renterbody).forEach((data) => {
          if (renterbody[data]) {
            formData.append(data, renterbody[data]);
          }
        });
      } else {
        Object.keys(lenderbody).forEach((data) => {
          if (lenderbody[data]) {
            formData.append(data, lenderbody[data]);
          }
        });
      }
      addRating(formData).then((resp) => {
        setSubmitting(false);
        setLoding(false);

        if (resp?.status === 200) {
          if (userDetail?.role_id == RENTER) {
            navigate("/renter/my-rental");
          } else {
            navigate("/lender/reservation");
          }
        } else {
          showNotification("danger", resp?.data?.message);
          if (userDetail?.role_id == RENTER) {
            navigate("/renter/my-rental");
          } else {
            navigate("/lender/reservation");
          }
        }
      });
    },
  });

  const RatingDetails = (id) => {
    ratingDetails(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setValues({
          ...values,
          rating: resp?.data?.data?.rating,
          item_quality: resp?.data?.data?.item_quality,
          communication: resp?.data?.data?.communication,
          transport: resp?.data?.data?.transport,
          review: resp?.data?.data?.review,
          message: resp?.data?.data?.message,
          value: resp?.data?.data?.value,
          item_maintained: resp?.data?.data?.item_maintained,
          punctuality: resp?.data?.data?.punctuality,
        });
      }
    });
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="category-detailview pb-3 pt-5">
        <Container>
          <Row>
            <Col lg={12}>
              <p className="head16 d-flex align-items-center justify-content-between gap-3">
                <a
                  href="#"
                  className="d-block d-lg-none d-md-none"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <BackIcon />
                </a>
                {/* <a href="#" className="text-black text-underline">
                  Save & Exit
                </a> */}
              </p>

              <div className="ratingdetail-view">
                <Form.Group className="mb-3">
                  <Form.Label className="font700">
                    Describe your experience renting to{" "}
                    {userDetail?.role_id === RENTER
                      ? product?.created_by_name
                      : product?.booked_by?.renter_name}
                    {""}
                    <span className="error">*</span>
                  </Form.Label>
                  <textarea
                    type="textarea"
                    name="review"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.review}
                    maxLength={300}
                    className="form-control min-height140"
                  ></textarea>
                  <span className="text-start required">
                    {errors.review && touched.review && errors.review}{" "}
                  </span>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="font700">
                    Private message to{" "}
                    {userDetail?.role_id === RENTER
                      ? product?.created_by_name
                      : product?.booked_by?.renter_name}{" "}
                  </Form.Label>
                  <textarea
                    type="textarea"
                    name="message"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.message}
                    maxLength={300}
                    className="form-control min-height140"
                  ></textarea>
                </Form.Group>

                {userDetail?.role_id == RENTER ? (
                  <>
                    <div className="d-flex mb-5 ratingcards">
                      <svg
                        width="38"
                        height="40"
                        viewBox="0 0 38 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.6286 27.143C25.7294 27.143 31.4858 21.3867 31.4858 14.2859C31.4858 7.18505 25.7294 1.42871 18.6286 1.42871C11.5278 1.42871 5.77148 7.18505 5.77148 14.2859C5.77148 21.3867 11.5278 27.143 18.6286 27.143Z"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M18.6283 19.9999C21.7843 19.9999 24.3426 17.4415 24.3426 14.2856C24.3426 11.1297 21.7843 8.57129 18.6283 8.57129C15.4724 8.57129 12.9141 11.1297 12.9141 14.2856C12.9141 17.4415 15.4724 19.9999 18.6283 19.9999Z"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.1427 26.9145L13.6284 37.4859C13.5787 37.6868 13.4867 37.8748 13.3586 38.0374C13.2305 38.1999 13.0692 38.3334 12.8856 38.4287C12.6917 38.5129 12.4826 38.5563 12.2713 38.5563C12.0599 38.5563 11.8508 38.5129 11.657 38.4287L2.17127 34.2859C1.9723 34.1918 1.79722 34.0539 1.65918 33.8825C1.52114 33.7111 1.42372 33.5106 1.37423 33.2962C1.32474 33.0817 1.32447 32.8589 1.37344 32.6443C1.42242 32.4297 1.51935 32.2291 1.65698 32.0573L8.99984 22.8573M21.857 26.7716L24.4284 37.4859C24.4795 37.6902 24.5752 37.8807 24.7086 38.0437C24.842 38.2067 25.0097 38.3382 25.1998 38.4287C25.3884 38.5139 25.5929 38.558 25.7998 38.558C26.0068 38.558 26.2113 38.5139 26.3998 38.4287L35.8284 34.2859C36.0304 34.1929 36.2079 34.0541 36.3467 33.8805C36.4856 33.7069 36.5821 33.5033 36.6284 33.2859C36.6826 33.0732 36.6849 32.8506 36.6352 32.6368C36.5855 32.423 36.4852 32.2243 36.3427 32.0573L28.6856 22.3145"
                          stroke="black"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <Form.Group>
                        <div className=" ps-4">
                          <h4 className="font24">
                            Rate your overall experience with{" "}
                            {product?.created_by_name &&
                              `${product?.created_by_name}'s`}{" "}
                            Rental <span className="error">*</span>
                          </h4>
                          <div className="starslist smallsize">
                            <ReactStars
                              className="star_rating"
                              size={24}
                              count={5}
                              half={false}
                              name="rating"
                              onChange={(e) => {
                                setFieldValue("rating", e);
                              }}
                              value={values?.rating}
                              color2={"#16ff96"}
                            />
                          </div>
                          <span className="text-start required">
                            {errors.rating && touched.rating && errors.rating}{" "}
                          </span>
                        </div>
                      </Form.Group>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  className="btn btn-theme height42 width200"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
      {loading ? <Loader /> : null}
    </>
  );
};

export default AddRatingmobile2;
