/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Commons/Loader/Loader";
import { addProductExtraInfo } from "../../../Services/APIServices";
import showNotification from "../../../Services/NotificationService";
import { RightArrownIcon } from "../../../SvgIcons/allIcons";
import { STEP_FIVE, STEP_FOUR, STEP_SEVEN } from "../../../globals/constants";

const ExtraInfo = ({ productDetails }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const rid = new URLSearchParams(location?.search).get("rid");

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    handleBlur,
    touched,
    isValid,
    setValues,
  } = useFormik({
    initialValues: {
      product_id: rid,
      extra_info_to_renter: "",
    },
    onSubmit(values) {
      setLoading(true);
      let formData = new FormData();
      formData.append("product_id", rid);
      if (values.extra_info_to_renter) {
        formData.append("extra_info_to_renter", values.extra_info_to_renter);
      }
      if (productDetails?.steps >= STEP_FOUR) {
        addProductExtraInfo(formData)
          .then((resp) => {
            setLoading(false);
            if (resp?.status === 200) {
              navigate(`/lender/calender?rid=${rid}`);
            }
          })
          .catch((err) => console.log(err));
      } else {
        setLoading(false);
        showNotification(
          "danger",
          "Please fill in all the mandatory fields before creating the listing"
        );
      }
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isValid) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    if (rid && productDetails) {
      setValues({
        ...values,
        extra_info_to_renter: productDetails?.extra_info_to_renter,
      });
    }
  }, [productDetails]);

  const backAction = () => {
    navigate("/lender/new-item?atab=sixth&rid=" + rid);
  };

  return (
    <>
      <p className="head16">
        Extra information for the renter
        <span className="mx-2">
          <RightArrownIcon />
        </span>
      </p>
      <Row>
        <Col lg={6} md={6}>
          <div className="mb-2">
            <p className="font12 font700 colordark mb-1">
              Add any extra information required for the renter{" "}
            </p>

            <Form.Control
              type="text"
              name="extra_info_to_renter"
              value={values?.extra_info_to_renter}
              placeholder="Add anything extra needed by the renter"
              className="extra_info_to_renter"
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
            />
            {touched?.extra_info_to_renter && errors?.extra_info_to_renter && (
              <span className="text-start required">
                {errors?.extra_info_to_renter}
              </span>
            )}
          </div>
        </Col>
        <div className="mt-3">
          {productDetails?.steps >= STEP_SEVEN ? (
            <Button
              type="button"
              className="btn btn-theme mb-0 mx-1"
              onClick={handleSubmit}
            >
              Save
            </Button>
          ) : (
            <Button
              type="button"
              className="btn btn-theme mb-0 mx-1"
              onClick={handleSubmit}
            >
              Create listing
            </Button>
          )}

          <Button
            type="button"
            className="btn btn-theme mb-0 ms-2 mx-1"
            onClick={() => backAction()}
          >
            Back
          </Button>
        </div>
      </Row>
      {loading && <Loader />}
    </>
  );
};

export default ExtraInfo;
