import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import { AddAddress } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { BackIcon } from "../../SvgIcons/allIcons";
import { LENDER, RENTER } from "../../globals/constants";
import useRole from "../../hooks/useRole";

const SendToOther = () => {
  const location = useLocation();
  const role = useRole();
  const navigate = useNavigate();
  const id = new URLSearchParams(location.search).get("id");
  const from = new URLSearchParams(location?.search).get("from");
  const to = new URLSearchParams(location?.search).get("to");
  const pincode = new URLSearchParams(location?.search).get("pincode");

  const validationSchema = Yup.object({
    address1: Yup.string().required("This field is required").trim(),
    state: Yup.string().required("This field is required."),
    city: Yup.string().required("This field is required."),
    country: Yup.string().required("This field is required."),
    zipcode: Yup.string()
      .matches(/^[0-9]{5}/, "Enter a correct ZipCode it should be number")
      .min(6, "Minimum 6 digit required.")
      .max(10, "Maximum 10 digit required.")
      .required("This field is required."),
  });
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      address1: "",
      address2: "",
      state: "",
      city: "",
      country: "",
      zipcode: "",
      latitude: "",
      longitude: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);
      let body = {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.zipcode,
        latitude: values.latitude ? values.latitude : 0,
        longitude: values.longitude ? values.longitude : 0,
      };
      let formData = new FormData();
      Object.keys(body).forEach((data) => {
        formData.append(data, body[data]);
      });
      setLoading(true);
      AddAddress(formData).then((resp) => {
        setSubmitting(true);
        setLoading(false);
        if (resp?.status === 200) {
          resetForm();
          navigate(
            `/renter/address-modal?id=${id}&from=${from}&to=${to}&pincode=${pincode}`
          );
        } else {
          showNotification("danger", resp?.data?.message);
          resetForm();
        }
      });
    },
  });

  const handlePlaces = (place) => {
    place?.address_components?.map((item) => {
      if (item?.types?.includes("country")) {
        setFieldValue("country", item?.long_name);
      }
      if (item?.types?.includes("administrative_area_level_1")) {
        setFieldValue("state", item?.long_name);
      }
      if (item?.types?.includes("administrative_area_level_3")) {
        setFieldValue("city", item?.long_name);
      }
      if (item?.types?.includes("postal_code")) {
        setFieldValue("zipcode", item?.long_name);
      }
    });
    setFieldValue("address1", place?.formatted_address);
    setFieldValue("latitude", place?.geometry?.location?.lat());
    setFieldValue("longitude", place?.geometry?.location?.lng());
  };

  return (
    <>
      {" "}
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="delete-items">
        <div className="middle-area text-start new-address">
          <div className="p-4">
            <Row>
              <p className="head16 d-flex align-items-center gap-3">
                <Link
                  to={`/renter/checkout?id=${id}&from=${from}&to=${to}&pincode=${pincode}`}
                  className="d-block d-md-none"
                >
                  <BackIcon />
                </Link>
                Add new address
              </p>
              <Col sm={12} className="mx-auto">
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label className="font700 font12 secondaycolor">
                      Address 1
                    </Form.Label>
                    <Autocomplete
                      apiKey={process.env.REACT_APP_MAP_KEY}
                      placeholder="Address 1"
                      className="form-control"
                      name="address1"
                      value={values?.address1}
                      options={{
                        types: ["address"],
                        componentRestrictions: { country: "in" },
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onPlaceSelected={(place) => {
                        handlePlaces(place);
                      }}
                    />

                    <span className="text-start required">
                      {touched.address1 && errors.address1}{" "}
                    </span>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="font700 font12 secondaycolor">
                      Address 2{/* <span className="error">*</span> */}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="address2"
                      placeholder="Address 2"
                      onChange={handleChange}
                      value={values?.address2}
                      onBlur={handleBlur}
                    />
                    {/* <span className="text-start required">
                      {touched.address2 && errors.address2}
                    </span> */}
                  </Form.Group>
                  <Row></Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          City <span className="error">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="city"
                          placeholder="City"
                          onChange={handleChange}
                          value={values?.city}
                          onBlur={handleBlur}
                        />
                        <span className="text-start required">
                          {touched.city && errors.city}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          State <span className="error">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="State"
                          name="state"
                          value={values?.state}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <span className="text-start required">
                          {touched.state && errors.state}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          Country <span className="error">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Country"
                          name="country"
                          value={values?.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />

                        <span className="text-start required">
                          {touched.country && errors.country}
                        </span>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          Zip Code <span className="error">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="zipcode"
                          maxLength="10"
                          placeholder="Zip Code"
                          onChange={handleChange}
                          value={values?.zipcode}
                          onBlur={handleBlur}
                        />
                        <span className="text-start required">
                          {touched.zipcode && errors.zipcode}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    className="btn btn-theme font700 mb-0 w-100"
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SendToOther;
