import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Selected } from "../../App";
import PagesModal from "../../Components/Renter/Checkout/PagesModal";
import { isLogin, isLogout } from "../../Redux/LoginSlice";
import {
  ChangeCurrentRole,
  getStaticPages,
  logOut,
} from "../../Services/APIServices";
import { imageBase } from "../../globals/Config";
import { LENDER, RENTER, TERMS_AND_CONDITION } from "../../globals/constants";
import useRole from "../../hooks/useRole";
import Footer from "../Footer";
import Loader from "../Loader/Loader";
import ProfileTabs from "../ProfileTabs";
import Address from "./Address";
import ContactInformation from "./ContactInformation";
import MobileSetting from "./MobileSetting";
import Payments from "./Payments";
import PersonalInformation from "./PersonalInformation";

const MobileProfile = () => {
  const location = useLocation();
  const role = useRole();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pagesModal, setPagesModal] = useState(false);
  const [pageData, setPageData] = useState("");
  const userDetails = useSelector((state) => state?.Login?.userDetails);
  const { isSelected, setIsSelected } = useContext(Selected);
  const [activeTab, setActiveTab] = useState("first");
  const atab = new URLSearchParams(location.search).get("atab");

  useEffect(() => {
    if (atab) {
      setActiveTab(atab);
    }
  }, [atab]);

  const handleRoleChange = (role) => {
    let formData = new FormData();
    formData.append("role_id", role);
    setLoader(true);
    ChangeCurrentRole(formData).then((resp) => {
      setLoader(false);
      if (resp?.data?.status === 200) {
        localStorage.setItem("userDetails", JSON.stringify(resp?.data?.data));
        dispatch(isLogin(resp?.data?.data));
        if (resp?.data?.data?.role_id === RENTER) {
          navigate("/renter/home");
        }
        if (resp?.data?.data?.role_id === LENDER) {
          navigate("/lender/home");
        }
      }
    });
  };

  const logOutHandler = (e) => {
    e.preventDefault();
    logOut().then((resp) => {
      if (resp?.status === 200) {
        localStorage.clear();
        dispatch(isLogout());
        navigate("/");
      }
    });
  };

  const getPageData = (type) => {
    setLoader(true);
    getStaticPages(type).then((resp) => {
      setLoader(false);
      if (resp?.data?.status === 200) {
        setPageData(resp?.data?.data);
        setPagesModal(true);
      }
    });
  };

  return (
    <>
      <ProfileTabs active="profile" />
      <div className="bggrey accountmain mb-0 pb-5">
        <Tab.Container
          id="left-tabs-example"
          activeKey={isSelected ? activeTab : false}
          onSelect={(k) => {
            setActiveTab(k);
            if (role == RENTER) {
              navigate(`/renter/profile?atab=${k}`);
            } else {
              navigate(`/lender/profile?atab=${k}`);
            }
          }}
        >
          <Container fluid>
            <Row>
              {!isSelected && (
                <Col lg={4} md={4} sm={12} className=" pt-4 pb-0">
                  <Row>
                    <Col lg={4} md={3} sm={12}></Col>
                    <Col lg={8} md={9} sm={12}>
                      <Nav
                        variant="pills"
                        className="flex-column sidebarnav mobilesidebar h55vh"
                      >
                        <Nav.Item onClick={() => setIsSelected(true)}>
                          <Nav.Link eventKey="first" className="pt-0">
                            <span className="lft-bar-user icons">
                              <img
                                alt="..."
                                className="avatar"
                                src={
                                  userDetails?.profile_pic
                                    ? imageBase + userDetails?.profile_pic
                                    : require("../../assets/images/DummyImg.png")
                                }
                              />
                            </span>
                            Personal Info
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => setIsSelected(true)}>
                          <Nav.Link eventKey="second">
                            <span className="icons">
                              <img
                                src={require("../../assets/images/user-icon.png")}
                                alt="img"
                              />
                            </span>
                            Contact Info
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => setIsSelected(true)}>
                          <Nav.Link eventKey="third">
                            <span className="icons">
                              <img
                                src={require("../../assets/images/map-icon.png")}
                                alt="img"
                              />
                            </span>
                            Addresses
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => setIsSelected(true)}>
                          <Nav.Link eventKey="fourth">
                            <span className="icons">
                              <img
                                src={require("../../assets/images/payments.png")}
                                alt="img"
                              />
                            </span>
                            Payments
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={() => setIsSelected(true)}>
                          <Nav.Link eventKey="fifth">
                            <span className="icons">
                              <img
                                src={require("../../assets/images/settings.png")}
                                alt="img"
                              />
                            </span>
                            Settings
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      {role == RENTER ? (
                        <span
                          className="btn btn-theme-outline w-100 height52"
                          onClick={() => handleRoleChange(LENDER)}
                        >
                          Switch to lending
                        </span>
                      ) : (
                        <span
                          className="btn btn-theme-outline w-100 height52"
                          onClick={() => handleRoleChange(RENTER)}
                        >
                          Switch to Renting
                        </span>
                      )}

                      <span
                        onClick={(e) => {
                          logOutHandler(e);
                        }}
                        Link
                        className="btn btn-danger text-white w-100 height52"
                      >
                        Log Out
                      </span>

                      <div className="d-flex align-items-center gap-2 mt-3 justify-content-center">
                        <Link
                          to="/how-it-works"
                          className="text-decoration-underline text-dark"
                        >
                          How does it work?
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            getPageData(TERMS_AND_CONDITION);
                          }}
                          className="text-decoration-underline text-dark ms-3"
                        >
                          Terms and Conditions
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
              <Col lg={8} md={8} sm={12} className="mb-5 px-0">
                <Tab.Content>
                  <Tab.Pane eventKey="first" className="pt-5 pb-5">
                    <PersonalInformation />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second" className="pt-5 pb-5">
                    <ContactInformation />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third" className="pt-5 pb-5">
                    <Address />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth" className="pt-5 pb-5">
                    <Payments />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth" className="pt-0 pb-0">
                    <div className="bggrey p-4 pb-5">
                      <MobileSetting isSelected={isSelected} />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Container>
        </Tab.Container>
      </div>
      <Footer />
      <PagesModal
        pagesModal={pagesModal}
        setPagesModal={setPagesModal}
        pageData={pageData}
        setPageData={setPageData}
        loader={loader}
      />
      {loader && <Loader />}
    </>
  );
};

export default MobileProfile;
