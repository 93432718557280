import React, { useState } from "react";
import { Forgetpassword } from "../Services/APIServices";
import { Button, Form } from "react-bootstrap";
import { UserGreyIcon } from "../SvgIcons/allIcons";
import { Link, useNavigate } from "react-router-dom";
import showNotification from "../Services/NotificationService";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../Commons/Loader/Loader";
export default function ForgotPassword({ handleBackButton }) {
  const [loading, setLoding] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email()
      .required("Email is required")
      .max(70, "Maximum lenght is 70 characters.")
      .min(5, "Need at least 5 characters."),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    handleBlur,
    touched,
    isValid,
  } = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);
      let formData = new FormData();
      formData.append("email", values.email);
      setLoding(true);
      Forgetpassword(formData).then((resp) => {
        setLoding(false);
        setSubmitting(false);
        if (resp?.status === 200) {
          localStorage.setItem("token", resp?.data?.token);
          navigate("/");
          resetForm();
        } else {
          showNotification("danger", resp?.data?.data?.message);
        }
      });
    },
  });

  return (
    <div className="middle-area">
      <p>
        Please enter your email address so we can send a login code to you for
        temporary access
      </p>
      <Form>
        <Form.Group
          className="mb-3 position-relative"
          controlId="Form.ControlInput1"
        >
          <div className="user-icon">
            <UserGreyIcon />
          </div>
          <Form.Control
            type="email"
            placeholder="Email"
            className="email"
            name="email"
            onBlur={handleBlur}
            value={values.email}
            onChange={handleChange}
          />
          <span className="text-start required">
            {" "}
            {errors.email && touched.email && errors.email}{" "}
          </span>
        </Form.Group>
      </Form>
      <div className="d-flex align-items-center justify-content-between">
        <Button
          className="btn-theme"
          onClick={handleSubmit}
        >
          Send
        </Button>
        <Link
          className="mb-3 ms-lg-4 ms-md-4 ms-sm-2"
          onClick={handleBackButton}
        >
          Back
        </Link>
      </div>
      {loading ? <Loader /> : null}
    </div>
  );
}
