/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import {
  BackGreenIcon,
  ParkGreenIcon,
  SearchIcon,
} from "../../SvgIcons/allIcons";
// Import Swiper styles
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Footer from "../../Commons/Footer";
import { getStaticPages } from "../../Services/APIServices";
import { HOW_IT_WORKS, LENDER, RENTER } from "../../globals/constants";
import useDocumentTitle from "../../globals/useDocumentTitle";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Header from "../../Commons/Headers/Header";
import useRole from "../../hooks/useRole";

const LenderHome = (props) => {
  useDocumentTitle("SHAXIFY - Lender");
  const role = useRole();
  const [howitWorks, setHowItWorks] = useState("");

  useEffect(() => {
    howItWorks();
  }, []);

  const howItWorks = () => {
    getStaticPages(HOW_IT_WORKS).then((resp) => {
      if (resp?.data?.status === 200) {
        setHowItWorks(resp?.data?.data);
      }
    });
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}

      <div className="itmes-list ptb lender-items">
        <Container>
          <Row className="align-items-center pt-lg-5">
            <Col lg={6} md={6} sm={12}>
              <h4 className="text-white">Want to make extra cash?</h4>
              <h2 className="text-white">
                With a shared economy you are giving each product a new life
              </h2>
              <Link className="btn btn-theme mt-3" to="/lender/listing">
                List your item
              </Link>
            </Col>
          </Row>
          <div className="category-boxes list-boxes mb-3">
            <Row>
              <Col lg={3} md={4} sm={12}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={require("../../assets/images/img1.jpg")}
                  />
                  <Card.Body>
                    <Card.Title>
                      <b> Get your rental delivered to your door steps</b>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} md={4} sm={12}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={require("../../assets/images/img2.jpg")}
                  />
                  <Card.Body>
                    <Card.Title>
                      <b>Secure online payments</b>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} md={4} sm={12}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={require("../../assets/images/img3.jpg")}
                  />
                  <Card.Body>
                    <Card.Title>
                      <b>Insurance coverage is on us</b>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={3} md={4} sm={12}>
                <Card>
                  <Card.Img
                    variant="top"
                    src={require("../../assets/images/img4.jpg")}
                  />
                  <Card.Body>
                    <Card.Title>
                      <b>Support any problems</b>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div className="how-it-work lender-work">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={6} sm={12}>
              <div className="pe-lg-5 pe-0 me-lg-5 me-0">
                <h4 className="font24 text-start">How it works</h4>
                <h2>{howitWorks?.title}</h2>
                <span
                  dangerouslySetInnerHTML={{
                    __html: howitWorks?.content,
                  }}
                />
                <a href="/sustainbility" className="btn btn-theme">
                  Learn More
                </a>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <img
                src={require("../../assets/images/how-it-work-img.png")}
                alt="img"
              />
            </Col>
          </Row>

          <div className="boxes">
            <Row>
              <Col lg={4}>
                <span className="search">
                  <SearchIcon />
                </span>
                <h4>
                  Instead of selling, list <br />
                  your item for rent
                </h4>
              </Col>
              <Col lg={4}>
                <span>
                  <ParkGreenIcon />
                </span>
                <h4>
                  Get it picked up from <br /> your home
                </h4>
              </Col>
              <Col lg={4}>
                <span>
                  <BackGreenIcon />
                </span>
                <h4>Safely returned to you</h4>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default LenderHome;
