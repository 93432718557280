/*
@copyright : Ozvid Technologies Pvt. Ltd. < www.ozvid.com >
@author     : Shiv Charan Panjeta < shiv@ozvid.com >
 
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of Ozvid Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import * as req from "../utils/http";

export const PostReq = async (path, body) => {
  /**request for post method */
  return await req.http
    .post(path, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getReq = async (path) => {
  /**request for get method */
  return await req.http
    .get(path)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const putReq = async (path, body) => {
  /**request for put method */

  return await req.http
    .put(path, body)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const delReq = async (path, body) => {
  /**request for delete method */
  return await req.http
    .delete(path, body)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      return err;
    });
};

//post request formultipart/form-data
export const POSTReq = async (path, body) => {
  return await req.http_multipart
    .post(path, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//GET_Req request for multipart/form-data
export const GETReq = async (path) => {
  return await req.http_multipart
    .get(path)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

//PUTReq request formultipart/form-data
export const PUTReq = async (path, body) => {
  return await req.http_multipart
    .put(path, body)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

//DELReq request formultipart/form-data
export const DELReq = async (path, body) => {
  return await req.http_multipart
    .delete(path, body)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      return err;
    });
};
