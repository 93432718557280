import React from "react";

export default function Nodata() {
  return (
    <div className="nodata text-center">
      <img src={require("../../assets/images/nodata.png")} alt="img" />
      <h4> No Data Found.</h4>
    </div>
  );
}
