import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  ListWhiteIcon,
  WhiteCalendarIcon,
  WhiteMsgIcon,
  WhiteProfileIcon,
  WhiteReservationIcon,
} from "../../SvgIcons/allIcons";
import { Selected } from "../../App";

const MobileLenderFooter = () => {
  const location = useLocation();
  const { pathname } = location;
  const { setIsSelected } = useContext(Selected);

  return (
    <div className="mobile-footer d-none">
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <Link
            className={
              pathname == "/lender/listing" || pathname == "/lender/new-item"
                ? "text-center active"
                : "text-center"
            }
            to={"/lender/listing"}
          >
            <ListWhiteIcon />
            <p className="font400 font8 mb-0">My Listings</p>
          </Link>
          <Link
            className={
              pathname == "/lender/reservation"
                ? "text-center active"
                : "text-center"
            }
            to={"/lender/reservation"}
          >
            <WhiteReservationIcon />
            <p className="font400 font8 mb-0">Reservations</p>
          </Link>
          <Link
            className={
              pathname == "/lender/calender"
                ? "text-center active"
                : "text-center"
            }
            to={"/lender/calender"}
          >
            <WhiteCalendarIcon />
            <p className="font400 font8 mb-0">Calendar</p>
          </Link>
          <Link
            className={
              pathname == "/message" || pathname == "/lender/message"
                ? "text-center active"
                : "text-center"
            }
            to={"/message"}
          >
            <WhiteMsgIcon />
            <p className="font400 font8 mb-0">Messages</p>
          </Link>
          <Link
            to="/lender/profile"
            className={
              pathname == "/lender/profile"
                ? "text-center active"
                : "text-center"
            }
            onClick={() => {
              setIsSelected(false);
            }}
          >
            <WhiteProfileIcon />
            <p className="font400 font8 mb-0">My Profile</p>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default MobileLenderFooter;
