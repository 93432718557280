/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addProductImages,
  deleteProductImage,
  dragDropImage,
  editProductImages,
} from "../../../Services/APIServices";
import showNotification from "../../../Services/NotificationService";
import {
  CrossIcon,
  DeletecircleIcon,
  EditcircleIcon,
  RightArrownIcon,
} from "../../../SvgIcons/allIcons";
import { imageValidator } from "../../../utils/CommonFunction";
import { STEP_ONE } from "../../../globals/constants";
import Loader from "../../../Commons/Loader/Loader";
import { imageBase } from "../../../globals/Config";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Photos = ({ productDetails, ProductDetails }) => {
  const location = useLocation();
  const [photos, setPhotos] = useState([]);
  const [editId, setEditId] = useState("");
  const [editImage, setEditImage] = useState("");
  const [deleteData, setDeleteImage] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleshowdelete = (data) => {
    setshowdelete(true);
    setDeleteImage(data);
  };
  const [showdelete, setshowdelete] = useState(false);
  const handleCloseDel = () => setshowdelete(false);
  const [showTwo, setshowTwo] = useState(false);
  const handleshowTwo = () => setshowTwo(true);
  const handleCloseTwo = () => setshowTwo(false);
  const rid = new URLSearchParams(location?.search)?.get("rid");
  const [loading, setLoding] = useState(false);

  const navigate = useNavigate();

  const {
    handleChange,
    values,
    handleBlur,
    resetForm,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      file: "",
      coverPhoto: false,
      caption: "",
    },
    onSubmit(values, { resetForm }) {
      setLoding(true);
      handleClose();
      if (productDetails?.steps >= STEP_ONE) {
        if (editId) {
          let body = {
            product_image_id: editId,
            image: values?.file,
            caption: values?.caption,
            is_cover_photo: values?.coverPhoto ? "1" : "0",
          };
          let formData = new FormData();

          Object.keys(body).forEach((i) => {
            formData.append(i, body[i]);
          });
          editProductImages(formData).then((resp) => {
            setLoding(false);
            if (resp?.data?.status === 200) {
              ProductDetails(rid);
              resetForm();
              setEditId("");
            }
          });
        } else {
          let body = {
            product_id: rid,
            product_image: values?.file,
            caption: values?.caption,
            is_cover_photo: values?.coverPhoto ? "1" : "0",
          };

          let formData = new FormData();

          Object.keys(body).forEach((i) => {
            formData.append(i, body[i]);
          });

          addProductImages(formData).then((resp) => {
            setLoding(false);
            if (resp?.data?.status === 200) {
              ProductDetails(rid);
              resetForm();
            }
          });
        }
      } else {
        setLoding(false);
        showNotification("danger", "Please complete previous steps to proceed");
      }
    },
  });

  useEffect(() => {
    if (rid && productDetails) {
      setPhotos(productDetails?.product_image);
    }
  }, [productDetails]);

  const imageHandler = (e) => {
    if (!imageValidator(e.target.files[0])) {
      return false;
    }
    setFieldValue("file", e.target.files[0]);
    handleShow();
  };

  const handleClose = () => {
    setShow(false);
    resetForm();
  };

  const handleDeleteImage = () => {
    let formData = new FormData();
    formData.append("product_image_id", deleteData?.id);
    deleteProductImage(formData).then((resp) => {
      if (resp?.data?.status === 200) {
        ProductDetails(rid);
        handleshowTwo();
        handleCloseDel();
      }
    });
  };

  const handleEditModal = (data) => {
    handleShow();
    setValues({
      ...values,
      coverPhoto: data?.is_cover_photo,
      caption: data?.caption,
    });
    setEditId(data?.id);
    setEditImage(data?.images);
  };

  const recordSave = () => {
    if (productDetails?.steps >= STEP_ONE) {
      if (photos.length < 1) {
        showNotification("danger", "Add at least one photo to proceed.");
      } else {
        navigate("/lender/new-item?atab=third&rid=" + rid);
      }
    } else {
      showNotification("danger", "Please complete previous steps to proceed");
    }
  };

  const backAction = () => {
    navigate("/lender/new-item?atab=first&rid=" + rid);
  };

  // drag and drop functionality
  const handleDrop = (event) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    if (!imageValidator(files[0])) {
      return false;
    }
    setFieldValue("file", files[0]);
    handleShow();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/plain", event.target.id);
  };

  const handleDragEnd = async (result) => {
    if (
      !result.destination ||
      result?.source?.index == result?.destination?.index
    )
      return;

    setLoding(true);
    const items = Array.from(photos);
    const [reorderedItems] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItems);
    setPhotos(items);
    try {
      let body = {
        source: result?.source?.index + 1,
        destination: result?.destination?.index + 1,
        product_id: rid,
      };

      let resp = await dragDropImage(body);
      setLoding(false);
      if (resp?.data?.status == 200) {
        ProductDetails(rid);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <p className="head16">
        Item Details
        <span className="mx-2">
          <RightArrownIcon />
        </span>
        Photos
      </p>
      <p>
        <small>
          <i>&#9432; Drag and Drop Photos to change their order.</i>
        </small>
      </p>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          droppableId="photos_list"
          direction={window.innerWidth < 768 ? "vertical" : "horizontal"}
        >
          {(provided) => {
            return (
              <>
                <Row ref={provided.innerRef} {...provided.droppableProps}>
                  {photos && photos?.length > 0
                    ? photos?.map((data, index) => {
                        return (
                          <Draggable
                            key={data?.id}
                            draggableId={String(data?.id)}
                            index={index}
                          >
                            {(provided) => {
                              return (
                                <Col
                                  lg={4}
                                  md={6}
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Card>
                                    <div className="d-flex butns">
                                      <label
                                        htmlFor="editFile"
                                        onClick={() => handleEditModal(data)}
                                      >
                                        <EditcircleIcon />
                                      </label>

                                      <label
                                        onClick={() => handleshowdelete(data)}
                                      >
                                        <DeletecircleIcon />
                                      </label>
                                    </div>
                                    <Card.Img
                                      variant="top"
                                      src={
                                        data?.images && imageBase + data?.images
                                      }
                                      className="product_img"
                                    />
                                    <Card.Body>
                                      {data?.is_cover_photo ? (
                                        <Card.Text>Cover Photo</Card.Text>
                                      ) : (
                                        <Card.Text>{data?.caption}</Card.Text>
                                      )}
                                    </Card.Body>
                                  </Card>
                                </Col>
                              );
                            }}
                          </Draggable>
                        );
                      })
                    : provided.placeholder}

                  <Col lg={4} md={6}>
                    <label htmlFor="myfile" className="d-block">
                      <div
                        className="custom-upload-file"
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}
                      >
                        <div onDragStart={handleDragStart}>
                          <label htmlFor="myfile">
                            Choose a file or drag it here{" "}
                          </label>
                          <input
                            type="file"
                            id="myfile"
                            name="myfile"
                            accept="image/*"
                            onChange={(e) => {
                              imageHandler(e);
                            }}
                            onClick={(e) => {
                              e.target.value = null;
                            }}
                          />
                        </div>
                      </div>
                    </label>
                  </Col>
                  <div className="mt-3">
                    <span
                      className="btn btn-theme mb-0 mx-1"
                      onClick={() => recordSave()}
                    >
                      Save
                    </span>
                    <span
                      className="btn btn-theme mb-0 mx-1"
                      onClick={() => backAction()}
                    >
                      Back
                    </span>
                  </div>
                  {loading && <Loader />}
                </Row>
              </>
            );
          }}
        </Droppable>
      </DragDropContext>

      {/* Upload Pic Modal */}

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-items"
      >
        <div className="middle-area text-start">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">{editId ? "Edit" : "Add new"} photo</h4>
            <span onClick={handleClose}>
              <CrossIcon />
            </span>
          </div>
          <Modal.Body className="px-0 pb-0">
            <Row>
              <Col sm={12} className="mx-auto">
                <div className="product-img">
                  {editId ? (
                    <input
                      type="file"
                      id="editfile"
                      name="myfile"
                      accept="image/*"
                      hidden
                      onChange={(e) => {
                        imageHandler(e);
                      }}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <label htmlFor="editfile">
                    <img
                      src={
                        values?.file
                          ? URL.createObjectURL(values?.file)
                          : imageBase + editImage
                      }
                      alt="img"
                      className="h-auto"
                    />
                  </label>
                </div>
                <div className="d-flex align-items-center gap-1 my-3">
                  <div className="custom-checkbox mb-0">
                    <Form.Check
                      type="checkbox"
                      name="coverPhoto"
                      value={values?.coverPhoto}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      checked={values?.coverPhoto}
                    />
                  </div>
                  <p className="mb-2 font12 font700 colordark">
                    Set as cover photo
                  </p>
                </div>

                <div>
                  <p className="font12 font700 colordark mb-1">Caption</p>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    className="h-auto px-2 "
                    name="caption"
                    value={values?.caption}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className="text-start">
                  <Button
                    className="btn btn-theme mt-3 mb-1"
                    type="button"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    {editId ? "Update" : "Save"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>

      {/* Delete Pic Modal */}

      <Modal
        show={showdelete}
        onHide={handleCloseDel}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-items"
      >
        <div className="middle-area text-start">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Delete photo</h4>
            <span onClick={handleCloseDel}>
              <CrossIcon />
            </span>
          </div>
          <Modal.Body className="px-0 pb-0">
            <div className="single-item">
              <p className="font14 font700 colodark text-center">
                Do you want remove following photo
              </p>
            </div>
            <Row>
              <Col sm={12} className="mx-auto">
                <div className="product-img">
                  <img
                    src={deleteData?.images && imageBase + deleteData?.images}
                    alt="img"
                    className="h-auto"
                  />
                </div>

                <div className="text-start">
                  <button
                    onClick={handleDeleteImage}
                    className="btn btn-danger mt-3 mb-1"
                  >
                    Confirm
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>

      {/* Delete Dismiss Modal */}

      <Modal
        show={showTwo}
        onHide={handleCloseTwo}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-items"
      >
        <div className="middle-area text-start">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">Photo deleted!</h4>
            <span onClick={handleCloseTwo}>
              <CrossIcon />
            </span>
          </div>
          <Modal.Body className="px-0 pb-0">
            <div className="single-item">
              <p className="font14 font700 colodark text-center">
                Following photo has been deleted.
              </p>
            </div>
            <Row>
              <Col sm={12} className="mx-auto">
                <div className="product-img">
                  <img
                    src={deleteData?.images && imageBase + deleteData?.images}
                    alt="img"
                    className="h-auto"
                  />
                </div>

                <div className="text-start">
                  <button
                    className="btn btn-theme mt-3 mb-1"
                    onClick={handleCloseTwo}
                  >
                    Dismiss
                  </button>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default Photos;
