import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import RenterProfileTabs from "../ProfileTabs";
import ChangePassword from "./ChangePassword";
import Notification from "./Notification";
import AccountDelete from "./AccountDelete";
import Footer from "../Footer";
import MobileProfile from "../MyProfile/MobileProfile";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { LENDER, RENTER } from "../../globals/constants";
import LenderHeader from "../Headers/LenderHeader";
import RenterHeader from "../Headers/RenterHeader";
import Header from "../Headers/Header";
import useRole from "../../hooks/useRole";

const Settings = () => {
  const location = useLocation();
  const role = useRole();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("first");
  const atab = new URLSearchParams(location?.search).get("atab");
  const userRole = useSelector((state) => state?.Login?.userDetails?.role_id);

  useEffect(() => {
    if (atab) {
      setActiveTab(atab);
    }
  }, [atab]);

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="d-none d-md-block">
        <div className="mainbox_outer">
          <RenterProfileTabs active="settings" />
          <div className="accountmain">
            <Tab.Container
              id="left-tabs-example"
              activeKey={activeTab}
              onSelect={(k) => {
                setActiveTab(k);
                if (userRole == RENTER) {
                  navigate(`/renter/settings?atab=${k}`);
                } else {
                  navigate(`/lender/settings?atab=${k}`);
                }
              }}
            >
              <Container fluid>
                <Row>
                  <Col
                    lg={4}
                    md={4}
                    sm={12}
                    className="bggrey pt-5 pb-5 vh-100"
                  >
                    <Row>
                      <Col lg={4} md={3} sm={12}></Col>
                      <Col lg={8} md={9} sm={12}>
                        <Nav variant="pills" className="flex-column sidebarnav">
                          <Nav.Item>
                            <Nav.Link eventKey="first" className="pt-0">
                              Password
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Notifications</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">Account</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={8} md={8} sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first" className="pt-5 pb-5">
                        <ChangePassword />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second" className="pt-5 pb-5">
                        <Notification activeTab={activeTab} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third" className="pt-5 pb-5">
                        <AccountDelete />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Container>
            </Tab.Container>
          </div>
        </div>
        <Footer />
      </div>
      <div className="d-block d-md-none">
        <MobileProfile />
      </div>
    </>
  );
};

export default Settings;
