/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactStars from "react-stars";
import Nodata from "../../Commons/CommonModals/Nodata";
import Dropdown from "../../Commons/Dropdown";
import Footer from "../../Commons/Footer";
import ProfileTabs from "../../Commons/ProfileTabs";
import Paginate from "../../Pagination/Pagination";
import { LanderListing } from "../../Services/APIServices";
import {
  BtnRightIcon,
  CrossDarkIcon,
  SearchGrayIcon,
} from "../../SvgIcons/allIcons";
import { truncate } from "../../globals/HelperCommon";
import {
  ACTIVE_STATE,
  ALL_STATE,
  DEFAULT_CURRENCY,
  DRAFT_STATE,
  INACTIVE_STATE,
  LENDER,
  PERPAGE_TEN,
  PERPAGE_THIRTY,
  PERPAGE_TWENTY,
  RENTER,
} from "../../globals/constants";
import LenderMobileListing from "./LenderMobileListing";
import useRole from "../../hooks/useRole";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Header from "../../Commons/Headers/Header";
import Loader from "../../Commons/Loader/Loader";
import { imageBase } from "../../globals/Config";

const LenderListing = () => {
  const role = useRole();
  const [list, setList] = useState([]);
  const [meta, setMeta] = useState("");
  const [search, setSearch] = useState("");
  const [state, setState] = useState({ label: "Active", value: ACTIVE_STATE });
  const [page, setPage] = useState("1");
  const [perPage, setPerPage] = useState(PERPAGE_TEN);
  const searchText = useSelector((state) => state?.search?.search);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    productListing(searchText, state?.value, page, perPage);
  }, [searchText]);

  const productListing = (
    search = "",
    state = ACTIVE_STATE,
    page,
    perPage = PERPAGE_TEN
  ) => {
    LanderListing(search, state, page, perPage)
      .then((resp) => {
        setLoading(false);
        if (resp?.data?.status === 200) {
          setList(resp?.data?.data);
          setMeta(resp?.data?.meta);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    productListing(e.target.value, state?.value, page, perPage);
  };

  const handleStateChange = (e) => {
    setState(e);
    productListing(search, e?.value, page, perPage);
  };

  const handlePageChange = (e) => {
    setPage(e.selected + 1);
    productListing(search, state?.value, e.selected + 1, perPage);
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    productListing(search, state?.value, page, e.target.value);
  };

  const removeSearch = () => {
    productListing("", state?.value, page, perPage);
    setSearch("");
  };

  return (
    <>
      <div className="mobile_module">
        <div className="mobile-header">
          {role == LENDER ? (
            <LenderHeader />
          ) : role == RENTER ? (
            <RenterHeader />
          ) : (
            <Header />
          )}
        </div>

        <div className="d-none d-md-block">
          <div className="mainbox_outer">
            <ProfileTabs active="listing" />
            <div className="top-breadcrumb top-area">
              <Container>
                <Row className="align-items-center">
                  <Col lg={8} md={12}>
                    <Row>
                      <Col lg={4} md={3} sm={12}>
                        <Form className="search-form ">
                          <Form.Label>Search</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            className="Search"
                            aria-label="Search"
                            value={search}
                            onChange={handleSearch}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                              }
                            }}
                          />
                          <span className="searc-icon">
                            <SearchGrayIcon />
                          </span>
                          {search.length ? (
                            <span className="cross" onClick={removeSearch}>
                              <CrossDarkIcon />
                            </span>
                          ) : (
                            ""
                          )}
                        </Form>
                      </Col>
                      <Col lg={2} md={2} sm={12} className="d-lg-block d-none">
                        <Form.Label>Status</Form.Label>
                        <Dropdown
                          name="my_rental"
                          value={state}
                          clearable={false}
                          options={[
                            { label: "All", value: ALL_STATE },
                            { label: "Active", value: ACTIVE_STATE },
                            { label: "Inactive", value: INACTIVE_STATE },
                            { label: "Draft", value: DRAFT_STATE },
                          ]}
                          placeholder={"Select"}
                          onChange={(e) => handleStateChange(e)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={4} md={12} sm={12} className="text-end">
                    <Link
                      className="btn btn-theme mb-0  w-50 d-lg-block d-none float-end"
                      to="/lender/new-item"
                    >
                      Create new listing
                    </Link>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="accountmain new-listing  mb-5 mt-4">
              <Container>
                <Row>
                  {list && list.length > 0 ? (
                    list?.map((data, index) => {
                      return (
                        <Col lg={3} md={4} sm={12} key={index}>
                          <Link
                            to={`/lender/new-item?atab=first&rid=${data?.id}`}
                          >
                            <Card className="mb-4">
                              <Card.Img
                                variant="top"
                                className="product-img"
                                src={
                                  data?.product_image?.images
                                    ? imageBase + data?.product_image?.images
                                    : require("../../assets/images/static_image.jpg")
                                }
                              />
                              <Card.Body>
                                <div className="font12 font700 mb-2 d-flex align-items-center gap-3 flex-wrap">
                                  <p className="font700 font14  d-flex align-items-center mb-0 text-capitalize">
                                    <i>
                                      {truncate(
                                        data?.created_by?.full_name,
                                        12
                                      )}{" "}
                                      ({data?.rental_count})
                                    </i>
                                  </p>

                                  <span className="stars-card">
                                    <ReactStars
                                      value={data?.average_rating}
                                      count={5}
                                      size={24}
                                      color2={"#16ff96"}
                                      edit={false}
                                    />
                                  </span>
                                </div>

                                <h5 className="head16">{data?.title}</h5>
                                <p className="font700 font12 mb-0">
                                  Today Price :
                                  {data?.daily_price
                                    ? ` ${DEFAULT_CURRENCY} 
                                    ${Number(data?.daily_price).toLocaleString(
                                      "hi"
                                    )}
                                    `
                                    : ""}
                                </p>
                              </Card.Body>
                            </Card>
                          </Link>
                        </Col>
                      );
                    })
                  ) : (
                    <>{!loading && <Nodata />}</>
                  )}
                </Row>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <Paginate
                    page={meta?.page_count}
                    handlePageClick={(e) => handlePageChange(e)}
                    cp={page}
                  />
                  <div className="d-flex align-items-center justify-content-end flex-wrap gap-2 mb-3">
                    <h6 className="font12 font400 colordark mb-0">Show</h6>
                    <div style={{ width: "70px" }}>
                      <Form.Select
                        aria-label="Default select"
                        className="font14 font400"
                        value={perPage}
                        onChange={handlePerPageChange}
                      >
                        <option value={PERPAGE_TEN}>10</option>
                        <option value={PERPAGE_TWENTY}>20</option>
                        <option value={PERPAGE_THIRTY}>30</option>
                      </Form.Select>
                    </div>
                    <h5 className="font12 font400 colordark mb-0">per page</h5>
                  </div>
                </div>
              </Container>
            </div>
          </div>
          <Footer />
          {loading && <Loader />}
        </div>
        <div className="inner_box">
          <div className="d-block d-md-none">
            <LenderMobileListing />
          </div>
        </div>
      </div>
    </>
  );
};

export default LenderListing;
