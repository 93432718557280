/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import React, { useEffect, useState } from "react";
import {
  Accordion,
  Col,
  Container,
  Nav,
  Pagination,
  Row,
  Tab,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../../Commons/Footer";
import ProfileTabs from "../../../Commons/ProfileTabs";
import { LanderProductDetails } from "../../../Services/APIServices";
import { BackIcon } from "../../../SvgIcons/allIcons";
import Discounts from "./Discounts";
import ExtraInfo from "./ExtraInfo";
import Fees from "./Fees";
import ListingBasic from "./ListingBasic";
import Location from "./Location";
import Photos from "./Photos";
import Pricing from "./Pricing";
import { LENDER, RENTER } from "../../../globals/constants";
import LenderHeader from "../../../Commons/Headers/LenderHeader";
import RenterHeader from "../../../Commons/Headers/RenterHeader";
import Header from "../../../Commons/Headers/Header";
import useRole from "../../../hooks/useRole";

const CreateNewListing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = useRole();
  const [activeTab, setActiveTab] = useState("first");
  const [activeNav, setActiveNav] = useState("0");
  const [isSelected, setIsSelected] = useState(false);
  const [bottomcontent, setbottomcontent] = useState(false);
  const [productDetails, setProductDetails] = useState("");
  const [recordId, setRecordId] = useState("");
  const rid = new URLSearchParams(location?.search).get("rid");
  const atab = new URLSearchParams(location?.search).get("atab");

  let active = 2;
  let items = [];

  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  useEffect(() => {
    // get active tab value from url
    if (atab) {
      setActiveTab(atab);
      if (atab === "fourth" || atab === "fifth" || atab === "sixth") {
        setActiveNav("1");
      }
      if (atab === "seventh") {
        setActiveNav("");
      }
    }

    // get recordId
    if (rid) {
      setRecordId(rid);
      ProductDetails(rid);
    }
  }, [atab]);

  const ProductDetails = (id) => {
    LanderProductDetails(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setProductDetails(resp?.data?.data);
      }
    });
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      {/**************************************************Desktop*********************************************/}
      <div className="d-none d-md-block">
        <div className="mainbox_outer">
          <ProfileTabs active="listing" />
          <div className="accountmain new-listing">
            <Tab.Container
              id="left-tabs-example"
              activeKey={activeTab}
              onSelect={(k) => {
                setActiveTab(k);
                if (rid) {
                  navigate(`/lender/new-item?atab=${k}&rid=${rid}`);
                }
              }}
            >
              <Container fluid>
                <Row>
                  <Col lg={4} md={4} sm={12} className="bggrey pt-5 pb-5 ">
                    <Row>
                      <Col lg={4} md={3} sm={12}></Col>
                      <Col lg={8} md={9} sm={12}>
                        {/* <h4>
                          Delta Children <br /> Jordan 4-in-1 <br /> Convertible
                          Crib
                        </h4> */}
                        <Accordion
                          activeKey={activeNav}
                          alwaysOpen
                          onSelect={(k) => {
                            setActiveNav(k);
                          }}
                        >
                          <Accordion.Item eventKey="0">
                            <h3>{productDetails?.title}</h3>
                            <Accordion.Header>Item Details</Accordion.Header>
                            <Accordion.Body>
                              <Nav variant="pills" className="flex-row">
                                <Nav.Item>
                                  <Nav.Link eventKey="first">
                                    Listing Basics
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="second">Photos</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="third">Location</Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              Pricing & Availability
                            </Accordion.Header>
                            <Accordion.Body>
                              <Nav variant="pills" className="flex-row">
                                <Nav.Item>
                                  <Nav.Link eventKey="fourth">Pricing</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="fifth">
                                    Discounts
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="sixth"> Fees</Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Nav variant="pills" className="flex-row nav_tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="seventh">
                              Info for renter
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={8} md={8} sm={12}>
                    <Row>
                      <Col lg={10} md={10} sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first" className="pt-5 pb-5">
                            <ListingBasic productDetails={productDetails} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="second" className="pt-5 pb-5">
                            <Photos
                              productDetails={productDetails}
                              ProductDetails={ProductDetails}
                            />
                          </Tab.Pane>
                          <Tab.Pane eventKey="third" className="pt-5 pb-5">
                            <Location productDetails={productDetails} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth" className="pt-5 pb-5">
                            <Pricing productDetails={productDetails} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="fifth" className="pt-5 pb-5">
                            <Discounts productDetails={productDetails} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="sixth" className="pt-5 pb-5">
                            <Fees productDetails={productDetails} />
                          </Tab.Pane>
                          <Tab.Pane eventKey="seventh" className="pt-5 pb-5">
                            <ExtraInfo productDetails={productDetails} />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                      <Col lg={2} md={2} sm={12}></Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Tab.Container>
          </div>
        </div>
        <Footer />
      </div>

      {/**************************************************Mobile*********************************************/}
      <div className="d-block d-md-none">
        <ProfileTabs active="listing" />
        <div className="accountmain new-listing new-mobile-list mb-0">
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeTab}
            onSelect={(k) => {
              setActiveTab(k);
              if (rid) {
                navigate(`/lender/new-item?atab=${k}&rid=${rid}`);
              }
            }}
          >
            <Container fluid>
              <Row>
                {isSelected ? (
                  <></>
                ) : (
                  <Col
                    lg={4}
                    md={4}
                    sm={12}
                    className="bgwhite pt-3 pb-5 h90vh mobilesidebarlist"
                  >
                    <Row>
                      <Col lg={4} md={3} sm={12}></Col>
                      <Col lg={8} md={9} sm={12}>
                        <div className="d-flex d-lg-none mb-2 align-items-center">
                          <Link to="/lender/listing" className="me-3">
                            <BackIcon />{" "}
                          </Link>
                          <h4 className="">
                            {productDetails && productDetails?.title}
                          </h4>
                        </div>
                        <Accordion defaultActiveKey={["0"]} alwaysOpen>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Item Details</Accordion.Header>
                            <Accordion.Body>
                              <Nav variant="pills" className="flex-row">
                                <Nav.Item
                                  onClick={() => {
                                    setIsSelected(true);
                                    setbottomcontent(true);
                                  }}
                                >
                                  <Nav.Link eventKey="first">
                                    Listing Basics
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => setIsSelected(true)}>
                                  <Nav.Link eventKey="second">Photos</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => setIsSelected(true)}>
                                  <Nav.Link eventKey="third">Location</Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        <Accordion defaultActiveKey={["1"]} alwaysOpen>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              Pricing & Availability
                            </Accordion.Header>
                            <Accordion.Body>
                              <Nav variant="pills" className="flex-row">
                                <Nav.Item onClick={() => setIsSelected(true)}>
                                  <Nav.Link eventKey="fourth">Pricing</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => setIsSelected(true)}>
                                  <Nav.Link eventKey="fifth">
                                    Discounts
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => setIsSelected(true)}>
                                  <Nav.Link eventKey="sixth"> Fees</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => setIsSelected(true)}>
                                  <Nav.Link
                                    eventKey="seventh"
                                    className="font16 font700 mt-3"
                                  >
                                    <h2 className="font16 font700 mb-0">
                                      Info for renter
                                    </h2>
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col lg={8} md={8} sm={12} className="mb-5">
                  <Row>
                    <Col lg={10} md={10} sm={12}>
                      <Tab.Content>
                        {isSelected ? (
                          <div
                            className="d-flex d-lg-none mb-4 align-items-center pt-3"
                            onClick={() => {
                              setIsSelected(false);
                              setActiveTab("");
                            }}
                          >
                            <Link to="javascript:void(0)" className="me-3">
                              <BackIcon />{" "}
                            </Link>
                            <h4 className="">{productDetails?.title} </h4>
                          </div>
                        ) : (
                          <></>
                        )}
                        {bottomcontent && (
                          <Tab.Pane eventKey="first" className="pt-2 pb-5">
                            <ListingBasic productDetails={productDetails} />
                          </Tab.Pane>
                        )}
                        <Tab.Pane eventKey="second" className="pt-0 pb-5">
                          <Photos
                            productDetails={productDetails}
                            ProductDetails={ProductDetails}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="third" className="pt-0 pb-5">
                          <Location productDetails={productDetails} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth" className="pt-0 pb-5">
                          <Pricing productDetails={productDetails} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth" className="pt-0 pb-5">
                          <Discounts productDetails={productDetails} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="sixth" className="pt-0 pb-5">
                          <Fees productDetails={productDetails} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="seventh" className="pt-0 pb-5">
                          <ExtraInfo productDetails={productDetails} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                    <Col lg={2} md={2} sm={12}></Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Tab.Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CreateNewListing;
