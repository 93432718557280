/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { useFormik } from "formik";
import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import HomeFooter from "../../Commons/HomeFooter";
import { NewsLetter } from "../../Services/APIServices";
import { LENDER, RENTER } from "../../globals/constants";
import useRole from "../../hooks/useRole";
import showNotification from "../../Services/NotificationService";
const RenterNews = () => {
  const role = useRole();
  const [loding, setLoading] = useState(false);
  const userDetails = useSelector((state) => state?.Login?.userDetails);
  const validationSchema = Yup.object({
    email: Yup.string()
      .email()
      .required("Email required")
      .max(70, "Maximum lenght is 70 characters.")
      .min(5, "Need at least 5 characters.")
      .matches(/^[a-zA-Z0-9_.+-]+[@]\w+[.]\w{2,3}/, "Please enter valid email"),
  });
  const { handleSubmit, handleChange, values, errors, touched, resetForm } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema,
      onSubmit(values) {
        let formData = new FormData();
        formData.append("email", values.email);

        setLoading(true);
        NewsLetter(formData)
          .then((resp) => {
            setLoading(false);
            if (resp?.data?.status === 200) {
              showNotification("success", resp?.data?.message);
              resetForm();
            }
          })
          .catch((err) => console.log(err));
      },
    });

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        <div className="top-head">
          <Container>
            <div className="row">
              <Col>
                <h4 className="font48">Newsletter</h4>
              </Col>
            </div>
          </Container>
        </div>
        <div className="top-area newsletter pt-36 pb-156 mt-131 mb-151">
          <Container>
            <Row>
              <Col lg={7} md={7}>
                <h1 className="mb-70 font900 font24">Shaxify News</h1>
                <h2 className="font40  font700">
                  Sign up to get the latest news from shaxify delivered to you
                </h2>
              </Col>
              <Col lg={5} md={5}>
                <h3 className="font24 font700">Enter email</h3>
                <Form
                  className="d-flex align-items-center justify-content-between mt-5"
                  onSubmit={handleSubmit}
                >
                  <Form.Group className="mb-3 position-relative w-100">
                    <Form.Control
                      type="text"
                      className="email"
                      value={values?.email}
                      name="email"
                      onChange={handleChange}
                    />

                    <span className="text-start required">
                      {touched?.email && errors?.email}
                    </span>
                  </Form.Group>

                  <button type="submit" className="btn btn-theme mt-0 ms-1">
                    Submit
                  </button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* <Footer /> */}
      {userDetails ? <Footer /> : <HomeFooter />}
    </>
  );
};

export default RenterNews;
