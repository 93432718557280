import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Commons/Footer";
import { getAddressList } from "../../Services/APIServices";
import { BackIcon } from "../../SvgIcons/allIcons";
import showNotification from "../../Services/NotificationService";
import { useDispatch, useSelector } from "react-redux";
import { checkoutAddress } from "../../Redux/CheckoutAddressSlice";
import useRole from "../../hooks/useRole";
import { LENDER, RENTER } from "../../globals/constants";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Header from "../../Commons/Headers/Header";

const AddAddressModal = () => {
  const role = useRole();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const id = new URLSearchParams(location?.search).get("id");
  const from = new URLSearchParams(location?.search).get("from");
  const to = new URLSearchParams(location?.search).get("to");
  const isCod = new URLSearchParams(location?.search).get("cod");
  const pincode = new URLSearchParams(location?.search).get("pincode");
  const addressData = useSelector((state) => state?.checkout?.address);

  useEffect(() => {
    locationList();
    if (addressData) {
      setSelectedAddress(addressData);
    }
  }, []);

  const locationList = () => {
    getAddressList().then((resp) => {
      if (resp?.data?.status === 200) {
        setAddressList(resp?.data?.data);
      }
    });
  };

  const handleAddressChange = (data) => {
    setSelectedAddress(data);
  };

  const handleNavigate = () => {
    if (selectedAddress == "") {
      showNotification("danger", "Please select address");
      return false;
    }
    dispatch(checkoutAddress(selectedAddress));
     navigate(
       `/renter/checkout?id=${id}&from=${from}&to=${to}&pincode=${selectedAddress.zipcode}&cod=${isCod}`
     );
  };

  const handleDispatch = () => {
    dispatch(checkoutAddress(selectedAddress));
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="delete-items">
        <div className="middle-area text-start">
          <div className="p-4">
            <p className="head16 d-flex align-items-center gap-3">
              <Link
                to={`/renter/checkout?id=${id}&from=${from}&to=${to}&pincode=${pincode}`}
                onClick={() => handleDispatch()}
                className="d-block d-md-none"
              >
                <BackIcon />
              </Link>
              Select a delivery address
            </p>
            <Row>
              <Col sm={12} className="mx-auto">
                {addressList &&
                  addressList.length > 0 &&
                  addressList?.map((data, index) => {
                    return (
                      <Card
                        className="addresscard shadow-none rounded-0 mb-3 h-auto"
                        key={index}
                      >
                        <div className="d-flex align-items-start gap-3 w-100">
                          <div className="custom-checkbox">
                            <Form.Check
                              type="radio"
                              name="address"
                              id={`address-${data?.id}`}
                              value={data?.id}
                              checked={data?.id === selectedAddress?.id}
                              onChange={() => handleAddressChange(data)}
                            />
                          </div>
                          <label htmlFor={`address-${data?.id}`}>
                            <p className="mb-0">
                              {`${data?.address1} ${data?.address2}`}
                              <br />
                              {`${data?.city} ${data?.state}`}
                              <br />
                              {data?.country}
                              <br />
                              {data?.zipcode}
                            </p>
                          </label>
                        </div>
                      </Card>
                    );
                  })}

                <div className="text-decoration-none">
                  <Card
                    className="addresscard addadress shadow-none rounded-0 h-auto d-flex align-items-center justify-content-center"
                    htmlFor="addNew"
                    onClick={() =>
                      navigate(
                        `/renter/add-new-address?id=${id}&from=${from}&to=${to}&pincode=${pincode}`
                      )
                    }
                  >
                    <p className="mb-0 font14 font700" id="addNew">
                      Add new address
                    </p>
                  </Card>
                </div>
              </Col>
            </Row>
            <Button
              className="btn btn-theme font700 mb-0 w-100 use-address-mobile"
              onClick={() => handleNavigate()}
            >
              Use This Address
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddAddressModal;
