import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isLogin } from "../../Redux/LoginSlice";
import { ChangeUserPassword } from "../../Services/APIServices";

const Notification = ({ activeTab }) => {
  const userDetails = useSelector((state) => state?.Login?.userDetails);
  const dispatch = useDispatch();
  const {
    handleChange,
    handleBlur,
    isSubmitting,
    setValues,
    values,
    isValid,
    handleSubmit,
  } = useFormik({
    initialValues: {
      select_type: 2,
      email: "",
      text: "",
      promotion: "",
    },
    onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      let formData = new FormData();
      formData.append("select_type", 2);
      formData.append("email_type", values?.email ? "1" : "0");
      formData.append("text_type", values?.text ? "1" : "0");
      formData.append("promotion_type", values?.promotion ? "1" : "0");
      ChangeUserPassword(formData).then((resp) => {
        if (resp?.status === 200) {
          setSubmitting(false);
          dispatch(isLogin(resp?.data?.data));
        }
      });
    },
  });

  useEffect(() => {
    setValues({
      email: userDetails?.email_notification,
      text: userDetails?.text_notification,
      promotion: userDetails?.recieve_promotions_notification,
    });
  }, [userDetails, activeTab]);

  return (
    <>
      <p className="head16">Notifications</p>
      <p className="head12">
        Receive notifications related to your rentals, requests and bookings
      </p>
      <Row>
        <Col lg={4} md={6}>
          <Form className="notification">
            <div className="mb-2">
              <Form.Check
                label="Emails"
                id="Emails"
                type="checkbox"
                name="email"
                value={values?.email}
                checked={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="mb-2">
              <Form.Check
                label="Texts"
                id="Texts"
                type="checkbox"
                name="text"
                value={values?.text}
                checked={values?.text}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className="">
              <Form.Check
                label="Want to receive promotions"
                id="Want to receive promotions"
                type="checkbox"
                name="promotion"
                value={values?.promotion}
                checked={values?.promotion}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <Button
              className="btn btn-theme font700 w-50 mt-3 d-none d-lg-block d-md-block"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Notification;
