/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Link, useNavigate } from "react-router-dom";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Footer from "../../Commons/Footer";
import { isLogin } from "../../Redux/LoginSlice";
import {
  ChangeCurrentRole,
  getCategoryHome,
  getSlideCategory,
  getStaticPages,
} from "../../Services/APIServices";
import { ArrowrightIcon } from "../../SvgIcons/allIcons";
import { truncate } from "../../globals/HelperCommon";
import {
  HOME_PAGE_CATEGORY_LIMIT,
  HOW_IT_WORKS,
  LENDER,
  RENTER,
} from "../../globals/constants";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Header from "../../Commons/Headers/Header";
import useRole from "../../hooks/useRole";
import Loader from "../../Commons/Loader/Loader";
import { imageBase } from "../../globals/Config";

const RenterHome = () => {
  const role = useRole();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [banner, setBanner] = useState([]);
  const [howitWorks, setHowItWorks] = useState("");

  useEffect(() => {
    catagoryList();
    getSlideList();
    howItWorks();
  }, []);

  const dispatch = useDispatch();
  const [list, setList] = useState([]);

  const catagoryList = () => {
    getCategoryHome("", HOME_PAGE_CATEGORY_LIMIT).then((resp) => {
      if (resp?.status === 200) {
        setList(resp?.data?.data);
      }
    });
  };

  const handleViewBaner = (id) => {
    navigate(`/renter/category-map-view?id=${id}`);
  };

  const handleViewCategory = (data) => {
    navigate(`/renter/category-map-view?id=${data?.id}&t=${data?.title}`);
  };

  const getSlideList = () => {
    getSlideCategory().then((resp) => {
      if (resp?.status === 200) {
        setBanner(resp?.data?.data);
        catagoryList();
      }
    });
  };

  const { host, hostname, href, origin, pathname, port, protocol } =
    window.location;

  let currentpage = "";
  if (pathname == "/lender/home") {
    currentpage = "home";
  } else if (pathname == "/renter/category-map-view") {
    currentpage = "marketplace";
  } else if (pathname == "/lender/how-it-works") {
    currentpage = "how-it-works";
  } else if (pathname == "/message") {
    currentpage = "message";
  } else if (pathname == "/lender/listing") {
    currentpage = "item_list";
  }

  const handleRoleChange = (role) => {
    let formData = new FormData();
    formData.append("role_id", role);
    setLoader(true);
    ChangeCurrentRole(formData).then((resp) => {
      setLoader(false);
      if (resp?.data?.status === 200) {
        localStorage.setItem("userDetails", JSON.stringify(resp?.data?.data));
        dispatch(isLogin(resp?.data?.data));
        if (resp?.data?.data?.role_id === RENTER) {
          navigate("/renter/home");
        }
        if (resp?.data?.data?.role_id === LENDER) {
          navigate("/lender/listing");
        }
      }
    });
  };

  const howItWorks = () => {
    getStaticPages(HOW_IT_WORKS).then((resp) => {
      if (resp?.data?.status === 200) {
        setHowItWorks(resp?.data?.data);
      }
    });
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        <div className="main-slider pb-0">
          <div className="hero-banner pb-5">
            <Container>
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={50}
                slidesPerView={1}
                navigation={true}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={true}
                className="pb-5"
              >
                {banner &&
                  banner?.map((data, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <Row className="align-items-center">
                          <>
                            <Col lg={6} md={6} sm={12}>
                              <div className="slide-content px-3">
                                <h2>{truncate(data?.title, 50)}</h2>
                                <p>{truncate(data?.description, 200)}</p>
                                <Button
                                  className="btn btn-theme"
                                  onClick={() => handleViewBaner(data?.id)}
                                >
                                  Take me there
                                </Button>
                              </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                              <img
                                src={
                                  data?.image
                                    ? imageBase + data?.image
                                    : require("../../assets/images/DummyImg.png")
                                }
                                alt="slide1"
                              />
                            </Col>
                          </>
                        </Row>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Container>
          </div>

          <Container>
            <div className="category-boxes">
              <Row>
                {list && list.length > 0 ? (
                  list?.map((data, index) => {
                    return (
                      <Col
                        lg={3}
                        md={4}
                        sm={12}
                        onClick={() => handleViewCategory(data)}
                        style={{ cursor: "pointer" }}
                        key={index}
                        className="mb-3"
                      >
                        <Card>
                          <Card.Img
                            variant="top heightauto"
                            src={
                              data?.category_image
                                ? imageBase + data?.category_image
                                : require("../../assets/images/static_image.jpg")
                            }
                          />
                          <Card.Body>
                            <Card.Title>
                              {" "}
                              {data?.title ? truncate(data?.title, 20) : "NA"}
                            </Card.Title>
                            <Card.Text>
                              {data?.description
                                ? truncate(data?.description, 50)
                                : "NA"}
                            </Card.Text>
                            <Link className="btn btn-shop ps-0">
                              Shop Now! <ArrowrightIcon />
                            </Link>
                          </Card.Body>
                        </Card>
                      </Col>
                    );
                  })
                ) : (
                  <></>
                )}
              </Row>
            </div>
          </Container>
        </div>

        <div className="how-it-work ptb">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6} sm={12}>
                <div className="pe-lg-5 pe-0 me-lg-5 me-0">
                  <h4 className="font24 text-start">How it works</h4>
                  <h2>{howitWorks?.title}</h2>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: howitWorks?.content,
                    }}
                  />
                  <a href="/how-it-works" className="btn btn-theme">
                    Learn More
                  </a>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <img
                  src={require("../../assets/images/how-it-work-img.png")}
                  alt="img"
                />
              </Col>
            </Row>
          </Container>
        </div>

        <div className="itmes-list ptb renter-items">
          <Container>
            <Row className="align-items-center pt-lg-5">
              <Col lg={6} md={6} sm={12}>
                <h4 className="text-white">Want to make extra cash?</h4>
                <h2 className="text-white">
                  With a shared economy you are giving each product a new life
                </h2>
                <Button
                  className="btn btn-theme mt-3"
                  type="button"
                  onClick={() => handleRoleChange(LENDER)}
                >
                  List your item
                </Button>
              </Col>
            </Row>
            <div className="category-boxes list-boxes mb-0">
              <Row>
                <Col className="mb-3" lg={3} md={4} sm={12}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={require("../../assets/images/electronics.png")}
                    />
                    <Card.Body>
                      <Card.Title>
                        <b>Get your rental delivered to your door steps</b>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="mb-3" lg={3} md={4} sm={12}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={require("../../assets/images/fashion.png")}
                    />
                    <Card.Body>
                      <Card.Title>
                        <b>Secure online payments</b>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="mb-3" lg={3} md={4} sm={12}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={require("../../assets/images/baby-essential.png")}
                    />
                    <Card.Body>
                      <Card.Title>
                        <b>Insurance coverage is on us</b>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="mb-3" lg={3} md={4} sm={12}>
                  <Card>
                    <Card.Img
                      variant="top"
                      src={require("../../assets/images/sports-equipment.png")}
                    />
                    <Card.Body>
                      <Card.Title>
                        <b>Support any problems</b>
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <Footer />
      {loader && <Loader />}
    </>
  );
};

export default RenterHome;
