/*
@copyright : Ozvid Technologies Pvt. Ltd. < www.ozvid.com >
@author     : Shiv Charan Panjeta < shiv@ozvid.com >
 
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of Ozvid Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
import axios from "axios";
import URL from "../globals/Config";
import showNotification from "../Services/NotificationService";
import * as session from "../utils/session";

/**Create a instance of axios with a custom config */
export const http = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const http_multipart = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

/**Add a request interceptor */
http.interceptors.request.use(
  function (config) {
    const token = session.getToken();

    if (token) {
      config.headers[`Authorization`] = `Token ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**Add a response interceptor */
http.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response) {
      if (error.response.status === 400) {
        showNotification("danger", error.response.data.message);
        return error.response;
      }
      if (error.response.status === 401) {
        localStorage.clear();        
        window.location.href = "/";
      }
      if (error.response.status === 500) {
        showNotification("danger", "Internal server error");
      }

      if (error.response.status === 403) {
        showNotification("danger", error.response.data.message);
        localStorage.clear();
      } else {
        return Promise.reject(error);
      }
    }
  }
);

// Multipart form-data  Request interceptor
http_multipart.interceptors.request.use(
  function (config) {
    const token = session.getToken();

    if (token) {
      config.headers[`Authorization`] = `Token ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http_multipart.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response.status === 400) {
      showNotification("danger", error.response.data.message);
      return error.response;
    }
    if (error.response.status === 403) {
      showNotification("danger", error.response.data.message);
      localStorage.clear();
    }
    if (error.response.status === 500) {
      showNotification("danger", "Internal server error");
    }
    if (error.response.status === 401) {
      localStorage.clear();   
      window.location.href = "/";
    }
  }
);
