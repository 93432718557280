import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Loader from "../../Commons/Loader/Loader";
import showNotification from "../../Services/NotificationService";
import { BackIcon } from "../../SvgIcons/allIcons";
import { LENDER, RENTER } from "../../globals/constants";
import { AddCard } from "../../Services/APIServices";
import useRole from "../../hooks/useRole";
import { acceptOnlyNum, restrictAlpha } from "../../utils/CommonFunction";

const AddCardMobile = () => {
  const role = useRole();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const id = new URLSearchParams(location.search).get("id");
  const from = new URLSearchParams(location?.search).get("from");
  const to = new URLSearchParams(location?.search).get("to");
  const pincode = new URLSearchParams(location?.search).get("pincode");
  const validationSchema = Yup.object({
    card_holder_name: Yup.string().required("This field is required").trim(),
    card_number: Yup.string().required("This field is required."),
    expiry_date: Yup.string().required("This field is required."),
    cvv: Yup.string()
      .required("This field is required.")
      .min(3, "Minimum 3 digit required"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
    isSubmitting,
    setFieldValue,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      card_holder_name: "",
      card_number: "",
      expiry_date: "",
      cvv: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);
      let body = {
        card_holder_name: values.card_holder_name,
        card_number: values.card_number?.split(" ")?.join(""),
        expiry_date: values.expiry_date,
        cvv: values.cvv,
      };

      let formData = new FormData();
      Object.keys(body).forEach((data) => {
        formData.append(data, body[data]);
      });
      setLoading(true);
      AddCard(formData).then((resp) => {
        setLoading(false);
        setSubmitting(false);
        if (resp?.status === 200) {
          resetForm();
          navigate(
            `/renter/payment-modal?id=${id}&from=${from}&to=${to}&pincode=${pincode}`
          );
        } else {
          showNotification("danger", resp?.data?.message);
        }
      });
    },
  });

  const handleCardDisplay = () => {
    const rawText = [...values?.card_number.split(" ").join("")]; // Remove old space
    const creditCard = []; // Create card as array
    rawText.forEach((t, i) => {
      if (i % 4 === 0 && i !== 0) creditCard.push(" "); // Add space
      creditCard.push(t);
    });
    return creditCard.join(""); // Transform card array to string
  };

  const handleExpiryDate = (event) => {
    if (event.key == "Backspace") {
      setFieldValue("expiry_date", event.target.value);
    } else {
      setFieldValue(
        "expiry_date",
        event.target.value
          .replace(
            /^([1-9]\/|[2-9])$/g,
            "0$1/" // 3 > 03/
          )
          .replace(
            /^(0[1-9]|1[0-2])$/g,
            "$1/" // 11 > 11/
          )
          .replace(
            /^([0-1])([3-9])$/g,
            "0$1/$2" // 13 > 01/3
          )
          .replace(
            /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
            "$1/$2" // 141 > 01/41
          )
          .replace(
            /^([0]+)\/|[0]+$/g,
            "0" // 0/ > 0 and 00 > 0
          )
          .replace(
            /[^\d\/]|^[\/]*$/g,
            "" // To allow only digits and `/`
          )
          .replace(
            /\/\//g,
            "/" // Prevent entering more than 1 `/`
          )
      );
    }
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="delete-items">
        <div className="middle-area text-start new-address">
          <div className="p-4">
            <Row>
              <p className="head16 d-flex align-items-center gap-3">
                <Link
                  to={`/renter/checkout?id=${id}&from=${from}&to=${to}&pincode=${pincode}`}
                  className="d-block d-md-none"
                >
                  <BackIcon />
                </Link>
                Add new card
              </p>
              <Col sm={12} className="mx-auto">
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label className="font700 font12 secondaycolor">
                      Card Holder Name <span className="error">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="card_holder_name"
                      placeholder="Enter card holder name"
                      onChange={handleChange}
                      value={values?.card_holder_name}
                      onBlur={handleBlur}
                    />
                    <span className="text-start required">
                      {touched.card_holder_name && errors.card_holder_name}{" "}
                    </span>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="font700 font12 secondaycolor">
                      Card number <span className="error">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text "
                      name="card_number"
                      placeholder="Enter card number "
                      onChange={handleChange}
                      value={handleCardDisplay()}
                      onBlur={handleBlur}
                      maxLength={19}
                      onKeyPress={acceptOnlyNum}
                    />

                    <span className="text-start required">
                      {touched.card_number && errors.card_number}
                    </span>
                  </Form.Group>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          Expiry date <span className="error">*</span>
                        </Form.Label>

                        <Form.Control
                          type="text"
                          name="expiry_date"
                          placeholder="MM/YY"
                          onChange={handleChange}
                          onKeyUp={handleExpiryDate}
                          value={values?.expiry_date}
                          onBlur={handleBlur}
                          maxLength={5}
                        />

                        <span className="text-start required">
                          {touched.expiry_date && errors.expiry_date}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          Security Code <span className="error">*</span>
                        </Form.Label>

                        <Form.Control
                          type="password"
                          name="cvv"
                          placeholder="Enter security code "
                          onChange={handleChange}
                          value={values?.cvv}
                          onBlur={handleBlur}
                          maxLength={4}
                          min={3}
                          onKeyPress={restrictAlpha}
                        />

                        <span className="text-start required">
                          {touched.cvv && errors.cvv}{" "}
                        </span>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    className="btn btn-theme font700 mb-0 w-100"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    Add
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

export default AddCardMobile;
