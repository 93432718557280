/**
@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import ReactStars from "react-stars";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import Nodata from "../Commons/CommonModals/Nodata";
import Dropdown from "../Commons/Dropdown";
import Footer from "../Commons/Footer";
import Header from "../Commons/Headers/Header";
import LenderHeader from "../Commons/Headers/LenderHeader";
import RenterHeader from "../Commons/Headers/RenterHeader";
import Loader from "../Commons/Loader/Loader";
import Paginate from "../Pagination/Pagination";
import { categoryItemsList, getCategory } from "../Services/APIServices";
import { CalendarIcon } from "../SvgIcons/allIcons";
import { imageBase } from "../globals/Config";
import { truncate } from "../globals/HelperCommon";
import {
  ACTIVE_STATE,
  DEFAULT_CURRENCY,
  HIGH_TO_LOW,
  LENDER,
  LOW_TO_HIGH,
  PERPAGE_TEN,
  PERPAGE_THIRTY,
  PERPAGE_TWENTY,
  RENTER,
} from "../globals/constants";
import useDocumentTitle from "../globals/useDocumentTitle";
import useRole from "../hooks/useRole";
import MobileCategoryItem from "./MobileCategoryItem";
import Map from "./Renter/Checkout/Map";

const CategoryItems = () => {
  useDocumentTitle("Shaxify - MarketPlace");
  const navigate = useNavigate();
  const location = useLocation();
  const role = useRole();
  const scrollerRef = useRef();
  const token = localStorage.getItem("token");
  const id = new URLSearchParams(location?.search).get("id");
  const title = new URLSearchParams(location?.search).get("t");
  const [activeTab, setActiveTab] = useState("second");
  const [list, setList] = useState([]);
  const [catId, setCatId] = useState("");
  const [search, setSearch] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [sort_by, setSort_by] = useState("");
  const [status] = useState(ACTIVE_STATE);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PERPAGE_TEN);
  const [meta, setMeta] = useState("");
  const [categoryOption, setCategoryOption] = useState([]);
  const searchText = useSelector((state) => state?.search?.search);
  const [loading, setLoding] = useState(true);
  const checkLoggedInUser = useSelector((state) => state?.Login?.login);
  const [showActive, setShowActive] = useState("");

  useEffect(() => {
    setSearch(searchText);
    catagoryList();
    getCategoryItems(
      id ? id : "",
      searchText,
      from,
      to,
      sort_by?.value,
      status,
      page,
      perPage
    );
  }, [searchText]);

  useEffect(() => {
    if (title && id) {
      setCatId({ label: title, value: id });
      handleCategoryChange({ label: title, value: id });
    }
  }, [id, title]);

  /**
   * List of leftsidebar > products
   *
   * @param {*} id
   * @param {*} title
   * @param {*} from
   * @param {*} to
   * @param {*} sort_by
   * @param {*} status
   * @param {*} page
   * @param {*} perPage
   */

  const getCategoryItems = (
    id = "",
    title = "",
    from = "",
    to = "",
    sort_by = "",
    status = "",
    page = "1",
    perPage = "10",
    map_view = false
  ) => {
    categoryItemsList(
      id,
      title,
      from,
      to,
      sort_by,
      status,
      page,
      perPage,
      map_view
    )
      .then((resp) => {
        setLoding(false);
        if (resp?.status === 200) {
          setList(resp?.data?.data);
          setMeta(resp?.data?.meta);
        }
      })
      .finally(() => setLoding(false));
  };

  const handleViewProduct = (id) => {
    {
      token
        ? navigate(`/renter/renter-details?id=${id}`)
        : navigate(`/renter-details?id=${id}`);
    }
  };

  const catagoryList = () => {
    getCategory().then((resp) => {
      if (resp?.status === 200) {
        let options = [];
        resp?.data?.data?.map((item) => {
          return options.push({
            label: item?.title,
            value: item?.id,
          });
        });

        setCategoryOption(options);
      }
    });
  };

  const handleCategoryChange = (e) => {
    setLoding(true);
    if (e?.value) {
      navigate({
        pathname: location?.pathname,
        search: `?id=${e?.value}&t=${e?.label}`,
      });
    }
    getCategoryItems(
      e?.value ? e.value : "",
      search,
      from,
      to,
      sort_by?.value,
      status,
      page,
      perPage
    );
    setCatId(e);
  };

  const handleFromDate = (e) => {
    if (to && e.target.value > to) {
      getCategoryItems(
        catId?.value ? catId?.value : "",
        search,
        to,
        e.target.value,
        sort_by?.value,
        status,
        page,
        perPage
      );
      setFrom(to);
      setTo(e.target.value);
    } else {
      getCategoryItems(
        catId?.value ? catId?.value : "",
        search,
        e.target.value,
        to,
        sort_by?.value,
        status,
        page,
        perPage
      );
      setFrom(e.target.value);
    }
  };

  const handleToDate = (e) => {
    if (from && e.target.value < from) {
      getCategoryItems(
        catId?.value ? catId?.value : "",
        search,
        e.target.value,
        from,
        sort_by?.value,
        status,
        page,
        perPage
      );
      setTo(from);
      setFrom(e.target.value);
    } else {
      getCategoryItems(
        catId?.value ? catId?.value : "",
        search,
        from,
        e.target.value,
        sort_by?.value,
        status,
        page,
        perPage
      );
      setTo(e.target.value);
    }
  };

  const handleSortChange = (e) => {
    getCategoryItems(
      catId?.value ? catId?.value : "",
      search,
      from,
      to,
      e?.value,
      status,
      page,
      perPage
    );
    setSort_by(e);
  };

  const handlePageChange = (e) => {
    setPage(e.selected + 1);
    getCategoryItems(
      catId?.value ? catId?.value : "",
      search,
      from,
      to,
      sort_by?.value,
      status,
      e.selected + 1,
      perPage
    );
    if (scrollerRef.current) {
      scrollerRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
      window.scrollTo(0, 0);
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    setPage(1);
    getCategoryItems(
      catId?.value ? catId?.value : "",
      search,
      from,
      to,
      sort_by?.value,
      status,
      1,
      e.target.value
    );
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    control: (provided) => ({
      ...provided,
      border: "1px solid #E8E8E8",
      backgroundColor: "#fff",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #E8E8E8",
      },
      outline: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const handleViewProfile = (id) => {
    if (checkLoggedInUser) {
      navigate(`/renter/user-profile?user_id=${id}`);
    } else {
      document.getElementById("headerlogin").click();
    }
  };

  return (
    <>
      <div className="d-none d-md-block">
        {role == LENDER ? (
          <LenderHeader />
        ) : role == RENTER ? (
          <RenterHeader />
        ) : (
          <Header />
        )}
        <div className="mainbox_outer">
          <div className="top-breadcrumb catagorybar">
            <Container>
              <div className="w-25 position-relative">
                <Select
                  name={"category_mapping"}
                  value={catId}
                  onChange={(e, r) => {
                    handleCategoryChange(e);
                    if (r.action === "clear") {
                      navigate({
                        pathname: location?.pathname,
                        search: `?id=&t=`,
                      });
                    }
                  }}
                  options={categoryOption}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 5,
                    colors: {
                      ...theme.colors,
                      primary25: "rgb(22 255 150 / 24%)",
                      primary: "#16ff961a",
                    },
                  })}
                  isClearable
                  styles={customStyles}
                  className="dropdown-role"
                  placeholder={"Select Category"}
                />
              </div>
            </Container>
          </div>
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
          >
            <div className="filterbar">
              <Container>
                <Row className="align-items-end">
                  <Col lg={8} md={12} sm={12}>
                    <Form>
                      <Row>
                        <Col lg={3} md={6} sm={12}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font700 font12 secondaycolor">
                              From
                            </Form.Label>
                            <div className="datepicker position-relative">
                              <Form.Control
                                type="date"
                                placeholder="DD/MM/YYYY"
                                min={moment().format("YYYY-MM-DD")}
                                value={from}
                                name={from}
                                onChange={handleFromDate}
                              />
                              <span className="position-absolute">
                                <CalendarIcon />
                              </span>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font700 font12 secondaycolor">
                              To
                            </Form.Label>
                            <div className="datepicker position-relative">
                              <Form.Control
                                type="date"
                                placeholder="DD/MM/YYYY"
                                min={moment().format("YYYY-MM-DD")}
                                value={to}
                                name={to}
                                onChange={handleToDate}
                              />
                              <span className="position-absolute">
                                <CalendarIcon />
                              </span>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                          <Form.Group className="mb-3">
                            <Form.Label className="font700 font12 secondaycolor">
                              Sort by
                            </Form.Label>
                            <Dropdown
                              name="category_mapping"
                              value={sort_by}
                              clearable={true}
                              options={[
                                {
                                  label: "Total price high",
                                  value: HIGH_TO_LOW,
                                },
                                {
                                  label: "Total price low",
                                  value: LOW_TO_HIGH,
                                },
                              ]}
                              placeholder={"Select"}
                              onChange={(e) => handleSortChange(e)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col lg={4} md={12} sm={12}>
                    <div className="grid-list-btn d-flex mb-3">
                      <Nav justify-end variant="pills">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="first"
                            onClick={() => {
                              getCategoryItems(
                                id,
                                "",
                                "",
                                sort_by,
                                status,
                                "",
                                "1",
                                PERPAGE_TEN,
                                true
                              );
                            }}
                          >
                            Map View
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second"
                            onClick={() => {
                              getCategoryItems(
                                id,
                                "",
                                "",
                                sort_by,
                                status,
                                "",
                                "1",
                                perPage
                              );
                            }}
                          >
                            Grid View
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="category-boxes position-relative">
                  <div className="absoluteboxmain">
                    <Container className="px-lg-0">
                      <Row className="m-0 me-3">
                        <Col lg={4} md={4} sm={12} className="absolutebox">
                          <div className="sidevarscroller" ref={scrollerRef}>
                            {list && list?.length > 0 ? (
                              list?.map((data, index) => {
                                return (
                                  <Card
                                    id={data?.id}
                                    className={
                                      showActive == data?.id
                                        ? "active-lft-card mb-4"
                                        : "mb-4"
                                    }
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Card.Img
                                      onClick={() =>
                                        handleViewProduct(data?.id)
                                      }
                                      variant="top"
                                      src={
                                        data?.product_image?.images
                                          ? imageBase +
                                            data?.product_image?.images
                                          : require("../assets/images/static_image.jpg")
                                      }
                                    />
                                    <Card.Body>
                                      <p
                                        className="font12 font700 mb-2 d-flex align-items-center gap-2 "
                                        title={data?.created_by?.full_name}
                                        onClick={() =>
                                          handleViewProfile(
                                            data?.created_by?.id
                                          )
                                        }
                                      >
                                        <em>
                                          {truncate(
                                            data?.created_by?.full_name,
                                            12
                                          )}{" "}
                                          ({data?.rental_count})
                                        </em>
                                      </p>
                                      <span>
                                        <ReactStars
                                          className="react-star"
                                          value={data?.average_rating}
                                          count={5}
                                          size={24}
                                          color2={"#16ff96"}
                                          edit={false}
                                        />
                                      </span>
                                      <div
                                        onClick={() =>
                                          handleViewProduct(data?.id)
                                        }
                                      >
                                        <h5 className="head16">
                                          {data?.title}
                                        </h5>
                                        <p className="font700 font12 mb-0">
                                          Today Price :{" "}
                                          {data?.daily_price
                                            ? ` ${DEFAULT_CURRENCY} ${Number(
                                                data?.daily_price
                                              ).toLocaleString("hi")}`
                                            : ""}
                                        </p>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                );
                              })
                            ) : (
                              <>{!loading && <Nodata />}</>
                            )}

                            <div className="m-3">
                              <Paginate
                                page={meta?.page_count}
                                handlePageClick={(e) => handlePageChange(e)}
                                cp={page}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <Container fluid className="px-0">
                    <Row className="m-0">
                      <Col lg={4}></Col>
                      <Col lg={8} sm={12} className="p-0 h-100">
                        <div className="mappingimg overflow-hidden">
                          <Map
                            props={list}
                            device={"website"}
                            setShowActive={setShowActive}
                            getCategoryItems={getCategoryItems}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="accountmain new-listing mt-4">
                  <Container>
                    <Row>
                      {list && list?.length > 0 ? (
                        list?.map((data, index) => {
                          return (
                            <Col
                              lg={3}
                              md={4}
                              sm={12}
                              key={index}
                              className="mb-3"
                            >
                              <Card
                                className="mb-4"
                                style={{ cursor: "pointer" }}
                              >
                                <Card.Img
                                  variant="top"
                                  className="product-img"
                                  onClick={() => handleViewProduct(data?.id)}
                                  src={
                                    data?.product_image?.images
                                      ? imageBase + data?.product_image?.images
                                      : require("../assets/images/static_image.jpg")
                                  }
                                />
                                <Card.Body>
                                  <div className="d-flex align-items-center gap-3">
                                    <p
                                      className="font12 font700 mb-0"
                                      onClick={() =>
                                        handleViewProfile(data?.created_by?.id)
                                      }
                                    >
                                      <em>
                                        {truncate(
                                          data?.created_by?.full_name,
                                          12
                                        )}{" "}
                                        ({data?.rental_count})
                                      </em>
                                    </p>
                                    <span>
                                      <ReactStars
                                        value={data?.average_rating}
                                        count={5}
                                        size={24}
                                        color2={"#16ff96"}
                                        edit={false}
                                      />
                                    </span>
                                  </div>
                                  <div
                                    onClick={() => handleViewProduct(data?.id)}
                                  >
                                    <h5 className="head16">{data?.title}</h5>
                                    <p className="font700 font12 mb-0">
                                      {" "}
                                      Today Price :{" "}
                                      {data?.daily_price
                                        ? ` ${DEFAULT_CURRENCY} ${Number(
                                            data?.daily_price
                                              ? data?.daily_price
                                              : 0
                                          ).toLocaleString("hi")}`
                                        : ""}
                                    </p>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })
                      ) : (
                        <>{!loading && <Nodata />}</>
                      )}
                    </Row>
                    <div className="d-flex align-items-center justify-content-between m-4">
                      <Paginate
                        page={meta?.page_count}
                        handlePageClick={(e) => handlePageChange(e)}
                        cp={page}
                      />

                      <div className="d-flex align-items-center justify-content-end flex-wrap gap-2 mb-3">
                        <h6 className="font12 font400 colordark mb-0">Show</h6>
                        <div style={{ width: "70px" }}>
                          <Form.Select
                            aria-label="Default select"
                            className="font14 font400"
                            value={perPage}
                            onChange={handlePerPageChange}
                          >
                            <option value={PERPAGE_TEN}>10</option>
                            <option value={PERPAGE_TWENTY}>20</option>
                            <option value={PERPAGE_THIRTY}>30</option>
                          </Form.Select>
                        </div>
                        <h5 className="font12 font400 colordark mb-0">
                          per page
                        </h5>
                      </div>
                    </div>
                  </Container>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
        <Footer />
        {loading && <Loader />}
      </div>

      <div className="d-block d-md-none maping-btn-bottom">
        <MobileCategoryItem />
      </div>
    </>
  );
};

export default CategoryItems;
