import React, { useEffect, useState } from "react";
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/navigation";
import {
  ArrowrightIcon,
  BackLongIcon,
  CrossIcon,
  ReviewCommentIcon,
  Star,
} from "../SvgIcons/allIcons";

import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../Commons/Footer";
import Header from "../Commons/Headers/Header";
import LenderHeader from "../Commons/Headers/LenderHeader";
import RenterHeader from "../Commons/Headers/RenterHeader";
import {
  getUserProfileDetails,
  userProductListing,
} from "../Services/APIServices";
import { convertUTCToLocal } from "../Services/helper";
import { imageBase } from "../globals/Config";
import { LENDER, RENTER } from "../globals/constants";
import useRole from "../hooks/useRole";

SwiperCore.use([Navigation]);

const ReviewsPage = () => {
  const role = useRole();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  let user_id = searchParam.get("user_id");
  const [details, setDetails] = useState();
  const [reviews, setReviews] = useState([]);
  const [product, setProduct] = useState([]);
  const [meta, setMeta] = useState([]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    userDetails();
    if (role == RENTER) {
      userPoducts();
    }
  }, []);

  const userDetails = async (page) => {
    const resp = await getUserProfileDetails(user_id, page);
    if (resp?.status == 200) {
      setDetails(resp?.data?.data);
      setReviews([...reviews, ...resp?.data?.reviews]);
      setMeta(resp?.data?.meta_data);
    }
  };

  const userPoducts = async () => {
    const resp = await userProductListing(user_id);
    if (resp?.status == 200) {
      setProduct(resp?.data?.data);
    }
  };

  const handlePageChange = () => {
    userDetails(meta?.current_page_no + 1);
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        <div className="d-none d-md-block">
          <div className="review pt47">
            <Container>
              <Row>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Card>
                    <Card.Body>
                      <Row className="align-items-center">
                        <Col md={6}>
                          <div className="user-info text-center">
                            <img
                              src={
                                details?.profile_pic
                                  ? imageBase + details?.profile_pic
                                  : require("../../src/assets/images/DummyImg.png")
                              }
                              alt="User"
                              className="rounded-circle"
                            />
                            <p className="font16 font700 mb-0">
                              {details?.full_name}
                            </p>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="rating_review text-center">
                            <p className="font16 font700">
                              {details?.review_count} <br />
                              <span className="font400">Reviews</span>
                            </p>

                            <p className="font16 font700 second_child">
                              <span className="d-flex align-items-center gap-1 justify-content-center">
                                {details?.average_rating?.toFixed(1)} <br />
                                <Star />
                              </span>
                              <span className="font400">Rating</span>
                            </p>
                            <p className="font16 font700">
                              {moment().diff(details?.created_on, "years") > 0
                                ? moment().diff(details?.created_on, "years")
                                : moment().diff(details?.created_on, "months") >
                                    0
                                  ? moment().diff(details?.created_on, "months")
                                  : moment().diff(details?.created_on, "days")}
                              <br />
                              <span className="font400">
                                {moment().diff(details?.created_on, "years") > 0
                                  ? "Years"
                                  : moment().diff(
                                        details?.created_on,
                                        "months"
                                      ) > 0
                                    ? "Months"
                                    : "Days"}{" "}
                                Lending
                              </span>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                  <div className="reviewer_info">
                    <div className="box">
                      <h4 className="font32 font700 pb49 mb-0">About me</h4>
                      {/* <p className="font16 font400 mb-0 pb71">
                      This is the Lender’s description from the profile
                    </p> */}
                    </div>
                    <div className="box position-relative pb29">
                      <h4 className="font700 pt32">
                        What {role == RENTER ? "renters" : "lender"} are saying
                        about {details?.full_name}
                      </h4>
                      <Swiper
                        navigation={true}
                        slidesPerView={2}
                        spaceBetween={50}
                        className="reviewSwiper"
                      >
                        {reviews?.length > 0 ? (
                          reviews?.slice(0, 5)?.map((item, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <Card>
                                  <Card.Body>
                                    <p className="font16 font400 first_child">
                                      {item?.review}
                                    </p>
                                    <div className="d-flex align-items-center gap-3">
                                      <img
                                        src={
                                          item?.created_by?.profile_pic
                                            ? imageBase +
                                              item?.created_by?.profile_pic
                                            : require("../../src/assets/images/DummyImg.png")
                                        }
                                        alt="user"
                                        className="rounded-circle"
                                      />
                                      <p className="font16 font700 mb-0">
                                        {item?.created_by?.full_name}
                                        <br />{" "}
                                        <span className="font400">
                                          {moment(
                                            convertUTCToLocal(item?.created_on)
                                          ).fromNow()}
                                        </span>
                                      </p>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </SwiperSlide>
                            );
                          })
                        ) : (
                          <p className="text-center">No reviews found</p>
                        )}
                      </Swiper>
                      {meta?.total_results > 5 && (
                        <span
                          className="font12 font700 w-80 btn btn-outline d-flex align-items-center justify-content-center gap-2"
                          onClick={(e) => {
                            e.preventDefault();
                            handleShow();
                          }}
                        >
                          <ReviewCommentIcon /> Show more reviews
                        </span>
                      )}
                    </div>
                    {role == RENTER && (
                      <div className="box position-relative pb29 border-0">
                        <h4 className="font700 pt32">
                          {details?.full_name} Listings
                        </h4>
                        <Swiper
                          navigation={true}
                          slidesPerView={2}
                          spaceBetween={50}
                          className="reviewListingSwiper"
                        >
                          {product?.length > 0 ? (
                            product?.map((item, index) => {
                              return (
                                <SwiperSlide key={index}>
                                  <Card>
                                    <Card.Img
                                      variant="top"
                                      src={
                                        item?.product_image?.images
                                          ? imageBase +
                                            item?.product_image?.images
                                          : require("../assets/images/product1.jpg")
                                      }
                                      onClick={() =>
                                        navigate(
                                          `/renter/renter-details?id=${item.id}`
                                        )
                                      }
                                    />
                                    <Card.Body>
                                      <h5 className="heade16">{item?.title}</h5>
                                      <span
                                        className="btn btn-shop ps-0"
                                        onClick={() =>
                                          navigate(
                                            `/renter/renter-details?id=${item.id}`
                                          )
                                        }
                                      >
                                        See More Details <ArrowrightIcon />
                                      </span>
                                    </Card.Body>
                                  </Card>
                                </SwiperSlide>
                              );
                            })
                          ) : (
                            <p className="text-center">No products found</p>
                          )}
                        </Swiper>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="d-block d-md-none">
          <div className="review pt47">
            <Container>
              <Row>
                <Col xs={12} className="mb-3">
                  <span className="ps-3" onClick={() => navigate(-1)}>
                    <BackLongIcon />
                  </span>
                </Col>
                <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                  <Card>
                    <Card.Body>
                      <Row className="align-items-center">
                        <Col xs={6}>
                          <div className="user-info text-center">
                            <img
                              src={
                                details?.profile_pic
                                  ? imageBase + details?.profile_pic
                                  : require("../../src/assets/images/DummyImg.png")
                              }
                              alt="User"
                              className="rounded-circle"
                            />
                            <p className="font16 font700 mb-0">
                              {details?.full_name}
                            </p>
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="rating_review text-center">
                            <p className="font16 font700">
                              {details?.review_count} <br />
                              <span className="font400">Reviews</span>
                            </p>

                            <p className="font16 font700 second_child">
                              <span className="d-flex align-items-center gap-1 justify-content-center">
                                {details?.average_rating?.toFixed(1)} <br />
                                <Star />
                              </span>
                              <span className="font400">Rating</span>
                            </p>
                            <p className="font16 font700">
                              {moment().diff(details?.created_on, "years") > 0
                                ? moment().diff(details?.created_on, "years")
                                : moment().diff(details?.created_on, "months") >
                                    0
                                  ? moment().diff(details?.created_on, "months")
                                  : moment().diff(details?.created_on, "days")}
                              <br />
                              <span className="font400">
                                {moment().diff(details?.created_on, "years") > 0
                                  ? "Years"
                                  : moment().diff(
                                        details?.created_on,
                                        "months"
                                      ) > 0
                                    ? "Months"
                                    : "Days"}{" "}
                                Lending
                              </span>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                  <div className="reviewer_info">
                    <div className="box">
                      <p className="font16 font400 mb-0 pb-4">
                        This is the Renter’s description from the profile
                      </p>
                    </div>
                    <div className="box position-relative pb29">
                      <h4 className="font700 pt32">
                        What {role == RENTER ? "renters" : "lender"} are saying
                        about {details?.full_name}
                      </h4>
                      <Swiper
                        navigation={true}
                        slidesPerView={1}
                        spaceBetween={50}
                        className="reviewSwiper"
                      >
                        {reviews?.length > 0 ? (
                          reviews?.slice(0, 5)?.map((item, index) => {
                            return (
                              <SwiperSlide key={index}>
                                <Card>
                                  <Card.Body>
                                    <p className="font16 font400 first_child">
                                      {item?.review}
                                    </p>
                                    <div className="d-flex align-items-center gap-3">
                                      <img
                                        src={
                                          item?.created_by?.profile_pic
                                            ? imageBase +
                                              item?.created_by?.profile_pic
                                            : require("../../src/assets/images/DummyImg.png")
                                        }
                                        alt="user"
                                        className="rounded-circle"
                                      />
                                      <p className="font16 font700 mb-0">
                                        {item?.created_by?.full_name}
                                        <br />{" "}
                                        <span className="font400">
                                          {moment(
                                            convertUTCToLocal(item?.created_on)
                                          ).fromNow()}
                                        </span>
                                      </p>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </SwiperSlide>
                            );
                          })
                        ) : (
                          <p className="text-center">No reviews found</p>
                        )}
                      </Swiper>
                      {meta?.total_results > 5 && (
                        <span
                          className="font12 font700 w-80 btn btn-outline d-flex align-items-center justify-content-center gap-2"
                          onClick={(e) => {
                            e.preventDefault();
                            handleShow();
                          }}
                        >
                          <ReviewCommentIcon /> Show more reviews
                        </span>
                      )}
                    </div>
                    {role == RENTER && (
                      <div className="box position-relative pb29 border-0">
                        <h4 className="font700 pt32">
                          {details?.full_name} Listings
                        </h4>
                        <Swiper
                          navigation={true}
                          slidesPerView={1}
                          spaceBetween={50}
                          className="reviewListingSwiper"
                        >
                          {product?.length > 0 ? (
                            product?.map((item, index) => {
                              return (
                                <SwiperSlide key={index}>
                                  <Card>
                                    <Card.Img
                                      variant="top"
                                      src={
                                        item?.product_image?.images
                                          ? imageBase +
                                            item?.product_image?.images
                                          : require("../assets/images/product1.jpg")
                                      }
                                      onClick={() =>
                                        navigate(
                                          `/renter/renter-details?id=${item.id}`
                                        )
                                      }
                                    />
                                    <Card.Body>
                                      <h5 className="heade16">{item?.title}</h5>
                                      <span
                                        className="btn btn-shop ps-0"
                                        onClick={() =>
                                          navigate(
                                            `/renter/renter-details?id=${item.id}`
                                          )
                                        }
                                      >
                                        See More Details <ArrowrightIcon />
                                      </span>
                                    </Card.Body>
                                  </Card>
                                </SwiperSlide>
                              );
                            })
                          ) : (
                            <p className="text-center">No products found</p>
                          )}
                        </Swiper>
                      </div>
                    )}
                  </div>
                  {/* <Link className="btn btn-default w-100 d-block text-center">
                    <span className="me-2">
                      <ReviewCommentIcon />
                    </span>
                    Load more Reviews
                  </Link> */}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Footer />

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delete-items"
      >
        <div className="middle-area text-start">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4 className="text-start">{meta?.total_results} Review</h4>
            <span onClick={handleClose}>
              <CrossIcon />
            </span>
          </div>
          <Modal.Body className="px-0 pb-0">
            <div className="modal-review-list">
              <ul className="review-list">
                {reviews?.map((item, index) => {
                  return (
                    <li className="box pb-5 mb-4" key={index}>
                      <div className="review-prof-card d-flex align-items-center mb-3">
                        <div className="revew-prf-img w-20 me-3">
                          <img
                            src={
                              item?.created_by?.profile_pic
                                ? imageBase + item?.created_by?.profile_pic
                                : require("../../src/assets/images/DummyImg.png")
                            }
                            alt="user"
                            className="rounded-circle revew-prf-img"
                          />
                        </div>
                        <div className="review-prof-dis w-80">
                          <h4 className="font16 font700 mb-1">
                            {item?.created_by?.full_name}
                          </h4>
                          <p className="font14 font400 mb-0">
                            reviews {moment(reviews?.created_on).fromNow()}
                          </p>
                        </div>
                      </div>
                      <div className="review-cmmnt">
                        <p className="font16 mb-0">{item?.review}</p>
                      </div>
                    </li>
                  );
                })}

                {meta?.page_count > 1 &&
                  meta?.total_results > reviews?.length && (
                    <span
                      className="font12 font700 w-80 btn btn-outline d-flex align-items-center justify-content-center gap-2"
                      onClick={(e) => {
                        e.preventDefault();
                        handlePageChange();
                      }}
                    >
                      <ReviewCommentIcon /> Show more reviews
                    </span>
                  )}
              </ul>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ReviewsPage;
