import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  WhiteBagIcon,
  WhiteMsgIcon,
  WhiteProfileIcon,
  WhiteSaveIcon,
  WhiteSearchIcon,
} from "../../SvgIcons/allIcons";
import { Selected } from "../../App";

const MobileRenterFooter = () => {
  const location = useLocation();
  const { pathname } = location;
  const { setIsSelected } = useContext(Selected);

  return (
    <div className="mobile-footer d-none">
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <Link
            className={
              pathname == "/catagory-mapping"
                ? "text-center active"
                : "text-center"
            }
            to="/catagory-mapping"
          >
            <WhiteSearchIcon />
            <p className="font400 font8 mb-0">Explore</p>
          </Link>
          <Link
            className={
              pathname == "/renter/saved-items"
                ? "text-center active"
                : "text-center"
            }
            to={"/renter/saved-items"}
          >
            <WhiteSaveIcon />
            <p className="font400 font8 mb-0">Saved items</p>
          </Link>
          <Link
            className={
              pathname == "/renter/my-rental"
                ? "text-center active"
                : "text-center"
            }
            to={"/renter/my-rental"}
          >
            <div className="bagwhite-icon">
              <WhiteBagIcon />
            </div>
            <p className="font400 font8 mb-0">My Rentals</p>
          </Link>
          <Link
            className={
              pathname == "/message" || pathname == "/renter/message"
                ? "text-center active"
                : "text-center"
            }
            to={"/message"}
          >
            <WhiteMsgIcon />
            <p className="font400 font8 mb-0">Messages</p>
          </Link>
          <Link
            className={
              pathname == "/renter/profile"
                ? "text-center active"
                : "text-center"
            }
            to={"/renter/profile"}
            onClick={() => {
              setIsSelected(false);
            }}
          >
            <WhiteProfileIcon />
            <p className="font400 font8 mb-0">My Profile</p>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default MobileRenterFooter;
