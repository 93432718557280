import React, { useState } from "react";
import { Col, Container, Modal, Nav, Row, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  QuestionIcon,
  WhiteProfileIcon,
  WhiteSaveIcon,
  WhiteSearchIcon,
} from "../../SvgIcons/allIcons";
import Login from "../../Authentication/Login";
import SignUp from "../../Authentication/SignUp";
import ForgotPassword from "../../Authentication/ForgotPassword";

const MobileFooter = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [activeTab, setActiveTab] = useState("login");
  const handleShow = () => setShow(true);

  const [showForgot, setshowForgot] = useState(false);
  const handleCloseTwo = () => setshowForgot(false);
  const handleShowTwo = () => setshowForgot(true);

  const handleBackButton = () => {
    handleCloseTwo();
    handleShow();
  };

  const { host, hostname, href, origin, pathname, port, protocol, searchurl } = window.location;

  let currentpage = "";

  if (pathname == "/lender/home") {
    currentpage = "SHAXIFY-Lender";

  } else if (pathname == "/renter/category-map-view") {
    currentpage = "marketplace";
  } else if (pathname == "/lender/how-it-works") {
    currentpage = "how-it-works";
  } else if (pathname == "/message") {
    currentpage = "message";
  } else if (pathname == "/lender/listing") {
    currentpage = "item_list";
  }
 

  return (
    <div className="mobile-footer d-none">
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <Link  className={(pathname == "/catagory-mapping") ? "text-center active" : "text-center"}  to={"/catagory-mapping"}>
            <WhiteSearchIcon />
            <p className="font400 font8 mb-0 active">Explore</p>
          </Link>
          <Link  className={(pathname == "/sustainbility") ? "text-center active" : "text-center"}  to={"/sustainbility"}>
            <QuestionIcon />
            <p className="font400 font8 mb-0">How does it work?</p>
          </Link>
          <Link  className={(pathname == "") ? "text-center active" : "text-center"}  to={""} onClick={handleShow}>
            <WhiteSaveIcon />
            <p className="font400 font8 mb-0">Saved items</p>
          </Link>
          <Link  className={(pathname == "") ? "text-center active" : "text-center"}  to={""} onClick={handleShow}>
            <WhiteProfileIcon />
            <p className="font400 font8 mb-0">My Profile</p>
          </Link>
        </div>
      </Container>

      {/**************************Login Modal***************/}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="darkbg p-0">
          <div className="header-area">
            <Link>
              <img src={require("../../assets/images/logo.png")} alt="" />
            </Link>
            <Modal.Title>Welcome</Modal.Title>
            <Tab.Container
              id="left-tabs-example"
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="register">I’m a new user</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="login">I have an account</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <div className="middle-area">
                    <Tab.Content>
                      {/******************************Login Tab********************************/}
                      <Tab.Pane eventKey="login">
                        <Login
                          handleClose={handleClose}
                          handleShowTwo={handleShowTwo}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="register">
                        {/******************************Signup Tab********************************/}
                        <SignUp setActiveTab={setActiveTab} />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>

      {/* Forgot Password Modal */}

      <Modal
        show={showForgot}
        onHide={handleCloseTwo}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="darkbg p-0">
          <div className="header-area">
            <Link>
              <img src={require("../../assets/images/logo.png")} alt="" />
            </Link>
            <Modal.Title>Welcome</Modal.Title>
          </div>
          <div className="middle-area">
            <ForgotPassword handleBackButton={handleBackButton} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MobileFooter;
