import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { AddCard, updateAddress } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { BackIcon } from "../../SvgIcons/allIcons";
import { LENDER, RENTER } from "../../globals/constants";
import { acceptOnlyNum, restrictAlpha } from "../../utils/CommonFunction";
import useRole from "../../hooks/useRole";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Header from "../../Commons/Headers/Header";

const AddNewCard = () => {
  const navigate = useNavigate();
  const [id, setId] = useState();
  const role = useRole();
  const userRole = useSelector((state) => state?.Login?.userDetails?.role_id);

  // API CODE
  const validationSchema = Yup.object({
    card_holder_name: Yup.string().required("This field is required").trim(),
    card_number: Yup.string().required("This field is required."),
    expiry_date: Yup.string().required("This field is required."),
    cvv: Yup.string()
      .required("This field is required.")
      .min(3, "Minimum 3 digit required"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    isSubmitting,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: {
      card_holder_name: "",
      card_number: "",
      expiry_date: "",
      cvv: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);

      let body = {
        card_holder_name: values.card_holder_name,
        card_number: values.card_number,
        expiry_date: values.expiry_date,
        cvv: values.cvv,
      };

      let formData = new FormData();
      Object.keys(body).forEach((data, key) => {
        formData.append(data, body[data]);
      });

      if (id) {
        updateAddress(formData).then((resp) => {
          setSubmitting(false);
          if (resp?.status === 200) {
            resetForm();
            navigate("/renter/profile?atab=fourth");
          } else {
            showNotification("danger", resp?.data?.message);
          }
          setId("");
        });
      } else {
        AddCard(formData).then((resp) => {
          setSubmitting(false);
          if (resp?.status === 200) {
            resetForm();
            navigate("/renter/profile?atab=fourth");
          } else {
            showNotification("danger", resp?.data?.message);
          }
        });
      }
    },
  });

  const handleCardDisplay = () => {
    const rawText = [...values?.card_number.split(" ").join("")]; // Remove old space
    const creditCard = []; // Create card as array
    rawText.forEach((t, i) => {
      if (i % 4 === 0 && i !== 0) creditCard.push(" "); // Add space
      creditCard.push(t);
    });
    return creditCard.join(""); // Transform card array to string
  };

  const handleExpiryDate = (event) => {
    if (event.key == "Backspace") {
      setFieldValue("expiry_date", event.target.value);
    } else {
      setFieldValue(
        "expiry_date",
        event.target.value
          .replace(
            /^([1-9]\/|[2-9])$/g,
            "0$1/" // 3 > 03/
          )
          .replace(
            /^(0[1-9]|1[0-2])$/g,
            "$1/" // 11 > 11/
          )
          .replace(
            /^([0-1])([3-9])$/g,
            "0$1/$2" // 13 > 01/3
          )
          .replace(
            /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
            "$1/$2" // 141 > 01/41
          )
          .replace(
            /^([0]+)\/|[0]+$/g,
            "0" // 0/ > 0 and 00 > 0
          )
          .replace(
            /[^\d\/]|^[\/]*$/g,
            "" // To allow only digits and `/`
          )
          .replace(
            /\/\//g,
            "/" // Prevent entering more than 1 `/`
          )
      );
    }
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}

      <div className="addresmodal">
        <div className="p-4">
          <Row>
            <p className="head16 d-flex align-items-center gap-3">
              <Link
                className="d-block d-lg-none d-sm-none"
                onClick={(e) => {
                  e.preventDefault();
                  if (userRole == RENTER) {
                    navigate("/renter/profile?atab=fourth");
                  } else {
                    navigate("/lender/profile?atab=fourth");
                  }
                }}
              >
                <BackIcon />
              </Link>
              Add new card
            </p>
            <Col lg={12}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Card Holder Name <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="card_holder_name"
                    placeholder="Enter card holder name"
                    onChange={handleChange}
                    value={values?.card_holder_name}
                    onBlur={handleBlur}
                  />
                  <span className="text-start required">
                    {touched.card_holder_name && errors.card_holder_name}{" "}
                  </span>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Card number <span className="error">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text "
                    name="card_number"
                    placeholder="Enter card number "
                    onChange={handleChange}
                    value={handleCardDisplay()}
                    onBlur={handleBlur}
                    maxLength={19}
                    onKeyPress={acceptOnlyNum}
                  />

                  <span className="text-start required">
                    {touched.card_number && errors.card_number}
                  </span>
                </Form.Group>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        Expiry date <span className="error">*</span>
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="expiry_date"
                        onChange={handleChange}
                        onKeyUp={handleExpiryDate}
                        value={values?.expiry_date}
                        onBlur={handleBlur}
                        placeholder="MM/YY"
                        maxLength={5}
                      />

                      <span className="text-start required">
                        {touched.expiry_date && errors.expiry_date}{" "}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        Security Code <span className="error">*</span>
                      </Form.Label>

                      <Form.Control
                        type="password"
                        name="cvv"
                        placeholder="Enter security code "
                        onChange={handleChange}
                        value={values?.cvv}
                        onBlur={handleBlur}
                        maxLength={4}
                        min={3}
                        onKeyPress={restrictAlpha}
                      />

                      <span className="text-start required">
                        {touched.cvv && errors.cvv}{" "}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  className="btn btn-theme font700 mb-0 w-100"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Add
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AddNewCard;
