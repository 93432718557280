/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Footer from "../../Commons/Footer";
import HomeFooter from "../../Commons/HomeFooter";
import {
  BackGreenIcon,
  ParkGreenIcon,
  SearchIcon,
} from "../../SvgIcons/allIcons";
import useDocumentTitle from "../../globals/useDocumentTitle";
import { HOW_IT_WORKS, LENDER, RENTER } from "../../globals/constants";
import { getStaticPages } from "../../Services/APIServices";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Header from "../../Commons/Headers/Header";
import useRole from "../../hooks/useRole";

const RenterSustainbility = () => {
  const role = useRole();
  const [howitWorks, setHowItWorks] = useState("");
  const userDetails = useSelector((state) => state?.Login?.userDetails);
  useDocumentTitle("SHAXIFY - How it works");

  useEffect(() => {
    howItWorks();
  }, []);

  const howItWorks = () => {
    getStaticPages(HOW_IT_WORKS).then((resp) => {
      if (resp?.data?.status === 200) {
        setHowItWorks(resp?.data?.data);
      }
    });
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        <div className="top-head top46 bottom20 d-none d-lg-block d-nd-block d-xl-block d-xxl-block">
          <Container>
            <div className="row">
              <Col>
                <h4 className="font24">Sustainability</h4>
              </Col>
            </div>
          </Container>
        </div>

        <div className="how-it-work pt-90 pb-93">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={6} sm={12}>
                <div className="pe-lg-5 pe-0 me-lg-5 me-0">
                  {/* <h4 className="text-start">{howitWorks?.title}</h4> */}
                  {/* <h2>Shaxify is transforming the rental market</h2> */}
                  <h2>{howitWorks?.title}</h2>

                  <p
                    dangerouslySetInnerHTML={{ __html: howitWorks?.content }}
                  ></p>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <img
                  src={require("../../assets/images/how-it-work-img.png")}
                  alt="img"
                />
              </Col>
            </Row>
            <div className="boxes">
              <Row>
                <Col lg={4}>
                  <span className="search">
                    <SearchIcon />
                  </span>
                  <h4>Find an item you need</h4>
                </Col>
                <Col lg={4}>
                  <span>
                    <ParkGreenIcon />
                  </span>
                  <h4>Get it shipped to you</h4>
                </Col>
                <Col lg={4}>
                  <span>
                    <BackGreenIcon />
                  </span>
                  <h4>Return after you’re done</h4>
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <div className="pt32 pb55">
          <Container>
            <Row>
              <Col lg={6} sm={12} className="mx-auto">
                <h2 className="hd-top-card">
                  With a shared economy you are giving each product a new life
                </h2>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="pt14 pb31 backgroundgray">
          <Container>
            <Row>
              <Col lg={12}>
                <h3 className="d-none d-lg-block">
                  Shaxify principle is investing in sustainability. With an
                  consistent increase in population we need to do our best in
                  order to leave the planet better than how we found it. That is
                  why we firmly believe in a shared economy and we invest in
                  delivering our items in a sustainable manner because it is
                  good for the planet, our customers and the communities we touch
                </h3>
                <h6 className="d-block d-lg-none font16">
                  Shaxify principle is investing in sustainability. With an
                  consistent increase in population we need to do our best in
                  order to leave the planet better than how we found it. That is
                  why we firmly believe in a shared economy and we invest in
                  delivering our items in a sustainable manner because it is
                  good for the planet, our customers and the communities we touch
                </h6>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* <Footer /> */}
      {userDetails ? <Footer /> : <HomeFooter />}
    </>
  );
};

export default RenterSustainbility;
