/*
@copyright : Ozvid Technologies Pvt. Ltd. < www.ozvid.com >
@author     : Shiv Charan Panjeta < shiv@ozvid.com >
 
All Rights Reserved.
Proprietary and confidential :  All information contained herein is, and remains
the property of Ozvid Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
// To get Only Digit

import showNotification from "../Services/NotificationService";

export const restrictAlpha = (e) => {
  const re = /[0-9A-F:]+/g;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
};

export const passwordValidation = (password) => {
  var regExp = /^(?=.*\p{Ll})(?=.*\p{Lu})[\p{L}\d@#$!%*?&]{8,15}$/gmu;
  if (regExp.test(password)) return false;
  else return false;
};

export const phoneValidation = (phone) => {
  var regExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  if (regExp.test(phone)) return true;
  else return false;
};

export const restrictSpecial = (e) => {
  const re = /^[^*|\":<>[\]{}`\\()';@&$!#%^]+$/;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
};

export const validateEmail = (e) => {
  const re = /r'^[a-zA-Z0-9_.+-]+[@]\w+[.]\w{2,3}$'/;
  if (!re.test(e.key)) {
    return false;
  } else return true;
};

export const preventSpace = (e) => {
  if (e.keyCode === 32) {
    e.preventDefault();
  }
};

export const NumberWithdecimal = (evt) => {
  if (
    (evt.charCode >= 48 && evt.charCode <= 57) ||
    evt.charCode == 46 ||
    evt.charCode == 0
  ) {
    // if (data.indexOf(".") > -1) {
    //   if (evt.charCode == 46) evt.preventDefault();
    // }
  } else evt.preventDefault();
};

export const truncate = (str, n) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const imageValidator = (data) => {
  const fileExtension = data.type.split("/")[1];
  const validExtensions = ["png", "jpeg", "jpg", "webp"];
  if (!validExtensions.includes(fileExtension)) {
    showNotification("danger", "Please select png, jpeg, jpg, webp format");
    return false;
  }
  return true;
};
export const acceptOnlyNum = (e) => {
  const re = /[0-9]+/g;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
};