import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { ShowCardList } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import Footer from "../../Commons/Footer";
import { BackIcon } from "../../SvgIcons/allIcons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { checkoutPayment } from "../../Redux/CheckoutAddressSlice";
import { LENDER, RENTER } from "../../globals/constants";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Header from "../../Commons/Headers/Header";
import useRole from "../../hooks/useRole";
import Loader from "../../Commons/Loader/Loader";

const PaymentModal = () => {
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const role = useRole();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cardList, setCardList] = useState([]);
  const id = new URLSearchParams(location?.search).get("id");
  const from = new URLSearchParams(location?.search).get("from");
  const to = new URLSearchParams(location?.search).get("to");
  const pincode = new URLSearchParams(location?.search).get("pincode");
  const [selectedCard, setSelectedCard] = useState("");
  useEffect(() => {
    AllCardList();
  }, []);

  const AllCardList = () => {
    setLoader(true);
    ShowCardList().then((res) => {
      setLoader(false);
      if (res?.status == 200) {
        setCardList(res?.data?.data);
      }
    });
  };

  const handleCardChange = (data) => {
    setSelectedCard(data);
  };

  const handleCardAdd = () => {
    if (selectedCard?.id || selectedCard === 0) {
    } else {
      showNotification("danger", "Select payment option to proceed");
    }
  };

  const handleNavigate = (cod = 0) => {
    if (selectedCard?.id || selectedCard === 0) {
      dispatch(checkoutPayment(selectedCard));
      navigate(
        `/renter/checkout?id=${id}&from=${from}&to=${to}&pincode=${pincode}&cod=${cod}`
      );
    } else {
      showNotification("danger", "Select payment option to proceed");
    }
  };

  const handleDispatch = () => {
    dispatch(checkoutPayment(selectedCard));
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="delete-items">
        <div className="middle-area text-start">
          <div className="p-4">
            <Row>
              <p className="head16 d-flex align-items-center gap-3">
                <Link
                  to={`/renter/checkout?id=${id}&from=${from}&to=${to}&pincode=${pincode}`}
                  onClick={() => handleDispatch()}
                  className="d-block d-md-none"
                >
                  <BackIcon />
                </Link>
                Select a payment method
              </p>
              <Col sm={12} className="mx-auto">
                {cardList &&
                  cardList.map((data, index) => {
                    return (
                      <Card
                        className="addresscard shadow-none rounded-0 mb-3 h-auto"
                        key={index}
                      >
                        <div className="d-flex align-items-start gap-3 w-100">
                          <div className="custom-checkbox">
                            <Form.Check
                              type="radio"
                              name="adress"
                              id={`payment-${data?.id}`}
                              checked={data?.id === selectedCard?.id}
                              onChange={() => handleCardChange(data)}
                            />
                          </div>
                          <label htmlFor={`payment-${data?.id}`}>
                            <div>
                              <p className="mb-2 font16 font700 colordark">
                                {data?.card_holder_name}
                              </p>
                              <p className="mb-2 font400 colordark">
                                XXXX-XXXX-XXXX-
                                {data?.ac_no?.substr(data?.ac_no?.length - 4)}
                              </p>
                              <p className="mb-0 font400 colordark">
                                Exp.{" "}
                                {moment(data?.exp_month, "MM").format("MM")}/
                                {moment(data?.exp_year, "YYYY").format("YY")}
                              </p>
                            </div>
                          </label>
                        </div>
                      </Card>
                    );
                  })}
                <Card className="addresscard shadow-none rounded-0 mb-3 h-auto">
                  <div className="d-flex align-items-start gap-3 w-100">
                    <div className="custom-checkbox">
                      <Form.Check
                        type="radio"
                        name="adress"
                        id={"payment-0"}
                        checked={0 === selectedCard}
                        onChange={() => handleCardChange(0)}
                      />
                    </div>
                    <label htmlFor={"payment-0"}>
                      <p className="font400 font16">
                        Pay in cash when item is delivered.
                      </p>
                    </label>
                  </div>
                </Card>
                <div className="text-decoration-none">
                  <Card
                    className="addresscard addadress shadow-none rounded-0 h-auto d-flex align-items-center justify-content-center"
                    htmlFor="addNew"
                    onClick={() =>
                      navigate(
                        `/renter/add-card-mobile?id=${id}&from=${from}&to=${to}&pincode=${pincode}`
                      )
                    }
                  >
                    <p className="mb-0 font14 font700" id="addNew">
                      Add new card
                    </p>
                  </Card>
                </div>
              </Col>
            </Row>
            {selectedCard === 0 ? (
              <Button
                className="btn btn-theme font700 mb-0 w-100"
                onClick={() => handleNavigate(1)}
              >
                Cash
              </Button>
            ) : (
              <Button
                className="btn btn-theme font700 mb-0 w-100"
                onClick={() => handleNavigate()}
              >
                Select this card
              </Button>
            )}
          </div>
        </div>
      </div>
      <Footer />
      {loader && <Loader />}
    </>
  );
};

export default PaymentModal;
