import { BackGreenIcon, BagIconLight, CalendarIcon, ParkGreenIcon, SearchIcon, Star, UserIconLight } from '../SvgIcons/allIcons';
export default [
    {
        original: require('../assets/images/product1.jpg'),
        thumbnail: require('../assets/images/product1.jpg')
    },
    {
        original: require('../assets/images/product1.jpg'),
        thumbnail: require('../assets/images/product1.jpg')
    },
    {
        original: require('../assets/images/product1.jpg'),
        thumbnail: require('../assets/images/product1.jpg')
    },
    {
        original: require('../assets/images/product1.jpg'),
        thumbnail: require('../assets/images/product1.jpg')
    }
];
