import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import { AddAddress, updateAddress } from "../../Services/APIServices";
import showNotification from "../../Services/NotificationService";
import { BackIcon } from "../../SvgIcons/allIcons";
import { LENDER, RENTER } from "../../globals/constants";
import useRole from "../../hooks/useRole";
import Loader from "../../Commons/Loader/Loader";

const AddNewAddress = () => {
  const navigate = useNavigate();
  const role = useRole();
  const location = useLocation();
  const { state: locationData } = location;
  const [id, setId] = useState("");

  useEffect(() => {
    if (locationData) {
      setId(locationData?.id);
      setValues({
        address1: locationData.address1,
        address2: locationData.address2,
        city: locationData.city,
        state: locationData.state,
        country: locationData.country,
        zipcode: locationData.zipcode,
        latitude: locationData.latitude,
        longitude: locationData.longitude,
      });
    }
  }, []);

  const validationSchema = Yup.object({
    address1: Yup.string().required("This field is required").trim(),
    state: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required"),
    country: Yup.string().required("This field is required."),
    zipcode: Yup.string()
      .matches(/^[0-9]{5}/, "Enter a correct ZipCode it should be number")
      .min(6, "Minimum 6 digit required.")
      .max(10, "Maximum 10 digit required.")
      .required("This field is required."),
  });
  const [loading, setLoading] = useState(false);
  const userRole = useSelector((state) => state?.Login?.userDetails?.role_id);
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    handleBlur,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      address1: "",
      address2: "",
      state: "",
      city: "",
      country: "",
      zipcode: "",
      latitude: "",
      longitude: "",
    },
    validationSchema,
    onSubmit(values, { setSubmitting, resetForm }) {
      setSubmitting(true);
      setLoading(true);
      let body = {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.zipcode,
        latitude: values.latitude ? values.latitude : 0,
        longitude: values.longitude ? values.longitude : 0,
      };

      let formData = new FormData();
      Object.keys(body).forEach((data) => {
        formData.append(data, body[data]);
      });
      if (id) {
        formData.append("address_id", id);
      }

      if (id) {
        updateAddress(formData).then((resp) => {
          setSubmitting(false);
          setLoading(false);
          if (resp?.status === 200) {
            resetForm();
            if (userRole == RENTER) {
              navigate("/renter/profile?atab=third");
            } else {
              navigate("/lender/profile?atab=third");
            }
          } else {
            showNotification("danger", resp?.data?.message);
          }
          setId("");
        });
      } else {
        AddAddress(formData).then((resp) => {
          setLoading(false);
          setSubmitting(false);
          if (resp?.status === 200) {
            if (userRole == RENTER) {
              navigate("/renter/profile?atab=third");
            } else {
              navigate("/lender/profile?atab=third");
            }
            resetForm();
          } else {
            showNotification("danger", resp?.data?.message);
          }
        });
      }
    },
  });

  const handlePlaces = (place) => {
    place?.address_components?.map((item) => {
      if (item?.types?.includes("country")) {
        setFieldValue("country", item?.long_name);
      }
      if (item?.types?.includes("administrative_area_level_1")) {
        setFieldValue("state", item?.long_name);
      }
      if (item?.types?.includes("administrative_area_level_3")) {
        setFieldValue("city", item?.long_name);
      }
      if (item?.types?.includes("postal_code")) {
        setFieldValue("zipcode", item?.long_name);
      }
    });
    setFieldValue("address1", place?.formatted_address);
    setFieldValue("latitude", place?.geometry?.location?.lat());
    setFieldValue("longitude", place?.geometry?.location?.lng());
  };

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="addresmodal">
        <div className="p-4 main-box">
          <Row>
            <p className="head16 d-flex align-items-center gap-3">
              <Link
                className="d-block d-lg-none d-sm-none"
                to={
                  userRole == RENTER
                    ? "/renter/profile?atab=third"
                    : "/lender/profile?atab=third"
                }
              >
                <BackIcon />
              </Link>
              {id ? " Update address" : "Add new address"}
            </p>
            <Col lg={12}>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Address 1 <span className="error">*</span>
                  </Form.Label>
                  <Autocomplete
                    apiKey={process.env.REACT_APP_MAP_KEY}
                    placeholder="Address 1"
                    className="form-control"
                    name="address1"
                    value={values?.address1}
                    options={{
                      types: ["address"],
                      componentRestrictions: { country: "in" },
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onPlaceSelected={(place) => {
                      handlePlaces(place);
                    }}
                  />

                  <span className="text-start required">
                    {touched.address1 && errors.address1}{" "}
                  </span>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="font700 font12 secondaycolor">
                    Address 2{/* <span className="error">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    placeholder="Address 2"
                    onChange={handleChange}
                    value={values?.address2}
                    onBlur={handleBlur}
                  />
                </Form.Group>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label className="font700 font12 secondaycolor">
                      City <span className="error">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      placeholder="City"
                      onChange={handleChange}
                      value={values?.city}
                      onBlur={handleBlur}
                    />
                    <span className="text-start required">
                      {touched.city && errors.city}
                    </span>
                  </Form.Group>
                </Col>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        State <span className="error">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="State"
                        name="state"
                        value={values?.state}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-start required">
                        {touched.state && errors.state}{" "}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        Country <span className="error">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Country"
                        name="country"
                        value={values?.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <span className="text-start required">
                        {touched.country && errors.country}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label className="font700 font12 secondaycolor">
                        Zip Code <span className="error">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="zipcode"
                        maxLength="10"
                        placeholder="Zip Code"
                        onChange={handleChange}
                        value={values?.zipcode}
                        onBlur={handleBlur}
                      />
                      <span className="text-start required">
                        {touched.zipcode && errors.zipcode}{" "}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  className="btn btn-theme font700 mb-0 w-100"
                  onClick={handleSubmit}
                >
                  {id ? "Update" : "Add new address"}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
      {loading && <Loader />}
    </>
  );
};

export default AddNewAddress;
