/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../Commons/Footer";
import HomeFooter from "../../Commons/HomeFooter";
import { LENDER, RENTER } from "../../globals/constants";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import Header from "../../Commons/Headers/Header";
import useRole from "../../hooks/useRole";

const RenterExchangeRate = () => {
  const role = useRole();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.Login?.userDetails);
  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}

      <div className="mainbox_outer">
        <div className="top-head">
          <Container>
            <div className="row">
              <Col>
                <h4 className="font48">Exchange Rate</h4>
              </Col>
            </div>
          </Container>
        </div>
        <div className="rate-area">
          <Container>
            <Row>
              <Col lg={5} sm={12} className="mx-auto">
                <h1 className="font48 font900">
                  Use your local <br /> currency and leave <br /> the rates to
                  us
                </h1>
              </Col>
              <Col lg={9} sm={12} className="mx-auto">
                <h4 className="font24 font700">
                  Shaxify currency converter allows you to pay for your rentals
                  in your local currency without having to worry about any
                  hidden fees
                </h4>
              </Col>
            </Row>
          </Container>

          <div className="bg-white ptb-38 mt-53">
            <Container>
              <div className="boxes">
                <Row>
                  <Col lg={4}>
                    <span className="search"></span>
                    <h4>
                      No need to google <br /> exchange rates
                    </h4>
                  </Col>
                  <Col lg={4}>
                    <span></span>
                    <h4>
                      Same rate used <br /> throughout all rental <br /> process
                    </h4>
                  </Col>
                  <Col lg={4}>
                    <span></span>
                    <h4>No hidden fees</h4>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
        <div className="rate-list backgroundgray">
          <Container>
            <Row>
              <Col lg={8}>
                <ul>
                  <li>
                    At checkout, you will see your rental summary in your local
                    currency
                  </li>
                  <li>
                    Upon submitting your rental, you pay in your local currency
                    and lock your exchange rate.
                  </li>
                  <li>
                    You can always opt out of Shaxify converter and pay in the
                    lenders currency
                  </li>
                  <li>
                    There are no hidden fees from Shaxify. If you get any
                    transactions even for payments in local currenccy, please
                    contact your bank.
                  </li>
                </ul>
              </Col>
              <Col lg={4}>
                <div className="exchange-img">
                  <img
                    src={require("../../assets/images/exchange-rate-img.png")}
                    alt="img"
                    className="float-end"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {userDetails ? <Footer /> : <HomeFooter />}
    </>
  );
};

export default RenterExchangeRate;
