/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import React from "react";
import Select from "react-select";

function Dropdown({
  options,
  value,
  onChange,
  placeholder,
  name,
  disabled,
  clearable = false,
}) {
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    control: (provided) => ({
      ...provided,
      border: "1px solid #E8E8E8",
      backgroundColor: "#fff",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #E8E8E8",
      },
      outline: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };
  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      maxMenuHeight={250}
      isClearable={clearable}
      theme={(theme) => ({
        ...theme,
        borderRadius: 5,
        colors: {
          ...theme.colors,
          primary25: "rgb(22 255 150 / 24%)",
          primary: "#16ff961a",
        },
      })}
      styles={customStyles}
      className="dropdown-role"
      placeholder={placeholder}
      isDisabled={disabled}
    />
  );
}

export default Dropdown;
