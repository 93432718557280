import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  login: false,
  userDetails:
    localStorage?.getItem("userDetails") &&
    JSON.parse(localStorage?.getItem("userDetails")),
};

export const loginSlice = createSlice({
  name: "login_slice",
  initialState,
  reducers: {
    isLogin: (state, action) => {
      if (action?.payload?.token) {
        state.login = true;
        state.userDetails = action.payload;
      }
    },
    isLogout: (state) => {
      state.login = false;
      state.userDetails = "";
    },
  },
});

export const { isLogin, isLogout } = loginSlice.actions;
export default loginSlice.reducer;
