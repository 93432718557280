/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import { createContext, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import ContactUs from "./Commons/ContactUs";
import NotAuthorised from "./Commons/NotAuthorised";
import ScrollTop from "./Commons/ScrollTop";
import CatagoryDetail from "./Components/CatagoryDetail";
import {
  default as CatagoryMap,
  default as CategoryItem,
} from "./Components/CategoryItem";
import Home from "./Components/Home";
import Chat from "./Components/Message/Chat";
import RenterCareers from "./Components/Renter/RenterCareers";
import RenterDetails from "./Components/Renter/RenterDetails";
import RenterExchangeRate from "./Components/Renter/RenterExchangeRate";
import RenterNews from "./Components/Renter/RenterNews";
import RenterSustainbility from "./Components/Renter/RenterSustainbility";
import StripeCallback from "./Components/StripeCallback";
import LenderRoutes from "./LenderRoutes";
import RequireRenterAuth, { RequireLenderAuth } from "./ProtectedRoute";
import RenterRoutes from "./RenterRoutes";

export const Selected = createContext(null);
function App() {
  const [isSelected, setIsSelected] = useState(false);
  return (
    <Selected.Provider value={{ isSelected, setIsSelected }}>
      <Routes>
        {/************************Protected Routes For Render*************************/}

        <Route exact path="*" element={<RequireRenterAuth />}>
          <Route path="renter/*" element={<RenterRoutes />} />
        </Route>

        {/************************Protected Routes For Lender*************************/}

        <Route exact path="*" element={<RequireLenderAuth />}>
          <Route path="lender/*" element={<LenderRoutes />} />
        </Route>

        {/*******************************Public Routes*******************************/}

        <Route path="/" element={<Home />} />
        <Route path="/how-it-works" element={<RenterSustainbility />} />
        <Route path="/sustainbility" element={<RenterSustainbility />} />
        <Route path="/newsletter" element={<RenterNews />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/careers" element={<RenterCareers />} />
        <Route path="/category-map-view" element={<CatagoryMap />} />
        <Route path="/catagory-marketplace" element={<CategoryItem />} />
        <Route path="/exchange-rate" element={<RenterExchangeRate />} />
        <Route path="/catagory-mapping" element={<CategoryItem />} />
        <Route path="/catagory-detail" element={<CatagoryDetail />} />
        <Route path="/unauthorised" element={<NotAuthorised />} />
        <Route path="/renter-details" element={<RenterDetails />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/message" element={<Chat />} />
        <Route path="/account-verification" element={<StripeCallback />} />

        {/* <Route path="/addnewaddress-mobile" element={<AddNewAddress />} /> */}
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
      <ScrollTop />
    </Selected.Provider>
  );
}

export default App;
