/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */

import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  BagIconLight,
  CrossWhiteIcon,
  NavBarLogo,
  NavBarMobileLogo,
  SearchIcon,
  UserIconLight,
} from "../../SvgIcons/allIcons";

import { Col, Row, Tab } from "react-bootstrap";
import { useDispatch } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ForgotPassword from "../../Authentication/ForgotPassword";
import Login from "../../Authentication/Login";
import SignUp from "../../Authentication/SignUp";
import { isLogin } from "../../Redux/LoginSlice";
import { searchQuery } from "../../Redux/searchSlice";
import { CheckUserLogin, getCategory } from "../../Services/APIServices";

const Header = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showForgot, setshowForgot] = useState(false);
  const handleCloseTwo = () => setshowForgot(false);
  const handleShowTwo = () => setshowForgot(true);
  const [search, setSearch] = useState("");
  const [defaultCategoryId, setDefaultCategoryId] = useState("");

  const [activeTab, setActiveTab] = useState("login");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    catagoryList();
    if (token) {
      checkUser();
    }
  }, []);

  const checkUser = () => {
    CheckUserLogin().then((resp) => {
      if (resp?.status === 200) {
        dispatch(isLogin(resp?.data?.data));
      }
    });
  };

  const [list, setList] = useState([]);
  const catagoryList = () => {
    getCategory().then((resp) => {
      if (resp?.status === 200) {
        if (resp?.data?.data && resp?.data?.data.length > 0) {
          setDefaultCategoryId(resp?.data?.data[0].id);
          setList(resp?.data?.data);
        }
      }
    });
  };

  const handleViewCategory = (data) => {
    navigate(`/catagory-mapping?id=${data?.id}&t=${data?.title}`);
  };

  const handleBackButton = () => {
    handleCloseTwo();
    handleShow();
  };

  const removeSearch = () => {
    dispatch(searchQuery(""));
    setSearch("");
  };

  const handleSearch = () => {
    dispatch(searchQuery(search));
    navigate("/catagory-mapping");
  };

  return (
    <>
      <Navbar expand="lg" className="logout-navbar">
        <Container>
          <Navbar.Brand>
            <Link to={`/`}>
              <NavBarLogo />
            </Link>
          </Navbar.Brand>
          <Form className="d-flex search-form">
            <Form.Control
              type="text"
              placeholder="Search"
              className="Search"
              aria-label="Search"
              value={search}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSearch();
                }
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className="searc-icon" onClick={handleSearch}>
              <SearchIcon />
            </span>
            {search.length ? (
              <span className="header-cross" onClick={removeSearch}>
                <CrossWhiteIcon />
              </span>
            ) : (
              ""
            )}
          </Form>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <NavLink
                to="/"
                onClick={() => removeSearch()}
                className="nav-link"
              >
                Home
              </NavLink>
              <NavLink
                to="/how-it-works"
                className="nav-link"
                onClick={() => removeSearch()}
              >
                How it works
              </NavLink>
              <NavLink
                to="/catagory-mapping"
                className="nav-link"
                onClick={() => removeSearch()}
              >
                Marketplace
              </NavLink>

              <NavDropdown title="Categories">
                {list && list.length > 0 ? (
                  list?.map((data, index) => {
                    return (
                      <NavDropdown.Item
                        onClick={() => handleViewCategory(data)}
                        value={data?.id}
                        key={index}
                      >
                        {data?.title}
                      </NavDropdown.Item>
                    );
                  })
                ) : (
                  <>No categories Available</>
                )}
              </NavDropdown>

              <Nav.Link
                onClick={() => {
                  handleShow();
                }}
              >
                List your item{" "}
              </Nav.Link>
              <Nav.Link onClick={handleShow} className="user-menu">
                <BagIconLight />
              </Nav.Link>
              <Nav.Link
                className="user-menu"
                id="headerlogin"
                onClick={handleShow}
              >
                <UserIconLight />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Navbar className="mobile-nav d-none">
        <Container>
          <Navbar.Brand>
            <NavLink to={"/"}>
              <NavBarMobileLogo />
            </NavLink>
          </Navbar.Brand>
          <div className="d-flex align-tems-center gap-3">
            <Nav.Link href="#action8" className="user-menu">
              <BagIconLight />
            </Nav.Link>
            <Nav.Link className="user-menu" onClick={handleShow}>
              <UserIconLight />
            </Nav.Link>
          </div>
        </Container>
      </Navbar>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="darkbg p-0">
          <div className="header-area">
            <Link to="/" onClick={handleClose}>
              <img src={require("../../assets/images/logo.png")} alt="" />
            </Link>
            <Modal.Title>Welcome</Modal.Title>
            <Tab.Container
              id="left-tabs-example"
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)}
            >
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="register">I’m a new user</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="login">I have an account</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <div className="middle-area">
                    <Tab.Content>
                      {/******************************Login Tab********************************/}
                      <Tab.Pane eventKey="login">
                        <Login
                          handleClose={handleClose}
                          handleShowTwo={handleShowTwo}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="register">
                        {/******************************Signup Tab********************************/}
                        <SignUp />
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>

      {/* Forgot Password Modal */}

      <Modal
        show={showForgot}
        onHide={handleCloseTwo}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="darkbg p-0">
          <div className="header-area">
            <Link>
              <img src={require("../../assets/images/logo.png")} alt="" />
            </Link>
            <Modal.Title>Welcome</Modal.Title>
          </div>
          <div className="middle-area">
            <ForgotPassword handleBackButton={handleBackButton} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
