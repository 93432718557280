import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LENDER, RENTER } from "../globals/constants";
import LenderHeader from "./Headers/LenderHeader";
import RenterHeader from "./Headers/RenterHeader";
import Header from "./Headers/Header";
import useRole from "../hooks/useRole";

const NotFound = () => {
  const role = useRole();
  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}

      <Container fluid>
        <Row className="align-items-center justify-content-center vh-100">
          <Col lg={8} xs={12} className="mx-auto text-center">
            <img src={require("../assets/images/error-404.gif")} alt="" />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotFound;
