const accessTokenKey = "token";

export const getToken = () => {
  return localStorage.getItem(accessTokenKey);
};

export const clearSession = () => {
  localStorage.removeItem(accessTokenKey);
};

export const checkSession = async () => {
  let accessToken = await localStorage.getItem(accessTokenKey);
  return accessToken ? true : false;
};
