/*
@copyright : Ozvid Technologies Pvt. Ltd. < www.ozvid.com >
@author    : Shiv Charan Panjeta < shiv@ozvid.com >
 
All Rights Reserved.
Proprietary and confidential :  All information contained here in is, and remains
the property of Ozvid Technologies Pvt. Ltd. and it's partners.
Unauthorized copying of this file, via any medium is strictly prohibited.
*/
let apiBase = process.env.REACT_APP_API_URL;

export let imageBase = process.env.REACT_APP_IMAGE_URL;
imageBase = imageBase?.slice(0, -1);


export default apiBase;
