/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import HomeFooter from "../../Commons/HomeFooter";
import { PlusIcon, SearchGrayIcon } from "../../SvgIcons/allIcons";
import { LENDER, RENTER } from "../../globals/constants";
import useRole from "../../hooks/useRole";

const RenterCareers = () => {
  const role = useRole();
  const userDetails = useSelector((state) => state?.Login?.userDetails);
  let active = 2;
  let items = [];

  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="mainbox_outer">
        <div className="top-head">
          <Container>
            <div className="row">
              <Col>
                <h4 className="font48">Careers</h4>
              </Col>
            </div>
          </Container>
        </div>
        <div className="top-breadcrumb top-area">
          <Container>
            <Row className="align-items-center">
              <Col lg={4} md={3} sm={12}>
                <Form className="search-form">
                  <Form.Label>Search</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className="Search"
                    aria-label="Search"
                  />
                  <span className="searc-icon">
                    <SearchGrayIcon />
                  </span>
                </Form>
              </Col>
              <Col lg={3} md={3} sm={12}>
                <Form.Label>Language</Form.Label>
                <Form.Select aria-label="Default select" className="font16">
                  <option>Language</option>
                  <option value="1">English</option>
                  <option value="2">French</option>
                </Form.Select>
              </Col>
              <Col lg={3} md={3} sm={12}>
                <Form.Label>Team</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  className="font16"
                >
                  <option>Operations</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </Col>
              <Col lg={2} md={3} sm={12}>
                <Button className="btn btn-theme">Apply Filters</Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="result-area ptb">
          <Container>
            <Row>
              <Col lg={6}>
                <h4 className="text-start mb-36">Your results</h4>
                <Table className="border-0 table-responsive">
                  <tbody>
                    <tr>
                      <td>
                        <div className="single-job">
                          <p className="font12 mb-0">Jan/12/2023</p>
                          <h5 className="font16 colordark">
                            Customer Service Advisor
                          </h5>
                        </div>
                      </td>
                      <td>
                        <h5 className="font16 colordark mb-0">City</h5>
                      </td>
                      <td>
                        <Link>
                          <PlusIcon />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="single-job">
                          <p className="font12 mb-0">Jan/12/2023</p>
                          <h5 className="font16 colordark">
                            Customer Service Advisor
                          </h5>
                        </div>
                      </td>
                      <td>
                        <h5 className="font16 colordark mb-0">City</h5>
                      </td>
                      <td>
                        <Link>
                          <PlusIcon />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="single-job">
                          <p className="font12 mb-0">Jan/12/2023</p>
                          <h5 className="font16 colordark">
                            Customer Service Advisor
                          </h5>
                        </div>
                      </td>
                      <td>
                        <h5 className="font16 colordark mb-0">City</h5>
                      </td>
                      <td>
                        <Link>
                          <PlusIcon />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="single-job">
                          <p className="font12 mb-0">Jan/12/2023</p>
                          <h5 className="font16 colordark">
                            Customer Service Advisor
                          </h5>
                        </div>
                      </td>
                      <td>
                        <h5 className="font16 colordark mb-0">City</h5>
                      </td>
                      <td>
                        <Link>
                          <PlusIcon />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="single-job">
                          <p className="font12 mb-0">Jan/12/2023</p>
                          <h5 className="font16 colordark">
                            Customer Service Advisor
                          </h5>
                        </div>
                      </td>
                      <td>
                        <h5 className="font16 colordark mb-0">City</h5>
                      </td>
                      <td>
                        <Link>
                          <PlusIcon />
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="single-job">
                          <p className="font12 mb-0">Jan/12/2023</p>
                          <h5 className="font16 colordark">
                            Customer Service Advisor
                          </h5>
                        </div>
                      </td>
                      <td>
                        <h5 className="font16 colordark mb-0">City</h5>
                      </td>
                      <td>
                        <Link>
                          <PlusIcon />
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Pagination size="sm">{items}</Pagination>
              </Col>
              <Col lg={6}></Col>
            </Row>
          </Container>
        </div>
      </div>
      {userDetails ? <Footer /> : <HomeFooter />}
    </>
  );
};

export default RenterCareers;
