import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LENDER, RENTER } from "../../globals/constants";
import useRole from "../../hooks/useRole";
import Footer from "../Footer";
import Header from "../Headers/Header";
import LenderHeader from "../Headers/LenderHeader";
import RenterHeader from "../Headers/RenterHeader";
import ProfileTabs from "../ProfileTabs";
import Address from "./Address";
import ContactInformation from "./ContactInformation";
import MobileProfile from "./MobileProfile";
import Payments from "./Payments";
import PersonalInformation from "./PersonalInformation";

const Profile = () => {
  const role = useRole();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("first");
  const userRole = useSelector((state) => state?.Login?.userDetails?.role_id);
  const atab = new URLSearchParams(location?.search).get("atab");

  useEffect(() => {
    if (atab) {
      setActiveTab(atab);
    }
  }, [atab]);

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="d-none d-md-block">
        <div className="mainbox_outer">
          <ProfileTabs active="profile" />
          <div className="accountmain">
            <Tab.Container
              id="left-tabs-example"
              activeKey={activeTab}
              onSelect={(k) => {
                setActiveTab(k);
                if (userRole == RENTER) {
                  navigate(`/renter/profile?atab=${k}`);
                } else {
                  navigate(`/lender/profile?atab=${k}`);
                }
              }}
            >
              <Container fluid>
                <Row>
                  <Col lg={4} md={4} sm={12} className="bggrey pt-5 pb-5">
                    <Row>
                      <Col lg={4} md={3} sm={12}></Col>
                      <Col lg={8} md={9} sm={12}>
                        <Nav variant="pills" className="flex-column sidebarnav">
                          <Nav.Item>
                            <Nav.Link eventKey="first" className="pt-0">
                              Personal Info
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Contact Info</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">Address</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="fourth">Payments</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={8} md={8} sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first" className="pt-5 pb-5">
                        <PersonalInformation />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second" className="pt-5 pb-5">
                        <ContactInformation />
                      </Tab.Pane>

                      <Tab.Pane eventKey="third" className="pt-5 pb-5">
                        <Address />
                      </Tab.Pane>

                      <Tab.Pane eventKey="fourth" className="pt-5 pb-5">
                        <Payments />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Container>
            </Tab.Container>
          </div>
        </div>
        <Footer />
      </div>
      <div className="d-block d-md-none">
        <MobileProfile  />
      </div>
    </>
  );
};

export default Profile;
