import React, { useState, useEffect, useMemo, useRef } from "react";
import { Col, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ChangeCurrentRole } from "../Services/APIServices";
import showNotification from "../Services/NotificationService";
import { isLogin } from "../Redux/LoginSlice";
import { LENDER, RENTER } from "../globals/constants";
import Dropdown from "./Dropdown";
import Loader from "./Loader/Loader";

const ProfileTabs = ({ active }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const currentRole = useSelector(
    (state) => state?.Login?.userDetails?.role_id
  );
  const [role, setRole] = useState({
    label: currentRole === LENDER ? "Lender" : "Renter",
    value: currentRole,
  });

  const handleRoleChange = (e) => {
    let formData = new FormData();
    formData.append("role_id", e.value);
    setLoader(true);
    ChangeCurrentRole(formData).then((resp) => {
      setLoader(false);
      if (resp?.data?.status === 200) {
        localStorage.setItem("userDetails", JSON.stringify(resp?.data?.data));
        dispatch(isLogin(resp?.data?.data));
        if (resp?.data?.data?.role_id === RENTER) {
          navigate("/renter/home");
        }
        if (resp?.data?.data?.role_id === LENDER) {
          navigate("/lender/home");
        }
        setRole(e);
      }
    });
  };

  return (
    <div className="top-head bottom-border pb-3  d-lg-block d-none">
      <Container>
        <div className="row  align-items-center">
          <Col lg={10} md={9} sm={12}>
            {currentRole === LENDER ? (
              <ul className="d-flex flex-wrap align-items-center subtop-menu">
                <li className={active === "listing" ? "active" : ""}>
                  <Link to="/lender/listing">My Listings</Link>
                </li>
                <li className={active === "reservation" ? "active" : ""}>
                  <Link to="/lender/reservation">Reservations</Link>
                </li>
                <li className={active === "calendar" ? "active" : ""}>
                  <Link to="/lender/calender">Calendar</Link>
                </li>
                <li className={active === "profile" ? "active" : ""}>
                  <Link to="/lender/profile?atab=first">My Profile</Link>
                </li>
                <li className={active === "settings" ? "active" : ""}>
                  <Link to="/lender/settings?atab=first">Settings</Link>
                </li>
              </ul>
            ) : (
              <ul className="d-flex flex-wrap align-items-center subtop-menu">
                <li className={active === "saved-item" ? "active" : ""}>
                  <Link to="/renter/saved-items">Saved Items</Link>
                </li>
                <li className={active === "my-rental" ? "active" : ""}>
                  <Link to="/renter/my-rental">My Rentals</Link>
                </li>
                <li className={active === "profile" ? "active" : ""}>
                  <Link to="/renter/profile?atab=first">My Profile</Link>
                </li>
                <li className={active === "settings" ? "active" : ""}>
                  <Link to="/renter/settings?atab=first">Settings</Link>
                </li>
              </ul>
            )}
          </Col>
          <Col lg={2} md={3} sm={12}>
            <Dropdown
              name="role_change"
              value={role}
              options={[
                { label: "Lender", value: LENDER },
                { label: "Renter", value: RENTER },
              ]}
              onChange={(e) => handleRoleChange(e)}
            />
          </Col>
        </div>
      </Container>
      {loader && <Loader />}
    </div>
  );
};

export default ProfileTabs;
