import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import FullCalendar from "@fullcalendar/react";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import Nodata from "../../../Commons/CommonModals/Nodata";
import Header from "../../../Commons/Headers/Header";
import LenderHeader from "../../../Commons/Headers/LenderHeader";
import RenterHeader from "../../../Commons/Headers/RenterHeader";
import {
  AddProductAvailability,
  calenderProducts,
  getProductDetails,
  productAvailabilityDetails,
} from "../../../Services/APIServices";
import { imageBase } from "../../../globals/Config";
import { LENDER, RENTER } from "../../../globals/constants";
import useRole from "../../../hooks/useRole";

function MobileCalender() {
  const role = useRole();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dailyPrice, setDailyPrice] = useState("");
  const location = useLocation();
  const [events, setEvents] = useState([]);
  const [product, setProduct] = useState("");
  const [productId, setProductId] = useState("");
  const rid = new URLSearchParams(location?.search).get("rid");

  useEffect(() => {
    productListing();
    if (rid) {
      ProductDetails(rid);
      setProductId(rid);
      AvailabilityDetails(rid);
    }
  }, []);

  const productListing = () => {
    calenderProducts().then((res) => {
      if (res?.status === 200) {
        setList(res?.data?.data);
      }
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (isValid) {
        handleSubmit();
      }
    }
  };

  const ProductDetails = (id) => {
    getProductDetails(id).then((resp) => {
      if (resp?.data?.status === 200) {
        setProduct(resp?.data?.data);

        // Get and Set daily price in state : in formic

        setFieldValue("product_price", resp?.data?.data?.daily_price);

        let elements = document.getElementsByClassName("exist_event");
        for (let i = 0; i < elements.length; i++) {
          var nodes = [];
          var element = elements[i];
          nodes.push(element);
          while (element.parentNode) {
            nodes.unshift(element?.parentNode);
            element = element?.parentNode;
            let res = element?.classList?.contains("fc-daygrid-day-frame");
            if (res) {
              element?.classList?.add("exist_event");
            }
            let classList = element?.classList;
          }
        }
      }
    });
  };

  const AvailabilityDetails = (id) => {
    setLoading(true);
    productAvailabilityDetails(id).then((resp) => {
      setLoading(false);
      if (resp?.data?.status === 200) {
        let eventsData = Object.entries(
          resp?.data?.data?.selected_dates || {}
        )?.map((data) => {
          if (data[1]) {
            return {
              date: moment(data[0])?.format("YYYY-MM-DD"),
              condition: data[1],
              className: "single_day",
              display: "background", // list-item
            };
          } else {
            return {
              date: moment(data[0])?.format("YYYY-MM-DD"),
              borderColor: "white",
              backgroundColor: "pink",
              display: "background",
            };
          }
        });
        setEvents(eventsData);
      }
    });
  };

  const validationSchema = Yup.object({
    available_from: Yup.string().required("From date required"),
    available_to: Yup.string().required("To date required"),
    availability_status: Yup.string().required("availability status required"),
    product_price: Yup.string().required("Product price required"),
  });

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched,
    isValid,
  } = useFormik({
    initialValues: {
      product_id: productId,
      available_from: "",
      available_to: "",
      availability_status: "",
      product_price: dailyPrice,
    },
    validationSchema,
    onSubmit(values, { setSubmitting }) {
      setSubmitting(true);
      let formData = new FormData();
      formData.append("product_id", productId);
      formData.append("available_from", values.available_from);
      formData.append("available_to", values.available_to);
      formData.append("product_price", values.product_price);
      formData.append("availability_status", values.availability_status);
      setLoading(true);
      AddProductAvailability(formData)
        .then((resp) => {
          setLoading(false);
          setSubmitting(false);
          if (resp?.status === 200) {
            AvailabilityDetails(resp?.data?.data?.product);
          }
        })
        .catch((err) => console.log(err));
    },
  });

  return (
    <div className="App">
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <Col
        lg={2}
        md={6}
        sm={12}
        className="green-border px-lg-0 overflow-scroll vh-100"
      >
        {list && list.length > 0 ? (
          list &&
          list?.map((data, index) => {
            return (
              <div
                className="room"
                onClick={() => {
                  AvailabilityDetails(data?.id);
                }}
                key={index}
              >
                <img
                  className={productId == data?.id ? "active" : ""}
                  src={
                    data?.product_image?.images
                      ? imageBase + data?.product_image?.images
                      : require("../../../assets/images/static_image.jpg")
                  }
                  alt="img"
                />

                <h4 className="font400 text-capitalize">{data?.title}</h4>
              </div>
            );
          })
        ) : (
          <Nodata />
        )}
      </Col>

      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, multiMonthPlugin]}
        initialView="multiMonthYear"
        headerToolbar={{
          center: "dayGridMonth,timeGridWeek,timeGridDay new",
        }}
        customButtons={{
          new: {
            text: "new",
            click: () => console.log("new event"),
          },
        }}
        events={events}
        eventColor="red"
        nowIndicator
        duration={3}
      />
    </div>
  );
}

export default MobileCalender;
