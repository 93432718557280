import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Link, useNavigate } from "react-router-dom";
import {
  FacebookGreenIcon,
  InstagramGreenIcon,
  TwitterGreenIcon,
  YoutubeGreenIcon,
} from "../SvgIcons/allIcons";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { ChangeCurrentRole } from "../Services/APIServices";
import { LENDER, RENTER } from "../globals/constants";
import MobileFooter from "./MobileFooter/MobileFooter";
import MobileLenderFooter from "./MobileFooter/MobileLenderFooter";
import MobileRenterFooter from "./MobileFooter/MobileRenterFooter";
import Loader from "./Loader/Loader";

const HomeFooter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const role = useSelector((state) => state?.Login?.userDetails?.role_id);
  const isLogin = useSelector((state) => state?.Login?.login);

  const handleCareerRoute = (e) => {
    e.preventDefault();
   
  };

  const handleRentItem = (e) => {
    e.preventDefault();
    if (isLogin) {
      if (role === RENTER) {
        handleRoleChange(LENDER);
      } else {
        navigate("/lender/new-item");
      }
    } else {
      document.getElementById("headerlogin").click();
    }
  };

  const handleRoleChange = (role) => {
    let formData = new FormData();
    formData.append("role_id", role);
    setLoader(true);
    ChangeCurrentRole(formData).then((resp) => {
      setLoader(false);
      if (resp?.data?.status === 200) {
        localStorage.setItem("userDetails", JSON.stringify(resp?.data?.data));
        dispatch(isLogin(resp?.data?.data));
        if (resp?.data?.data?.role_id === RENTER) {
          navigate("/renter/home");
        }
        if (resp?.data?.data?.role_id === LENDER) {
          navigate("/lender/listing");
        }
      }
    });
  };

  const handleNewsLetterRoute = (e) => {
    e.preventDefault();
    if (isLogin) {
      if (role === RENTER) {
        navigate("/renter/newsletter");
      } else {
        navigate("/lender/newsletter");
      }
    } else {
      navigate("/newsletter");
    }
  };

  const handleSustanability = (e) => {
    e.preventDefault();
    if (isLogin) {
      if (role === RENTER) {
        navigate("/renter/sustainbility");
      } else {
        navigate("/lender/how-it-works");
      }
    } else {
      navigate("/sustainbility");
    }
  };

  const handleExchangeRateRoute = (e) => {
    e.preventDefault();
   
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (isLogin) {
      if (role === RENTER) {
        navigate("/renter/home");
      } else {
        navigate("/lender/home");
      }
    } else {
      navigate("/");
    }
  };

  const handleAccountRoute = (e) => {
    e.preventDefault();
    if (isLogin) {
      if (role === RENTER) {
        navigate("/renter/profile");
      } else {
        navigate("/lender/profile");
      }
    } else {
      document.getElementById("headerlogin").click();
    }
  };

  const handleOrderRoute = (e) => {
    e.preventDefault();
    if (isLogin) {
      if (role === RENTER) {
        navigate("/renter/my-rental");
      } else {
        navigate("/lender/reservation");
      }
    } else {
      document.getElementById("headerlogin").click();
    }
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <>
      <div className="footer ptb home-footer">
        <Container>
          <Row>
            <Col lg={4} md={4} sm={12}>
              <Link onClick={handleLogoClick}>
                <svg
                  version="1.2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1000 279"
                  width="1000"
                  height="279"
                >
                  <path
                    id="Layer"
                    fillRule="evenodd"
                    fill="#16ff96"
                    d="m125.5 31c8.6 0 15.5-6.9 15.5-15.5 0-8.6-6.9-15.5-15.5-15.5-7.8 0-14.3 5.8-15.3 13.3-24.7 4-47.9 14.8-66.8 31.3-22.5 19.6-37.8 46.2-43.4 75.5l6.6 1.3c5.3-27.9 19.8-53.1 41.2-71.7 17.8-15.6 39.6-25.9 62.9-29.7 1.9 6.4 7.8 11 14.8 11zm137 88.5c-5.7-29.3-21.1-55.8-43.7-75.3l-4.4 5.1c21.5 18.5 36.1 43.7 41.6 71.5 4.6 23.4 2.5 47.6-6 69.7q-2-0.6-4.3-0.6c-8.6 0-15.5 6.9-15.5 15.5 0 8.5 6.9 15.5 15.5 15.5 8.5 0 15.5-7 15.5-15.5 0-4.6-2-8.8-5.2-11.6 9.1-23.6 11.4-49.3 6.5-74.3zm-174.4 152.3c28.2 9.7 58.9 9.6 87.1-0.2l-2.2-6.3c-26.8 9.3-55.9 9.4-82.8 0.2-23.1-7.9-43.4-22.3-58.5-41.3 2.9-2.8 4.7-6.8 4.7-11.1 0-8.5-7-15.5-15.5-15.5-8.6 0-15.5 7-15.5 15.5 0 8.6 6.9 15.5 15.5 15.5 1.8 0 3.5-0.3 5.1-0.9 16 20.4 37.5 35.7 62.1 44.1z"
                  />
                  <path
                    id="Layer"
                    fill="#ffffff"
                    d="m336.9 78.4c0 21.8 12.8 36 37.5 41.6l24.9 5.7c14.4 3.3 20.9 10.9 20.9 22.8 0 15.1-12.8 24.5-32.8 24.5-19.1 0-31.2-9.2-31.7-23.7h-20.9c1.3 26 21.8 42.1 52.6 42.1 32.2 0 53.3-17.4 53.3-44.6 0-21.3-12.5-34.7-37.2-40.4l-24.9-5.8c-14.3-3.4-21.2-10.7-21.2-22.8 0-14.9 12.4-24.5 31.4-24.5 17 0 27.6 8.8 28.7 23.8h20.7c-1.7-26.1-20.3-42.2-49.2-42.2-31.2 0-52.1 17.6-52.1 43.5z"
                  />
                  <path
                    id="Layer"
                    fill="#ffffff"
                    d="m482.8 188.7v-55.7c0-17.6 10.3-31.4 29.3-31.4 14.7 0 24.5 8.4 24.5 29.1v58.2h19.7v-62.6c0-26.1-12.6-42.7-38.7-42.7-15.9 0-28.3 7.6-34.8 18.7v-67.4h-19.6v153.8z"
                  />
                  <path
                    id="Layer"
                    fillRule="evenodd"
                    fill="#ffffff"
                    d="m578.5 160.4c0-18.8 14-30.5 38.1-32.4l32.2-2.5v-2.9c0-17.2-10.2-23.3-24-23.3-16.6 0-26 7.3-26 19.7h-17.1c0-21.4 17.5-35.4 43.9-35.4 25.3 0 42.5 13.4 42.5 41v64.3h-16.7l-2.1-16.5c-5.3 11.7-19.3 19-35.8 19-22 0-35-12.4-35-31zm70.6-15.7v-5.4l-26.2 2.1c-17.4 1.7-24.5 8.4-24.5 18.4 0 10.9 8 16.3 20.7 16.3 18.6 0 30-12.1 30-31.4z"
                  />
                  <path
                    id="Layer"
                    fill="#ffffff"
                    d="m705.8 188.9l25.1-37.5 25.6 37.5h22.8l-35-50.7 35.6-51.6h-22.4l-25.3 38-26-38h-22.8l35.6 51.6-35.8 50.7z"
                  />
                  <path
                    id="Layer"
                    fill="#ffffff"
                    d="m805.9 62.3c-7.1 0-12.9-5.8-12.9-13 0-7.2 5.8-13 12.9-13 7.2 0 13 5.8 13 13 0 7.2-5.8 13-13 13zm-9.6 24.3h19.7v102.3h-19.7z"
                  />
                  <path
                    id="Layer"
                    fill="#ffffff"
                    d="m833.7 86.6v16.5h15v85.8h19.7v-85.8h24.3v-16.5h-24.3v-16.4c0-11.1 3.4-17.5 16.1-17.5h8.8v-17c-4-0.6-8.3-0.8-12.1-0.8-18.6 0-32.5 10.2-32.5 35.3v16.4z"
                  />
                  <path
                    id="Layer"
                    fill="#ffffff"
                    d="m901.1 234.7c5.3 1.3 10.7 2.1 17.2 2.1 15.7 0 26-7.3 32.9-24.9l48.3-125.3h-20.3l-30.3 82.4-29.8-82.4h-20.7l41.3 107.7-3.6 9.9c-4.8 12.9-12.3 14.4-21.8 14.4h-13.2z"
                  />
                </svg>
              </Link>
              <p>A new way of renting</p>
              <ul className="social-links">
                <li>
                  <a target="_blank" href="https://www.facebook.com/shaxify/">
                    <FacebookGreenIcon />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/shaxify/">
                    <InstagramGreenIcon />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com/Shaxifyrent">
                    <TwitterGreenIcon />
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.youtube.com/@Shaxify">
                    <YoutubeGreenIcon />
                  </a>
                </li>
              </ul>
              <p>© {getYear()} All rights reserved</p>
            </Col>
            <Col lg={8} md={8} sm={12}>
              <Row>
                <Col lg={3} md={3} sm={12}>
                  <h5>
                    <i>Get to know us</i>
                  </h5>
                  <ul>
                    <li>
                      <Link onClick={handleCareerRoute}>Careers</Link>
                    </li>
                    <li>
                      <Link onClick={handleNewsLetterRoute}>
                        {" "}
                        Shaxify newsletter{" "}
                      </Link>
                    </li>
                    <li>
                      <Link onClick={handleSustanability}>
                        {" "}
                        Sustainability{" "}
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col lg={3} md={3} sm={12}>
                  <h5>
                    <i>Make money with us</i>
                  </h5>
                  <ul>
                    <li>
                      <Link onClick={handleRentItem}>
                        Rent items on Shaxify
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col lg={3} md={3} sm={12}>
                  <h5>
                    <i>Shaxify payment options</i>
                  </h5>
                  <ul>
                    <li>
                      <Link onClick={handleExchangeRateRoute}>
                        Shaxify currency <br /> converter
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#"> Shaxify membership </Link>
                    </li> */}
                  </ul>
                </Col>
                <Col lg={3} md={3} sm={12}>
                  <h5>
                    <i>Let us help you </i>
                  </h5>
                  <ul>
                    <li>
                      <Link onClick={handleAccountRoute}>Your account </Link>
                    </li>
                    <li>
                      <Link onClick={handleOrderRoute}>Your orders</Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      {role == LENDER ? (
        <MobileLenderFooter />
      ) : role == RENTER ? (
        <MobileRenterFooter />
      ) : (
        <MobileFooter />
      )}
      {loader && <Loader />}
    </>
  );
};

export default HomeFooter;
