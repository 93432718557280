import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Footer from "../../Commons/Footer";
import Header from "../../Commons/Headers/Header";
import LenderHeader from "../../Commons/Headers/LenderHeader";
import RenterHeader from "../../Commons/Headers/RenterHeader";
import { getCart } from "../../Redux/cartListSlice";
import { removeCartProduct } from "../../Services/APIServices";
import { TrashIcon } from "../../SvgIcons/allIcons";
import { LENDER, RENTER } from "../../globals/constants";
import useRole from "../../hooks/useRole";
import "./cart.scss";
import { imageBase } from "../../globals/Config";

function CartPage() {
  const role = useRole();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCart());
  }, []);

  const products = useSelector((state) => state?.cartData?.cart);

  // -----Remove Event------
  const removeFromCart = async (id) => {
    if (window.confirm("Are you sure ! Do you want to remove product")) {
      let formData = new FormData();
      formData.append("product_id", id);
      let response = await removeCartProduct(formData);
      if (response?.status == 200) {
        dispatch(getCart());
      }
    } else {
    }
  };

  // ------Total Product Incart and Total Price of cart

  const cartTotalAmount = products?.reduce(
    (acc, data) => acc + +data?.product_price,
    0
  );

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      {products?.length !== 0 ? (
        <div className="shop-cart py-5">
          <div className="container">
            <div className="shop-cart-wrapper">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Product Name</th>
                      <th>Price for a day</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="cart-img">
                              <img
                                src={
                                  data?.product?.product_image?.images &&
                                  imageBase +
                                    data?.product?.product_image?.images
                                }
                                alt=""
                              />
                            </div>
                          </td>
                          <td>
                            <h5>{data?.product?.title}</h5>
                          </td>
                          <td>
                            <div className="cart-price">
                              ₹{" "}
                              <span>
                                {Number(
                                  data?.product?.daily_price
                                )?.toLocaleString("hi") ?? 0}
                              </span>
                            </div>
                          </td>

                          <td>
                            <Link
                              className="btn btn-theme mt-3"
                              to={`/renter/checkout?id=${data?.product?.id}&from=${data?.saved_from}&to=${data?.saved_to}&pincode=${data?.deliver_to}`}
                            >
                              Rent Now
                            </Link>
                            <Link
                              to=""
                              className="cart-remove"
                              onClick={() => removeFromCart(data?.id)}
                            >
                              <TrashIcon />
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <div className="cart-footer">
                <div className="row">
                  <div className="col-md-6 col-lg-4"></div>
                  <div className="col-md-6 col-lg-8">
                    <div className="cart-summary">
                      <ul>
                        <li className="cart-total">
                          <strong>Total:</strong>{" "}
                          <span>₹ {cartTotalAmount}</span>
                        </li>
                      </ul>
                      <div className="text-end mt-40">
                        <Link
                          className="btn btn-theme w-100 mt-4"
                          to="/renter/checkout"
                        >
                          Checkout Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="nodata text-center mt-4">
            <img src={require("../../assets/images/nodata.png")} alt="img" />
            <h4>Your Shaxify cart is empty</h4>
            <Link
              href="shop-checkout.php"
              className="btn btn-theme  mt-4"
              to="/renter/category-map-view"
            >
              Continue Shopping
            </Link>
          </div>
        </>
      )}
      <Footer />
    </>
  );
}

export default CartPage;
