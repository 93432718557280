/**@copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. */
import React from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Footer from "../Commons/Footer";
import {
  BackIcon,
  CalendarIcon,
  Star,
  StarsBlueIcon,
  SupportIcon,
} from "../SvgIcons/allIcons";
import { DEFAULT_CURRENCY, LENDER, RENTER } from "../globals/constants";
import images from "./Images";
import LenderHeader from "../Commons/Headers/LenderHeader";
import RenterHeader from "../Commons/Headers/RenterHeader";
import Header from "../Commons/Headers/Header";
import useRole from "../hooks/useRole";

const CatagoryDetail = () => {
  const role = useRole();
  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      {/* <Header /> */}
      <div className="category-detailview pb-3 pt-lg-5 pt-3">
        <Container>
          <Row>
            <Col lg={5} md={5} sm={12} xs={12}>
              <div className="d-flex d-lg-none mb-4 align-items-center">
                <a className="me-3">
                  <BackIcon />{" "}
                </a>
                <h4 className="">
                  Delta Children Jordan 4-in-1 Convertible Crib{" "}
                </h4>
              </div>
              <ImageGallery showBullets={true} items={images} showNav={true} />
              <br />
              <button className="btn btn-default minwidth275">
                <SupportIcon /> Support
              </button>
            </Col>

            <Col lg={7} md={7} sm={12}>
              <div className="detail-view">
                <h2 className="d-none d-lg-block">
                  Delta Children Jordan 4-in-1 Convertible Crib{" "}
                </h2>
                <div className="d-flex flex-wrap mb-3">
                  <div className="usrimg mt-1">
                    <img src={require("../assets/images/electronics.png")} />
                  </div>
                  <div className="userinfo">
                    <h6 className="mb-0">
                      John Smith (12){" "}
                      <span className="font12 font400">
                        | 12 Rentals Joined 2021
                      </span>
                    </h6>
                    <div className="starlist ms-lg-3 ms-0">
                      <Star />
                      <Star />
                      <Star />
                      <Star />
                      <Star />
                    </div>
                  </div>
                </div>
                <p className="mb-3">
                  Delta Children Jordan 4-in-1 Convertible Crib.
                  <br />
                  Measurements: 100x50x40
                  <br />
                  Weight 10Kg
                  <br />
                  Comes with the mattress
                </p>
                <div className="measurementbar">
                  <h6 className="font12 font700">Product Measurements</h6>
                  <Row className="mb-3">
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <h6 className="font12 font700">Height (cm)</h6>
                      <p>120</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <h6 className="font12 font700">Width (cm)</h6>
                      <p>120</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <h6 className="font12 font700">Length (cm)</h6>
                      <p>120</p>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <h6 className="font12 font700">Weight (kg)</h6>
                      <p>13</p>
                    </Col>
                  </Row>
                </div>
                <h4> {DEFAULT_CURRENCY}20/month</h4>
                <Form className="d-none d-lg-block">
                  <Row>
                    <Col lg={3} md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          From
                        </Form.Label>
                        <div className="datepicker position-relative">
                          <Form.Control type="date" placeholder="DD/MM/YYYY" />
                          <span className="position-absolute">
                            <CalendarIcon />
                          </span>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          To
                        </Form.Label>
                        <div className="datepicker position-relative">
                          <Form.Control type="date" placeholder="DD/MM/YYYY" />
                          <span className="position-absolute">
                            <CalendarIcon />
                          </span>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="font700 font12 secondaycolor">
                          Zip Code
                        </Form.Label>
                        <Form.Control type="text" placeholder="Zip Code" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col lg={7}>
                    <table className="noborder greytable w-100 table">
                      <tr>
                        <td> {DEFAULT_CURRENCY}20/month x 10 months</td>
                        <td className="text-end">{DEFAULT_CURRENCY}200</td>
                      </tr>
                      <tr>
                        <td> 6 Month discount</td>
                        <td className="text-end">30%</td>
                      </tr>
                      <tr>
                        <td>Shaxify fee</td>
                        <td className="text-end">{DEFAULT_CURRENCY}20</td>
                      </tr>
                      <tr>
                        <td>Estimated Shipping</td>
                        <td className="text-end">{DEFAULT_CURRENCY}6</td>
                      </tr>
                      <tr>
                        <td className="font700">Total</td>
                        <td className="text-end font700">
                          {DEFAULT_CURRENCY}166
                        </td>
                      </tr>
                    </table>
                    <div className="d-flex align-items-center justify-content-between gap-3 two-btn">
                      <div className="w-100">
                        <button className="btn btn-default w-100">
                          Add to cart
                        </button>
                      </div>
                      <div className="w-100">
                        <button className="btn btn-theme w-100">
                          Rent Now!
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="blue-box d-flex d-lg-none align-items-start gap-2">
            <div>
              <StarsBlueIcon />
            </div>
            <div>
              <p className="font16 font700 colorblue">Squeaky Clean Policy</p>
              <p className="font16 font400 colorblue">
                We ask all our lenders to clean all items before before being
                sent.
              </p>
              <div className="text-end">
                <a className="font14 font700">Learn More</a>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default CatagoryDetail;
