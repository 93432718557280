import React from "react";
import ReactPaginate from "react-paginate";
import {
  PaginationLeftArrow,
  PaginationRightArrow,
} from "../SvgIcons/allIcons";
export default function Paginate(props) {
  return (
    <div>
      {props.page > 1 ? (
        <ReactPaginate
          previousLabel={<PaginationLeftArrow />}
          nextLabel={<PaginationRightArrow />}
          breakLabel={"..."}
          forcePage={props?.cp - 1}
          breakClassName={"page-item"}
          pageCount={Math.ceil(props.page)}
          marginPagesDisplayed={3}
          onPageChange={(e) => props.handlePageClick(e)}
          renderOnZeroPageCount={null}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          activeClassName={"active"}
          breakLinkClassName={"page-link"}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

// Usage

// {meta?.pageCount > 1 ? (
//   <Paginate
//     page={meta?.pageCount}
//     handlePageClick={(e) => handlePageChange(e)}
//     cp={currentpage}
//   />
// ) : (
//   ""
// )}

// const handlePageChange = (e) => {
//   setCurrentpage(e.selected + 1);
//   getAPI("", "", e.selected + 1);
// };
