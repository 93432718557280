/**
 * @copyright : ToXSL Technologies Pvt. Ltd. < www.toxsl.com >
@author : Shiv Charan Panjeta < shiv@toxsl.com >
All Rights Reserved.
Proprietary and confidential : All information contained herein is, and remains
the property of ToXSL Technologies Pvt. Ltd. and its partners.
Unauthorized copying of this file, via any medium is strictly prohibited. 
*/

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Commons/Headers/Header";
import LenderHeader from "../Commons/Headers/LenderHeader";
import RenterHeader from "../Commons/Headers/RenterHeader";
import Loader from "../Commons/Loader/Loader";
import { AddBank } from "../Services/APIServices";
import showNotification from "../Services/NotificationService";
import { LENDER, RENTER } from "../globals/constants";
import useRole from "../hooks/useRole";

const StripeCallback = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("Processing, Please wait...");
  const [apiCall, setApiCall] = useState(false);
  const code = new URLSearchParams(location.search).get("code");
  const navigate = useNavigate();
  const role = useRole();

  useEffect(() => {
    if (code && !apiCall) {
      setLoading(true);
      AddBank(code).then((resp) => {
        setLoading(false);
        if (resp?.status === 200) {
          setApiCall(true);
          setMsg("Bank added successfully");
        } else {
          showNotification("danger", resp?.data?.message);
        }
      });
    }
  }, []);

  return (
    <>
      {role == LENDER ? (
        <LenderHeader />
      ) : role == RENTER ? (
        <RenterHeader />
      ) : (
        <Header />
      )}
      <div className="d-flex align-items-center justify-content-center">
        <div>
          <h2>{msg}</h2>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default StripeCallback;
